import {
  Alert,
  Button,
  Input,
  Modal,
  SpaceBetween,
  Spinner,
  TextContent
} from '@amzn/awsui-components-react';
import { MaskingGroupInfo, SilencioDevice } from "src/utils/SilencioTypes";
import {
  isMaskingGroupNameValid,
  formatMaskingGroupName,
  getMaskingGroupType
} from './utils';
import {
  selectSelectedInputs,
  selectSelectedReadersAlarms,
  selectSelectedReadersModes,
  setInputsMaskingGroups,
  setInputsSearchMaskingGroup,
  setReadersAlarmsMaskingGroups,
  setReadersAlarmsSearchMaskingGroup,
  setReadersModesMaskingGroups,
  setReadersModesSearchMaskingGroup,
  updateInputDeviceMaskingGroups,
  updateReadersAlarmsDeviceMaskingGroups,
  updateReadersModesDeviceMaskingGroups,
} from '../../stores/slices/devicesSlice';
import { selectUserSelectedSite, selectUsername } from 'src/stores/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { debug } from 'src/utils/commonUtils';
import { upsertMaskingGroup } from '../../utils/DevicesUtils';
import { useBundle } from '@amzn/react-arb-tools';

export interface ICreateMaskingGroupProps {
  deviceType: string;
  maskingGroups: MaskingGroupInfo[] | undefined;
  showModalCallback: Function;
  visible: boolean;
}

export default function CreateMaskingGroup(props: ICreateMaskingGroupProps) {
  debug(`CreateMaskingGroup(): props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.maskingGroups.MaskingGroups');

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [name, setName] = React.useState('');

  const getSelectedDevicesSelector = () => {
    switch (props.deviceType) {
      case 'inputs':
        return useSelector(selectSelectedInputs) as SilencioDevice[];
      case 'readersAlarms':
        return useSelector(selectSelectedReadersAlarms) as SilencioDevice[];
      case 'readersModes':
        return useSelector(selectSelectedReadersModes) as SilencioDevice[];
    }
  };
  const selectedDevicesFromStore = getSelectedDevicesSelector();

  const username = useSelector(selectUsername);
  const userSelectedSite = useSelector(selectUserSelectedSite);

  const dispatch = useDispatch();

  const onChangeName = (input: string) => {
    setName(input.toUpperCase());
  }

  const displayNoteName = () => {
    if (name === '') {
      return `${userSelectedSite.sitename ?? 'SITE'}-`;
    } else {
      return formatMaskingGroupName(name, userSelectedSite.sitename ?? 'SITE');
    }
  }

  const saveOnClickHandler = async () => {
    const maskingGroupName = formatMaskingGroupName(name, userSelectedSite.sitename ?? 'SITE');
    if (!isMaskingGroupNameValid(maskingGroupName) || (props.maskingGroups && props.maskingGroups.find(mg => mg.name === maskingGroupName))) {
      setErrorMessage('Invalid Name: Names must not match a Masking Group, an Action Button, and must not include "MDF". Names may only contain the following characters: "A-Z 0-9 ( ) - . / _"');
      return;
    }
    if (!selectedDevicesFromStore || selectedDevicesFromStore.length < 1) {
      setErrorMessage('Error: Please select at least one device to create a masking group.');
      return;
    }
    if (selectedDevicesFromStore.length >= 100) {
      setErrorMessage('Error: Please select fewer than 100 devices while creating or editing a masking group.');
      return;
    }
    setLoading(true);

    try {
      debug(`Saving masking group: ${maskingGroupName}`);
      const maskingGroupType = getMaskingGroupType(props.deviceType);
      const result = await upsertMaskingGroup(userSelectedSite.siteRegion, maskingGroupName, maskingGroupType, username, null, selectedDevicesFromStore, null);
      debug(`CreateMaskingGroup() saveOnClickHandler() result: ${result}`);
      if (result.Result?.includes("Device Addition - Success") && result.Result_Description) {
        const { groupId } = /GroupID\s-\s(?<groupId>\d+)/.exec(result.Result_Description)!.groups!;
        const newGroup = {
          id: groupId,
          name: maskingGroupName,
          type: maskingGroupType
        }
        const maskingGroups = props.maskingGroups ? props.maskingGroups.concat([newGroup]).sort((a, b) => a.name > b.name ? 1 : -1) : [newGroup];
        switch (props.deviceType) {
          case 'inputs':
            dispatch(setInputsSearchMaskingGroup({ label: maskingGroupName, value: groupId }));
            dispatch(setInputsMaskingGroups({ maskingGroups: maskingGroups }));
            selectedDevicesFromStore.forEach(d => {
              dispatch(updateInputDeviceMaskingGroups({ ...d, MaskingGroups: d.MaskingGroups ? d.MaskingGroups.concat(newGroup) : [newGroup] }))
            });
            break;
          case 'readersAlarms':
            dispatch(setReadersAlarmsSearchMaskingGroup({ label: maskingGroupName, value: groupId }));
            dispatch(setReadersAlarmsMaskingGroups({ maskingGroups: maskingGroups }));
            selectedDevicesFromStore.forEach(d => {
              dispatch(updateReadersAlarmsDeviceMaskingGroups({ ...d, MaskingGroups: d.MaskingGroups ? d.MaskingGroups.concat(newGroup) : [newGroup] }))
            });
            break;
          case 'readersModes':
            dispatch(setReadersModesSearchMaskingGroup({ label: maskingGroupName, value: groupId }));
            dispatch(setReadersModesMaskingGroups({ maskingGroups: maskingGroups }));
            selectedDevicesFromStore.forEach(d => {
              dispatch(updateReadersModesDeviceMaskingGroups({ ...d, MaskingGroups: d.MaskingGroups ? d.MaskingGroups.concat(newGroup) : [newGroup] }))
            });
            break;
        }
        props.showModalCallback(false);
      } else {
        throw new Error(`upsertMaskingGroup result not Successful. Result: ${result.Result}`);
      }
    } catch (err) {
      debug(`CreateMaskingGroup() saveOnClickHandler() error: ${err}`);
      setErrorMessage(`Error creating masking group: ${maskingGroupName}`);
    }
    setLoading(false);
  };

  if (isBundleLoading) return <Spinner/>

  return (
    <Modal
      header={bundle.getMessage('create-masking-group')}
      visible={props.visible}
      onDismiss={() => props.showModalCallback(false)}
    >
      {
        loading ? <Spinner /> :
          <SpaceBetween direction='vertical' size={'l'}>
            <Alert
              dismissible={true}
              type='error'
              visible={errorMessage != null}
              onDismiss={() => setErrorMessage(null)}
            >
              {errorMessage}
            </Alert>
            <SpaceBetween direction='horizontal' size={'xxs'}>
              <TextContent>
                <p>{`${userSelectedSite.sitename ?? 'SITE'}-`}</p>
              </TextContent>
              <Input
                type='text'
                value={name}
                onChange={event => onChangeName(event.detail.value)}
                placeholder={bundle.getMessage('enter-masking-group-name')}
                ariaDescribedby={undefined}
                autoFocus={true}
              />
            </SpaceBetween>
            {
              bundle.formatMessage('create-masking-group-note', {
                name: displayNoteName(),
                deviceCount: selectedDevicesFromStore ? selectedDevicesFromStore.length : 0
              })
            }
            <Button
              variant='primary'
              onClick={saveOnClickHandler}
              disabled={!name}
              ariaLabel={bundle.getMessage('save-masking-group')}
            >
              {bundle.getMessage('save')}
            </Button>
          </SpaceBetween>
      }
    </Modal>
  );
}
