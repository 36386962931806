import * as APIt from 'src/API';
import {
  Alert,
  Box,
  Button,
  Modal,
  Multiselect,
  MultiselectProps,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react';
import { DeviceTypeTabs, TemplateTypes } from 'src/constants/Constants';
import { MaskingGroupInfo, SilencioDevice, TemplateRow } from "src/utils/SilencioTypes";
import React, { useState } from 'react';
import {
  selectInputDevices,
  selectInputsMaskingGroups,
  selectReadersAlarmsDevices,
  selectReadersAlarmsMaskingGroups,
  selectReadersModesDevices,
  selectReadersModesMaskingGroups,
} from 'src/stores/slices/devicesSlice';
import { selectUserSelectedSite, selectUsername } from 'src/stores/slices/userSlice';
import { debug } from '../../utils/commonUtils';
import { getActionOptions } from '../schedules/utils';
import { selectLoadingTemplates } from 'src/stores/slices/templatesSlice';
import { upsertSiteTemplate } from 'src/utils/TemplatesUtils';
import { useBundle } from "@amzn/react-arb-tools";
import { useSelector } from 'react-redux';

interface IUpdateTemplateProps {
  refreshTemplatesCallback: Function;
  showModalCallback: Function;
  templateRows: TemplateRow[];
  visible: boolean;
}

export const UpdateTemplate = (props: IUpdateTemplateProps) => {
  debug(`UpdateTemplate() props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.templates.UpdateTemplate');
  const [commonBundle, isCommonBundleLoading] = useBundle('components.Common');

  const filterTemplateRows = (action: string, templateType: string): TemplateRow[] => {
    return props.templateRows.filter(row => (row.action === action && row.templateType === templateType));
  }

  const [deviceType, setDeviceType] = React.useState<DeviceTypeTabs>(DeviceTypeTabs.inputs);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedAction, setSelectedAction] = React.useState<SelectProps.Option>({ label: isCommonBundleLoading ? 'Mask' : commonBundle.getMessage('mask'), value: 'Mask' });
  const [selectedEntities, setSelectedEntities] = useState<MultiselectProps.Options>(
    filterTemplateRows('Mask', TemplateTypes.open).map(row => ({ label: row.entityName, value: row.entityId }))
  );
  const [selectedTemplateType, setSelectedTemplateType] = React.useState<SelectProps.Option>({ label: isBundleLoading ? 'Open Site' : bundle.getMessage('open-site'), value: TemplateTypes.open });

  const inputDevices = useSelector(selectInputDevices) as SilencioDevice[];
  const inputsMaskingGroups = useSelector(selectInputsMaskingGroups) as MaskingGroupInfo[];
  const loadingTemplates = useSelector(selectLoadingTemplates) as boolean;
  const readersAlarmsDevices = useSelector(selectReadersAlarmsDevices) as SilencioDevice[];
  const readersAlarmsMaskingGroups = useSelector(selectReadersAlarmsMaskingGroups) as MaskingGroupInfo[];
  const readersModesDevices = useSelector(selectReadersModesDevices) as SilencioDevice[];
  const readersModesMaskingGroups = useSelector(selectReadersModesMaskingGroups) as MaskingGroupInfo[];
  const username = useSelector(selectUsername) as string;
  const userSelectedSite = useSelector(selectUserSelectedSite) as APIt.UserPrefSite | undefined;

  const getDevices = (deviceType: DeviceTypeTabs): SilencioDevice[] => {
    switch (deviceType) {
      case DeviceTypeTabs.inputs:
        return inputDevices;
      case DeviceTypeTabs.readersAlarms:
        return readersAlarmsDevices;
      default:
        return readersModesDevices;
    }
  }

  const getEntityOptions = (deviceType: DeviceTypeTabs): SelectProps.Option[] => {
    const devices = getDevices(deviceType);
    const maskingGroups = getMaskingGroups(deviceType);

    debug(`UpdateTemplate() getEntityOptions() deviceType is ${deviceType}`);
    let options = [
      {
        label: 'Masking Groups',
        options: maskingGroups.map(mg => ({ label: mg.name, value: mg.id }))
      },
      {
        label: 'Devices',
        options: devices.map(device => {
          const amzn_key = `${device.Parent_DeviceID}_${device.Child_DeviceID}_${device.Subchild_DeviceID}`;
          if (device.PairedReader) {
            return { label: device.DeviceName!, tags: ['Paired Reader', device.PairedReader], value: amzn_key }
          }
          return { label: device.DeviceName!, value: amzn_key }
        })
      }
    ];
    debug(`UpdateTemplate() getEntityOptions() options are: ${JSON.stringify(options)}`);
    return options;
  }

  const getMaskingGroups = (deviceType: DeviceTypeTabs): MaskingGroupInfo[] => {
    switch (deviceType) {
      case DeviceTypeTabs.inputs:
        return inputsMaskingGroups;
      case DeviceTypeTabs.readersAlarms:
        return readersAlarmsMaskingGroups;
      default:
        return readersModesMaskingGroups;
    }
  };

  const saveHandler = async () => {
    setLoading(true);
    setErrorMessage(null);

    const action = selectedAction.value;
    const templateType = selectedTemplateType.value;
    const siteRegion = userSelectedSite?.siteRegion;

    if (!action || !templateType) {
      setErrorMessage('Action and Template Type are required to save');
    } else if (!siteRegion) {
      setErrorMessage('Unable to determine site region');
    } else {
      try {
        const existingRows = filterTemplateRows(action, templateType);
        debug(`UpdateTemplate() saveHandler() existing rows ${JSON.stringify(existingRows)}`);
        debug(`UpdateTemplate() saveHandler() selected entities ${JSON.stringify(selectedEntities)}`);

        const addList: string[] = selectedEntities.filter((selectedEntity: MultiselectProps.Option) =>
          selectedEntity.value && !existingRows.find(existing => existing.entityId === selectedEntity.value)
        ).map((selectedEntity: MultiselectProps.Option) => selectedEntity.value!);

        const removeList: string[] = existingRows.filter((existing: TemplateRow) =>
          !selectedEntities.find((selectedEntity: MultiselectProps.Option) => existing.entityId === selectedEntity.value)
        ).map((existing: TemplateRow) => existing.entityId);

        debug(`UpdateTemplate() saveHandler() addList: ${JSON.stringify(addList)}`);
        debug(`UpdateTemplate() saveHandler() removeList: ${JSON.stringify(removeList)}`);

        let errors: string[] = [];
        if (addList.length === 0 && removeList.length === 0) {
          debug('UpdateTemplate() saveHandler() no action to take.');
        } else {
          const addDevices: string[] = [];
          const addGroups: string[] = [];
          addList.forEach((entity: string) => {
            if (entity.includes('_')) {
              addDevices.push(entity);
            } else {
              addGroups.push(entity);
            }
          });
  
          const removeDevices: string[] = [];
          const removeGroups: string[] = [];
          removeList.forEach((entity: string) => {
            if (entity.includes('_')) {
              removeDevices.push(entity);
            } else {
              removeGroups.push(entity);
            }
          });
  
          const response = await upsertSiteTemplate(
            addDevices.join(','), addGroups.join(','), action, removeDevices.join(','), removeGroups.join(','), username, siteRegion, templateType as TemplateTypes
          );
          debug(`UpdateTemplate() saveHandler() result: ${response}`);
          if (!response.Result) throw new Error(`Invalid Result ${JSON.stringify(response)}`);
  
          if (addDevices.length > 0 && !response.Result.includes('Device Addition to Template - Success')) {
            errors.push('Error adding device(s) to template.');
          }
          if (addGroups.length > 0 && !response.Result.includes('Masking Group Addition to Template - Success')) {
            errors.push('Error adding masking group(s) to template.');
          }
          if (removeDevices.length > 0 && !response.Result.includes('Device Deletion - Success')) {
            errors.push('Error removing device(s) from template.');
          }
          if (removeGroups.length > 0 && !response.Result.includes('Masking Group Deletion - Success')) {
            errors.push('Error removing masking group(s) from template.');
          }
          props.refreshTemplatesCallback();
        }

        if (errors.length === 1) {
          setErrorMessage(`Error: ${errors[0]}`);
        } else if (errors.length > 1) {
          setErrorMessage(`Errors: ${errors.join(' ')}`);
        } else {
          props.showModalCallback(false);
        }
      } catch (err) {
        debug(`UpdateTemplate() saveHandler() error: ${err}`);
        setErrorMessage('Error updating site template.');
      }
    }
    setLoading(false);
  };

  const selectedActionOnChange = (detail: SelectProps.ChangeDetail): void => {
    debug(`UpdateTemplate() selectedActionOnChange() detail is ${JSON.stringify(detail)}`);
    const value = detail.selectedOption.value!;
    switch (value) {
      case 'Mask':
      case 'Unmask':
        setDeviceType(DeviceTypeTabs.inputs);
        break;
      case 'Mask Door Forced Open':
      case 'Unmask Door Forced Open':
      case 'Mask Door Held Open':
      case 'Unmask Door Held Open':
        setDeviceType(DeviceTypeTabs.readersAlarms);
        break;
      default:
        setDeviceType(DeviceTypeTabs.readersModes);
        break;
    }
    setSelectedAction({ label: detail.selectedOption.label!, value: value });

    if (selectedTemplateType.value) {
      setSelectedEntities(filterTemplateRows(value, selectedTemplateType.value).map(row => ({ label: row.entityName, value: row.entityId })));
    } else {
      setSelectedEntities([]);
    }
  }

  const selectedEntitiesOnChange = (detail: MultiselectProps.MultiselectChangeDetail): void => {
    debug(`UpdateTemplate() selectedEntitiesOnChange() detail is ${JSON.stringify(detail)}`);
    setSelectedEntities(detail.selectedOptions);
  }

  const selectedTemplateTypeOnChange = (detail: SelectProps.ChangeDetail): void => {
    debug(`UpdateTemplate() selectedTemplateTypeOnChange() detail is ${JSON.stringify(detail)}`);
    const value = detail.selectedOption.value;
    if (!value) {
      setErrorMessage('Template Type value is required');
    } else {
      setSelectedTemplateType({ label: detail.selectedOption.label, value: value });

      if (selectedAction.value) {
        setSelectedEntities(filterTemplateRows(selectedAction.value, value).map(row => ({ label: row.entityName, value: row.entityId })));
      } else {
        setSelectedEntities([]);
      }
    }
  }

  if (isBundleLoading || isCommonBundleLoading) return <Spinner />;

  return (
    <Modal
      footer={<Box float='right'>
        <SpaceBetween direction='horizontal' size='xs'>
          <Button
            variant='primary'
            onClick={saveHandler}
            disabled={(loading || loadingTemplates)}
          >
            {bundle.getMessage('save')}
          </Button>
        </SpaceBetween>
      </Box>}
      header={bundle.getMessage('whole-site-templates')}
      onDismiss={() => props.showModalCallback(false)}
      size='large'
      visible={props.visible}
    >
      {(loading || loadingTemplates) ? <Spinner /> :
        <SpaceBetween size='m' direction='vertical'>
          {errorMessage != null &&
            <Alert
              dismissible={true}
              onDismiss={() => setErrorMessage(null)}
              type='error'
            >
              {errorMessage}
            </Alert>
          }
          <SpaceBetween direction='horizontal' size='xs'>
            <Select
              expandToViewport
              onChange={({ detail }) => { selectedTemplateTypeOnChange(detail) }}
              options={[
                { label: bundle.getMessage('open-site'), value: TemplateTypes.open },
                { label: bundle.getMessage('close-site'), value: TemplateTypes.close }
              ]}
              selectedAriaLabel={bundle.getMessage('template-type')}
              selectedOption={selectedTemplateType}
            />
            <Select
              expandToViewport
              onChange={({ detail }) => { selectedActionOnChange(detail) }}
              options={getActionOptions(commonBundle)}
              selectedAriaLabel={bundle.getMessage('selected-action')}
              selectedOption={selectedAction}
            />
          </SpaceBetween>
          <Multiselect
            filteringType='auto'
            loadingText={bundle.getMessage('loading')}
            onChange={({ detail }) => { selectedEntitiesOnChange(detail) }}
            options={getEntityOptions(deviceType)}
            placeholder={bundle.getMessage('select-placeholder')}
            selectedOptions={selectedEntities}
            tokenLimit={5}
            virtualScroll={true}
          />
        </SpaceBetween>
      }
    </Modal>
  );
}
