import { Button, Container, Header, Link, SpaceBetween, Spinner, TextContent } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { PerformActions } from './PerformActions';
import { TemplateTypes, URLS } from 'src/constants/Constants';
import { debug } from 'src/utils/commonUtils';
import { useBundle } from '@amzn/react-arb-tools';

interface IQuickActions {
  refreshDevicesCallback: Function;
}

export const QuickActions = (props: IQuickActions) => {
  debug(`QuickActions() props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.templates.QuickActions');

  const [showPerformActions, setShowPerformActions] = useState(false);
  const [templateType, setTemplateType] = useState<TemplateTypes | null>(null);

  const performActions = (templateType: TemplateTypes): void => {
    debug(`QuickActions() performActions() templateType is ${templateType}`);
    setTemplateType(templateType);
    setShowPerformActions(true);
  }

  if (isBundleLoading) return <Spinner />

  return (
    <SpaceBetween direction='vertical' size='m'>
      <Container
        header={
          <Header
            description={<>
              <TextContent>
                {bundle.getMessage('configure-quick-actions')}
              </TextContent>
              <Link href={URLS.SiteOpenClose} external target={'_blank'}>
                <b>{bundle.getMessage('see-more-on-templates')}</b>
              </Link>
            </>}
          >
            {bundle.getMessage('quick-actions')}
          </Header>
        }
      >
        <SpaceBetween direction='horizontal' size='xs'>
          <Button
            variant='primary'
            onClick={() => performActions(TemplateTypes.open)}
          >
            <Header>
              {bundle.getMessage('open-site')}
            </Header>
          </Button>
          <Button
            variant='primary'
            onClick={() => performActions(TemplateTypes.close)}
          >
            <Header>
              {bundle.getMessage('close-site')}
            </Header>
          </Button>
        </SpaceBetween >
      </Container>
      {showPerformActions &&
        <PerformActions
          refreshDevicesCallback={props.refreshDevicesCallback}
          showModalCallback={setShowPerformActions}
          templateType={templateType}
          visible={showPerformActions}
        />
      }
    </SpaceBetween>
  );
}