import { createSlice } from '@reduxjs/toolkit';
import { TemplateRow } from 'src/utils/SilencioTypes';

export interface TemplatesSliceInterface {
  loadingTemplates: boolean;
  templateRows: TemplateRow[];
};

export const initialTemplatesState: TemplatesSliceInterface = {
  loadingTemplates: false,
  templateRows: [],
};

export const templatesSlice = createSlice({
  name: 'templatesSlice',
  initialState: initialTemplatesState,
  reducers: {
    setLoadingTemplates: (state, action: { type: string, payload: boolean }) => {
      state.loadingTemplates = action.payload;
    },
    setTemplateRows: (state, action: { type: string, payload: { templateRows: TemplateRow[] } }) => {
      state.templateRows = action.payload.templateRows;
    },
  },
});

export const {
  setLoadingTemplates,
  setTemplateRows,
} = templatesSlice.actions;

export const selectLoadingTemplates = (state: any) => state.templates.loadingTemplates;
export const selectTemplateRows = (state: any) => state.templates.templateRows;

export default templatesSlice.reducer;