import React from 'react';
import { SpaceBetween } from '@amzn/awsui-components-react';
import DevicesTable from './DevicesTable';
import { useSelector } from 'react-redux';
import { DevicesSliceInterface, selectDevices } from '../stores/slices/devicesSlice';
import { debug } from '../utils/commonUtils';

interface SiteDevicesInterface {
  deviceType: string;
  refreshDevicesCallback(): Promise<void>;
}

export default function SiteDevices(props: SiteDevicesInterface) {
  debug(`SiteDevices(): props is ${JSON.stringify(props)}`);

  const devices = useSelector(selectDevices) as DevicesSliceInterface;

  return(
    <div>
      <SpaceBetween size="xs" direction="vertical">
        {
          devices &&
          <DevicesTable
            deviceType={props.deviceType}
            loadingDevices={devices.loadingDevices}
            refreshDevicesCallback={props.refreshDevicesCallback}
          />
        }
      </SpaceBetween>
    </div>
  );
}