import { createSlice } from '@reduxjs/toolkit';
import { debug } from '../../utils/commonUtils';
import { ScheduleRow } from 'src/utils/SilencioTypes';

export interface SchedulesSliceInterface {
  loadingSchedules: boolean;
  schedules: ScheduleRow[];
  tokenIndex: number,
  tokens: Array<string | undefined>,
};

export const initialSchedulesState: SchedulesSliceInterface = {
  loadingSchedules: false,
  schedules: [],
  tokenIndex: 0,
  tokens: [undefined],
};

export const schedulesSlice = createSlice({
  name: 'schedulesSlice',
  initialState: initialSchedulesState,
  reducers: {
    addToken: (state, action: { type: string, payload: { token: string | null | undefined } }) => {
      state.tokens.push(action.payload.token || undefined);
    },
    clearTokens: (state) => {
      state.tokens = [undefined];
    },
    deleteSchedule: (state, action: { type: string, payload: { scheduleName: string } }) => {
      state.schedules = state.schedules.filter(schedule => schedule.Name != action.payload.scheduleName);
    },
    setLoadingSchedules: (state, action: { type: string, payload: boolean }) => {
      state.loadingSchedules = action.payload;
    },
    setTokenIndex: (state, action: { type: string, payload: { index: number } }) => {
      state.tokenIndex = action.payload.index;
    },
    setSchedules: (state, action: { type: string, payload: { schedules: ScheduleRow[] } }) => {
      state.schedules = action.payload.schedules;
    },
    updateSchedule: (state, action: { type: string, payload: { scheduleName: string, enabled: boolean } }) => {
      debug(`updateSchedule() action is ${JSON.stringify(action)}`);
      state.schedules.forEach((s: ScheduleRow, i) => {
        if (s.Name === action.payload.scheduleName) state.schedules[i].State = action.payload.enabled ? 'ENABLED' : 'DISABLED';
      });
    },
  },
});

export const {
  addToken,
  clearTokens,
  deleteSchedule,
  setLoadingSchedules,
  setTokenIndex,
  setSchedules,
  updateSchedule,
} = schedulesSlice.actions;

export const selectLoadingSchedules = (state: any) => state.schedules.loadingSchedules;
export const selectSchedules = (state: any) => state.schedules.schedules;
export const selectTokenIndex = (state: any) => state.schedules.tokenIndex;
export const selectTokens = (state: any) => state.schedules.tokens;

export default schedulesSlice.reducer;