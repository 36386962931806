import {
  Alert,
  Button,
  Input,
  Modal,
  RadioGroup,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
  TextContent
} from '@amzn/awsui-components-react';
import { MaskingGroupInfo, SilencioDevice } from "src/utils/SilencioTypes";
import React, { useEffect } from 'react';
import { formatMaskingGroupName, getMaskingGroupType, isMaskingGroupKeyword, isMaskingGroupNameValid } from './utils';
import {
  selectSelectedInputs,
  selectSelectedReadersAlarms,
  selectSelectedReadersModes,
  setInputsSearchMaskingGroup,
  setReadersAlarmsSearchMaskingGroup,
  setReadersModesSearchMaskingGroup
} from '../../stores/slices/devicesSlice';
import { selectUserSelectedSite, selectUsername } from 'src/stores/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { debug } from 'src/utils/commonUtils';
import { upsertMaskingGroup } from '../../utils/DevicesUtils';
import { useBundle } from '@amzn/react-arb-tools';

export interface IEditMaskingGroupProps {
  deviceType: string;
  maskingGroups: MaskingGroupInfo[];
  refreshDevicesCallback: Function;
  resetSelectedItemsCallback: Function;
  selectedMaskingGroup: SelectProps.Option;
  showModalCallback: Function;
  visible: boolean;
}

export default function EditMaskingGroup(props: IEditMaskingGroupProps) {
  debug(`EditMaskingGroup(): props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.maskingGroups.MaskingGroups');

  const [name, setName] = React.useState<string>("");
  const [editAction, setEditAction] = React.useState("add");
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [maskingGroupOptions, setMaskingGroupOptions] = React.useState<SelectProps.Options>();
  const [selectedMaskingGroup, setSelectedMaskingGroup] = React.useState<SelectProps.Option>(props.selectedMaskingGroup);

  const getSelectedDevicesSelector = () => {
    switch (props.deviceType) {
      case 'inputs':
        return useSelector(selectSelectedInputs) as SilencioDevice[];
      case 'readersAlarms':
        return useSelector(selectSelectedReadersAlarms) as SilencioDevice[];
      case 'readersModes':
        return useSelector(selectSelectedReadersModes) as SilencioDevice[];
    }
  };
  const selectedDevicesFromStore = getSelectedDevicesSelector();

  const username = useSelector(selectUsername);
  const userSelectedSite = useSelector(selectUserSelectedSite);

  const dispatch = useDispatch();

  useEffect(() => {
    debug(`EditMaskingGroup() useEffect() [props.selectedMaskingGroup, props.maskingGroups]`);
    let options = props.maskingGroups.map((mg: MaskingGroupInfo) => ({ label: mg.name, value: mg.id }));
    debug(`EditMaskingGroup() useEffect() maskingGroups options is ${JSON.stringify(options)}`);
    setMaskingGroupOptions(options);
    setSelectedMaskingGroup(props.selectedMaskingGroup);
  }, [props.selectedMaskingGroup, props.maskingGroups])

  const onChangeName = (input: string) => {
    setName(input.toUpperCase());
  }

  const saveOnClickHandler = async () => {
    if (!selectedMaskingGroup.label || isMaskingGroupKeyword(selectedMaskingGroup.label) || !selectedMaskingGroup.value) {
      setErrorMessage('Error: must select a valid Masking Group to edit.');
      return;
    }
    const selectedName = selectedMaskingGroup.label;
    const selectedId = selectedMaskingGroup.value;
    let newName: string | null = formatMaskingGroupName(name, userSelectedSite.sitename ?? 'SITE');
    const renameDuplicate = (newName && newName != selectedName && props.maskingGroups.find(mg => mg.name === newName));
    if (newName && !isMaskingGroupNameValid(newName) || renameDuplicate) {
      setErrorMessage('Invalid Name: Names must not match a Masking Group, an Action Button, and must not include "MDF". Names may only contain the following characters: "A-Z 0-9 ( ) - . / _"');
      return;
    }
    if (!newName && (!selectedDevicesFromStore || selectedDevicesFromStore.length < 1)) {
      setErrorMessage('Error: No action to perform.');
      return;
    }
    if (selectedDevicesFromStore && selectedDevicesFromStore.length >= 100) {
      setErrorMessage('Error: Please select fewer than 100 devices while editing a masking group.');
      return;
    }

    setLoading(true);
    try {
      debug(`EditMaskingGroup() saveOnClickHandler() Editing masking group: ${selectedName} (${selectedId})`);
      newName = newName !== '' ? newName : null;
      let addDevices = null;
      let removeDevices = null;
      if (selectedDevicesFromStore && selectedDevicesFromStore.length > 0) {
        if (editAction == 'add') {
          addDevices = selectedDevicesFromStore;
        } else {
          removeDevices = selectedDevicesFromStore;
        }
      }
      const maskingGroupType = getMaskingGroupType(props.deviceType);

      const result = await upsertMaskingGroup(userSelectedSite.siteRegion, selectedName, maskingGroupType, username, newName, addDevices, removeDevices);
      debug(`EditMaskingGroup() saveOnClickHandler() result: ${result}`);

      if (!result.Result) throw new Error(`Invalid Result ${JSON.stringify(result)}`);

      const errors: string[] = [];
      if (addDevices && !result.Result.includes('Device Addition - Success')) {
        errors.push('Error adding devices to masking group.');
      }
      if (removeDevices) {
        if (!result.Result.includes('Device Deletion - Success')) {
          errors.push('Error removing devices from masking group.');
        } else {
          props.resetSelectedItemsCallback();
        }
      }
      if (newName && !result.Result.includes('Group Renaming - Success')) {
        errors.push('Error renaming masking group.');
      }

      props.refreshDevicesCallback();

      if (errors.length == 1) {
        setErrorMessage(`Error: ${errors[0]}`);
      } else if (errors.length > 1) {
        setErrorMessage(`Errors: ${errors.join(' ')}`);
      } else {
        const resultingSelectedMaskingGroup = { label: newName ?? selectedName, value: selectedId };
        switch (props.deviceType) {
          case 'inputs':
            dispatch(setInputsSearchMaskingGroup(resultingSelectedMaskingGroup));
            break;
          case 'readersAlarms':
            dispatch(setReadersAlarmsSearchMaskingGroup(resultingSelectedMaskingGroup));
            break;
          case 'readersModes':
            dispatch(setReadersModesSearchMaskingGroup(resultingSelectedMaskingGroup));
            break;
        }
        props.showModalCallback(false);
      }
    } catch (err) {
      debug(`EditMaskingGroup() saveOnClickHandler() error: ${err}`);
      setErrorMessage(`Error editing masking group: ${selectedName} (${selectedId})`);
    }
    setLoading(false);
  };

  if (isBundleLoading) return <Spinner />

  return (
    <Modal
      header={bundle.getMessage('edit-masking-group')}
      visible={props.visible}
      onDismiss={() => props.showModalCallback(false)}
    >
      {
        loading ? <Spinner /> :
          <SpaceBetween direction='vertical' size={'l'}>
            <Alert
              dismissible={true}
              type='error'
              visible={errorMessage != null}
              onDismiss={() => setErrorMessage(null)}
            >
              {errorMessage}
            </Alert>
            <Select
              onChange={({ detail }) => { setSelectedMaskingGroup(detail.selectedOption) }}
              options={maskingGroupOptions}
              selectedAriaLabel={bundle.getMessage('selected-masking-group')}
              selectedOption={selectedMaskingGroup}
              filteringType="auto"
              expandToViewport
            />
            <SpaceBetween direction='horizontal' size={'xxs'}>
              <TextContent>
                <p>{`${userSelectedSite.sitename ?? 'SITE'}-`}</p>
              </TextContent>
              <Input
                type='text'
                value={name}
                onChange={event => onChangeName(event.detail.value)}
                placeholder={bundle.getMessage('optional-new-name')}
                ariaDescribedby={undefined}
                autoFocus={true}
              />
            </SpaceBetween>
            {
              (formatMaskingGroupName(name, userSelectedSite.sitename ?? 'SITE') != '')
                ? bundle.formatMessage('rename-message', { newName: formatMaskingGroupName(name, userSelectedSite.sitename ?? 'SITE') })
                : ''
            }
            <RadioGroup
              onChange={({ detail }) => setEditAction(detail.value)}
              value={editAction}
              items={[
                { value: "add", label: bundle.getMessage('add-devices') },
                { value: "remove", label: bundle.getMessage('remove-devices') },
              ]}
            />
            {
              (editAction == "add")
                ? bundle.formatMessage('add-devices-note', { deviceCount: selectedDevicesFromStore ? selectedDevicesFromStore.length : 0 })
                : bundle.formatMessage('remove-devices-note', { deviceCount: selectedDevicesFromStore ? selectedDevicesFromStore.length : 0 })
            }
            {
              (editAction == "remove")
                ? bundle.formatMessage('remove-devices-warning', { maskingGroupName: selectedMaskingGroup.label })
                : ''
            }
            <Button
              variant='primary'
              onClick={saveOnClickHandler}
              disabled={!selectedMaskingGroup.value || (!name && selectedDevicesFromStore?.length == 0)}
              ariaLabel={bundle.getMessage('save-masking-group')}
            >
              {bundle.getMessage('save')}
            </Button>
          </SpaceBetween>
      }
    </Modal>
  );
}
