import React from 'react';
import { HOW_MANY_TRACKER } from '../constants/Constants';
import { selectUsername } from '../stores/slices/userSlice';
import { useSelector } from 'react-redux';

export default function Tracker() {
  const hostname = window.location.hostname;
  const username = useSelector(selectUsername);

  let trackerURL = '';

  switch (hostname) {
    case 'beta.silencio.gso.amazon.dev':
      trackerURL = HOW_MANY_TRACKER.WebsiteAccess_URL_BETA;
      break;
    case 'silencio.gso.amazon.dev':
      trackerURL = HOW_MANY_TRACKER.WebsiteAccess_URL_PROD;
      break;
    case 'localhost':
      trackerURL = HOW_MANY_TRACKER.WebsiteAccess_URL_LOCAL;
      break;
    case 'silencio.local.amazon.com':
      trackerURL = HOW_MANY_TRACKER.WebsiteAccess_URL_LOCAL;
      break;
  }

  const how_many_tracker_url = `${trackerURL}&login=${username}`;

  return (
    <img src={how_many_tracker_url} />
  )
}
