import * as APIt from 'src/API';
import {
  Alert,
  Button,
  CollectionPreferences,
  Header,
  Icon,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Spinner,
  Table,
  TableProps,
} from '@amzn/awsui-components-react';
import { ColumnDefinitions, TemplatesFilteringOptions, FilteringProperties } from './template-table-config';
import { PaginationLabels, TableEmptyState, TableNoMatchState } from '../table-config';
import React, { useEffect, useState } from 'react';
import { extractTemplateRows, getSiteTemplateDevices, upsertSiteTemplate } from 'src/utils/TemplatesUtils';
import {
  selectLoadingDevices,
  selectReadersAlarmsDevices,
} from 'src/stores/slices/devicesSlice';
import {
  selectLoadingTemplates,
  selectTemplateRows,
  setLoadingTemplates,
  setTemplateRows,
} from 'src/stores/slices/templatesSlice';
import {
  selectUsername,
  selectUserPrefs,
  selectUserSelectedSite,
  setUserPrefs,
} from 'src/stores/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { SilencioDevice } from "src/utils/SilencioTypes";
import { TemplateRow } from 'src/utils/SilencioTypes';
import TranslateText from '../TranslateText';
import { UpdateTemplate } from './UpdateTemplate';
import { debug } from 'src/utils/commonUtils';
import { updateUserPrefs } from 'src/utils/UserPrefsUtils';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TemplateTypes } from 'src/constants/Constants';

export default function TemplatesTable() {
  const [bundle, isBundleLoading] = useBundle('components.templates.TemplatesTable');

  const dispatch = useDispatch();

  const loadingDevices = useSelector(selectLoadingDevices) as boolean;
  const loadingTemplates = useSelector(selectLoadingTemplates) as boolean;
  const readersAlarmsDevices = useSelector(selectReadersAlarmsDevices) as SilencioDevice[];
  const templateRows = useSelector(selectTemplateRows) as TemplateRow[];
  const userPrefs = useSelector(selectUserPrefs) as APIt.UserPrefs | undefined;
  const username = useSelector(selectUsername) as string;
  const userSelectedSite = useSelector(selectUserSelectedSite) as APIt.UserPrefSite | undefined;

  const initialPageSize = (): number => {
    const defaultNumRecs = 50;
    let numRecs;
    try {
      numRecs = userPrefs?.siteTemplates?.numRecordsPerPage;
      debug(`pageSizeFromStore() numRecs is ${numRecs ?? defaultNumRecs}`);
      return numRecs ?? defaultNumRecs;
    } catch (error) {
      return defaultNumRecs;
    }
  }

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [pageSize, setPageSize] = useState<number>(initialPageSize());
  const [showUpdateTemplate, setShowUpdateTemplate] = useState(false);

  const deleteRowHandler = async (row: TemplateRow) => {
    debug(`TemplatesTable() deleteRowHandler() row ${JSON.stringify(row)}`);
    setErrorMessage(null);
    dispatch(setLoadingTemplates(true));

    const siteRegion = userSelectedSite?.siteRegion;
    if (!siteRegion) {
      setErrorMessage('Error: Unable to determine site region');
    } else {
      try {
        let response: APIt.SiteTemplateResponse | null = null;
        if (row.entityId.includes('_')) {
          response = await upsertSiteTemplate(null, null, row.action, row.entityId, null, username, siteRegion, row.templateType as TemplateTypes);
        } else {
          response = await upsertSiteTemplate(null, null, row.action, null, row.entityId, username, siteRegion, row.templateType as TemplateTypes);
        }
        debug(`TemplatesTable() deleteRowHandler() response: ${response}`);

        if (!response?.Result?.includes('Success')) {
          throw new Error('Invalid result while deleting row');
        } else {
          dispatch(setTemplateRows({ templateRows: templateRows.filter(r => r.action !== row.action || r.templateType !== row.templateType || r.entityId !== row.entityId) }));
        }
      } catch (err) {
        debug(`TemplatesTable() deleteRowHandler() error: ${err}`);
        setErrorMessage(`Error while deleting row: ${row.templateType} ${row.action} ${row.entityName}`);
      }
    }
    dispatch(setLoadingTemplates(false));
  }

  const refreshTemplateRows = async () => {
    setErrorMessage(null);
    dispatch(setLoadingTemplates(true));
    dispatch(setTemplateRows({ templateRows: [] }));
    try {
      const siteCode = userSelectedSite?.sitename;
      const siteRegion = userSelectedSite?.siteRegion;
      debug(`TemplatesTable() fetchTemplates() siteCode is ${siteCode} siteRegion is ${siteRegion}`);
      if (!siteCode || !siteRegion) {
        throw new Error('TemplatesTable() fetchTemplates() siteCode or siteRegion is undefined');
      }
      const openTemplateDevices: APIt.TemplateDevice[] = await getSiteTemplateDevices(siteCode, siteRegion, TemplateTypes.open, username);
      const closeTemplateDevices: APIt.TemplateDevice[] = await getSiteTemplateDevices(siteCode, siteRegion, TemplateTypes.close, username);
      const extractedTemplateRows = extractTemplateRows(readersAlarmsDevices, [...openTemplateDevices, ...closeTemplateDevices]);
      dispatch(setTemplateRows({ templateRows: extractedTemplateRows }));
    } catch (error) {
      console.error(`TemplatesTable() fetchTemplates() error is ${error}`);
      setErrorMessage('Error while loading site templates');
    }
    dispatch(setLoadingTemplates(false));
  };

  const getColumnDefinitions = (
    bundle: { getMessage: (id: string) => string; formatMessage: (id: string, ...args: any) => string; }
  ): TableProps.ColumnDefinition<TemplateRow>[] => {
    return [
      ...ColumnDefinitions,
      {
        id: 'Delete',
        header: <TranslateText translateKey={'delete'} />,
        cell: (item: TemplateRow) =>
          <Button
            onClick={() => deleteRowHandler(item)}
            ariaLabel={bundle.getMessage('delete')}
          >
            {bundle.getMessage('delete')}
          </Button>
      }
    ];
  }

  const updatePreferencesHandler = async (details: any) => {
    setErrorMessage(null);
    debug(`TemplatesTable() updatePreferencesHandler() details: ${JSON.stringify(details)}`);
    dispatch(setLoadingTemplates(true));
    try {
      setPageSize(details.pageSize);
      if (!userPrefs) {
        throw new Error('User prefs not yet defined.');
      }
      const newUserPrefs = await updateUserPrefs(
        {
          ...userPrefs,
          ['siteTemplates']: {
            __typename: 'SiteTemplatesPrefs',
            numRecordsPerPage: details.pageSize,
          }
        });
      if (newUserPrefs) dispatch(setUserPrefs(newUserPrefs));
    } catch (err) {
      console.error(`TemplatesTable() updatePreferencesHandler() Error: ${err}`);
      setErrorMessage(`Error updating user preferences`);
    }
    dispatch(setLoadingTemplates(false));
  }

  const { items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps } = useCollection(
    templateRows,
    {
      propertyFiltering: {
        filteringProperties: FilteringProperties,
        empty: <TableEmptyState title='No templates' />,
        noMatch: (
          <TableNoMatchState
            onClearFilter={() => {
              actions.setPropertyFiltering({ tokens: [], operation: 'and' });
            }}
          />
        ),
      },
      pagination: { pageSize: pageSize },
      sorting: { defaultState: { sortingColumn: ColumnDefinitions[0] } },
      selection: {},
    }
  );

  useEffect(() => {
    debug(`TemplatesTable() useEffect() Site set. Clearing templates and loading. Will fetch templates after devices load.`);
    dispatch(setLoadingTemplates(true));
    dispatch(setTemplateRows({ templateRows: [] }));
  }, [userSelectedSite]);

  useEffect(() => {
    debug(`TemplatesTable() useEffect() loading devices: ${loadingDevices}.`);
    if (!loadingDevices) {
      (async () => {
        await refreshTemplateRows();
      })();
    }
  }, [loadingDevices]);

  useEffect(() => {
    debug(`TemplatesTable() useEffect() templateRows from store: ${templateRows.length}`);
  }, [templateRows]);

  if (isBundleLoading) return <Spinner />;

  return (
    <div>
      <SpaceBetween direction='vertical' size='m'>
        {errorMessage &&
          <Alert
            dismissible={true}
            type='error'
            onDismiss={() => setErrorMessage(null)}
          >
            {errorMessage}
          </Alert>
        }
        <Table
          {...collectionProps}
          ariaLabels={{
            tableLabel: bundle.getMessage('whole-site-templates')
          }}
          columnDefinitions={getColumnDefinitions(bundle)}
          empty={<TableEmptyState title={bundle.getMessage('empty')} />}
          filter={
            <PropertyFilter
              {...propertyFilterProps}
              filteringOptions={TemplatesFilteringOptions}
              i18nStrings={{
                filteringAriaLabel: bundle.getMessage('filter-templates'),
                filteringPlaceholder: bundle.getMessage('filter-templates')
              }}
              countText={bundle.formatMessage('filter-matches', { count: filteredItemsCount === undefined ? 0 : filteredItemsCount })}
              expandToViewport={true}
            />
          }
          header={
            <>
              <Header>
                {bundle.getMessage('whole-site-templates')}
              </Header>
              <SpaceBetween direction='vertical' size='xs'>
                <SpaceBetween direction='horizontal' size='xs'>
                  <Button ariaLabel={bundle.getMessage('refresh-templates')} onClick={() => refreshTemplateRows()}>
                    <Icon name='refresh' alt={bundle.getMessage('refresh-templates')} />
                  </Button>
                  <Button
                    ariaLabel={bundle.getMessage('update-site-templates')}
                    disabled={loadingTemplates}
                    onClick={() => setShowUpdateTemplate(true)}
                    variant='primary'
                  >
                    {bundle.getMessage('update-site-templates')}
                  </Button>
                </SpaceBetween>
              </SpaceBetween>
            </>
          }
          items={items}
          loading={loadingDevices || loadingTemplates}
          loadingText={bundle.getMessage('loading-templates')}
          pagination={
            <Pagination
              {...paginationProps}
              ariaLabels={PaginationLabels}
            />
          }
          preferences={
            <CollectionPreferences
              onConfirm={({ detail }) => updatePreferencesHandler(detail)}
              title={bundle.getMessage('preferences')}
              confirmLabel={bundle.getMessage('confirm')}
              cancelLabel={bundle.getMessage('cancel')}
              preferences={{
                pageSize: pageSize
              }}
              pageSizePreference={{
                title: bundle.getMessage('select-page-size'),
                options: [
                  { value: 25, label: bundle.formatMessage('number-of-rows', { numberOfRows: 25 }) },
                  { value: 50, label: bundle.formatMessage('number-of-rows', { numberOfRows: 50 }) },
                  { value: 100, label: bundle.formatMessage('number-of-rows', { numberOfRows: 100 }) },
                  { value: 150, label: bundle.formatMessage('number-of-rows', { numberOfRows: 150 }) },
                  { value: 250, label: bundle.formatMessage('number-of-rows', { numberOfRows: 250 }) },
                  { value: 500, label: bundle.formatMessage('number-of-rows', { numberOfRows: 500 }) }
                ],
              }}
            />
          }
          resizableColumns={true}
          stickyHeader={true}
        />
        {showUpdateTemplate &&
          <UpdateTemplate
            refreshTemplatesCallback={refreshTemplateRows}
            showModalCallback={setShowUpdateTemplate}
            templateRows={templateRows}
            visible={showUpdateTemplate}
          />
        }
      </SpaceBetween>
    </div>
  );
}