import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectEmployeeId,
  selectPhySecMember,
  selectSideTeam,
  selectSigAdmin,
  selectUsername,
  setEmployeeId,
  setPhySecMember,
  setPhySecRegions,
  setSideTeam,
  setSigAdmin,
  setUsername
} from '../stores/slices/userSlice';
import Box from "@amzn/awsui-components-react/polaris/box";
import Link from "@amzn/awsui-components-react/polaris/link";
import AuthorizeUser from './AuthorizeUser';
import { PhySecTeams, URLS, UserActionNames } from '../constants/Constants';
import { debug } from '../utils/commonUtils';
import { Auth } from 'aws-amplify';
import { Spinner } from '@amzn/awsui-components-react';
import { createUserAction } from 'src/utils/UserActionsUtils';
import jwtDecode from 'jwt-decode';

export default function AuthenticateUser() {
  debug(`AuthenticateUser()`);
  document.title = "SilencioV2";

  const dispatch = useDispatch();
  const employeeId = useSelector(selectEmployeeId);
  const phySecMember = useSelector(selectPhySecMember);
  const sideTeam = useSelector(selectSideTeam);
  const sigAdmin = useSelector(selectSigAdmin);
  const username = useSelector(selectUsername);

  const [authFailed, setAuthFailed] = useState<boolean>(false);
  const [authError, setAuthError] = useState<string>('');

  useEffect(() => {
    debug('AuthenticateUser() useEffect([])');
    setAuthFailed(false);
    Auth.currentAuthenticatedUser()
      .then((cognitoUserData) => {
        debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() cognitoUserData is ${JSON.stringify(cognitoUserData)}`);
        const username: string = cognitoUserData.username.split('_')[1];
        debug(`AuthenticateUser() useEffect([]) currentAuthenticatedUser() username is ${username}`);
        dispatch(setUsername(username));
        try {
          const jwtToken = cognitoUserData.signInUserSession.idToken.jwtToken;
          const decodedTokenValue: any = jwtDecode(jwtToken);
          debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() decodedTokenValue is ${JSON.stringify(decodedTokenValue)}`);
          const employeeId = decodedTokenValue['custom:employeeId'];
          debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() employeeId is ${employeeId}`);
          const phySecTeamsToken = decodedTokenValue['custom:physec-teams'];
          debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() phySecTeamsToken is ${phySecTeamsToken}`);
          const phySecRegions: string[] = [];
          if (phySecTeamsToken) {
            if (phySecTeamsToken.includes(PhySecTeams.AMER)) {
              phySecRegions.push("AMER");
            }
            if (phySecTeamsToken.includes(PhySecTeams.APAC)) {
              phySecRegions.push("APAC");
            }
            if (phySecTeamsToken.includes(PhySecTeams.EMEA)) {
              phySecRegions.push("EMEA");
            }
          }
          debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() phySecRegions is ${phySecRegions}`);
          const sideTeamToken = decodedTokenValue['custom:side-team'];
          debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() sideTeamToken is ${sideTeamToken}`);
          const sigAdminToken = decodedTokenValue['custom:sig-admin'];
          debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() sigAdminToken is ${sigAdminToken}`);
          dispatch(setPhySecRegions(phySecRegions));
          dispatch(setPhySecMember(phySecRegions.length > 0));
          dispatch(setSideTeam(sideTeamToken === '["side-team"]'));
          dispatch(setSigAdmin(sigAdminToken === '["sig-admin"]'));
          dispatch(setEmployeeId(employeeId));
        } catch (error) {
          debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() error is ${error} JSON.stringify: ${JSON.stringify(error)}`);
        }
      })
      .catch((error) => {
        debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() error is ${error}`);
        setAuthError(error);
        Auth.federatedSignIn({ customProvider: 'AmazonFederate' })
          .catch(error => {
            debug(`AuthenticateUser() useEffect([]) federatedSignIn() error is ${error}`);
            setAuthFailed(true);
            setAuthError(error);
          });
      });
  }, []);

  let componentToRender;

  if (username && employeeId && sideTeam !== undefined && sigAdmin !== undefined && phySecMember !== undefined) {
    debug(`AuthenticateUser() authentication complete, username is ${username} employeeId is ${employeeId} sideTeam is ${sideTeam} sigAdmin is ${sigAdmin} phySecMember is ${phySecMember}`);
    createUserAction({ actionName: UserActionNames.AuthenticateUser, username: username });
    componentToRender = (
      <div>
        <AuthorizeUser username={username} employeeId={employeeId} />
      </div>
    );
  } else if (authFailed) {
    debug(`AuthenticateUser() authFailed`);
    createUserAction(
      {
        actionName: UserActionNames.AuthenticateUserError,
        username: 'null',
        parameters: JSON.stringify(
          {
            authError: authError
          })
      });
    componentToRender = (
      <Box
        margin={{ top: "s", left: "s" }}
        color="inherit"
        display="block"
        fontSize="heading-l"
      >
        Failed to authenticate user, please try again or contact
        <Link external href={URLS.Contact}> SIDE Support</Link>
      </Box>
    );
  } else {
    debug(`AuthenticateUser() authenticating`);
    componentToRender = (
      <Box
        margin={{ top: "s", left: "s" }}
        color="inherit"
        display="block"
        fontSize="heading-l"
      >
        Authenticating User...<Spinner />
      </Box>
    );
  }

  return componentToRender;
}
