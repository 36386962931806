import { debug, getPropertyFilterQuery, i18nFilterStrings } from '../utils/commonUtils';
import { DeviceTypes, EmptyPropertyFilterQuery, SilencioDeviceFilteringProperties } from 'src/constants/Constants';
import { PropertyFilter, Select, SelectProps, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import React, { useEffect } from 'react';
import { selectUserPrefs as selectUserPrefsInStore, setSavedSearches as setSavedSearchesInStore } from '../stores/slices/userSlice';
import { setInputsSearch, selectInputsSearch, Search } from 'src/stores/slices/devicesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { defaultMaskingGroup } from './maskingGroups/utils';
import DeleteSearch from './DeleteSearch';
import MaskingGroups from './maskingGroups';
import SaveSearch from './SaveSearch';
import { useBundle } from '@amzn/react-arb-tools';

interface InputsFilterInterface {
  filteredItemsCount: any;
  refreshDevicesCallback: Function;
  resetSelectedItemsCallback: Function;
  setFilterCallback: Function;
}

export default function InputsFilter(props: InputsFilterInterface) {
  debug(`InputsFilter() props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.Common');

  const dispatch = useDispatch();

  const selectUserPrefs = useSelector(selectUserPrefsInStore);
  const [userPrefs, setUserPrefs] = React.useState(selectUserPrefs);

  const inputsSearch = useSelector(selectInputsSearch) as Search;
  debug(`InputsFilter() inputsSearch is ${JSON.stringify(inputsSearch)}`);

  const [query, setQuery] = React.useState<any>(inputsSearch?.query || EmptyPropertyFilterQuery);

  const [filterInputsStatus, setFilterInputsStatus] = React.useState<any>(
    inputsSearch?.maskStatus ||
    {
      label: 'Any Status',
      value: 'Any Status'
    });

  const [selectedInputsSavedSearch, setSelectedInputsSavedSearch] = React.useState<{ label: string, value: string }>(
    inputsSearch?.savedSearch ||
    {
      label: 'Saved Searches',
      value: 'Saved Searches'
    });

  const [showSaveSearch, setShowSaveSearch] = React.useState(false);
  const [showDeleteSearch, setShowDeleteSearch] = React.useState(false);

  const [savedSearchesOptions, setSavedSearchesOptions] = React.useState<SelectProps.Options>();

  const [selectedMaskingGroup, setSelectedMaskingGroup] = React.useState<SelectProps.Option>(
    inputsSearch?.maskingGroup || defaultMaskingGroup);

  const resetInputsFilters = () => {
    debug(`InputsFilter() resetInputsFilters()`);
    setQuery(EmptyPropertyFilterQuery);
    setFilterInputsStatus({ label: 'Any Status', value: 'Any Status' });
    setSelectedInputsSavedSearch({ label: 'Saved Searches', value: 'Saved Searches' });
    props.setFilterCallback(EmptyPropertyFilterQuery);
    props.resetSelectedItemsCallback();
    setSelectedMaskingGroup(defaultMaskingGroup);
    dispatch(setInputsSearch(
      {
        maskingGroup: undefined,
        maskStatus: { label: 'Any Status', value: 'Any Status' },
        modes: [],
        outputStatus: { label: 'Any Status', value: 'Any Status' },
        savedSearch: undefined,
        query: EmptyPropertyFilterQuery
      }
    ));
  };

  const setFilterInputsStatusOnChangeHandler = (detail: SelectProps.ChangeDetail) => {
    debug(`InputsFilter() setFilterInputsStatusOnChangeHandler()`);
    setFilterInputsStatus(detail.selectedOption);
    props.resetSelectedItemsCallback();
  };

  const savedSearch = (searchName: string) => {
    debug(`InputsFilter() savedSearch() searchName is ${searchName}`);
    let savedSearches = userPrefs.inputs?.savedSearches || [];
    debug(`InputsFilter() savedSearch() savedSearches is ${JSON.stringify(savedSearches)}`);
    const newSavedOption = { label: searchName, value: searchName };
    debug(`InputsFilter() savedSearch() newSavedOption is ${JSON.stringify(newSavedOption)}`);
    setSelectedInputsSavedSearch(newSavedOption);
    dispatch(setSavedSearchesInStore(
      {
        devicesType: 'inputs',
        savedSearches: [
          ...savedSearches,
          {
            name: searchName,
            filterText: JSON.stringify(query),
            maskStatus: filterInputsStatus.value,
            modes: []
          }
        ]
      }));
  };

  const deletedSearch = (searchName: string) => {
    debug(`deletedSearch() searchName is ${searchName}`);
    let savedSearches = userPrefs.inputs?.savedSearches || [];
    debug(`deletedSearch() savedSearches is ${JSON.stringify(savedSearches)}`);
    let filteredSearches = savedSearches.filter((ss: { name: string; filterText: string; maskStatus: string; }) => ss.name !== searchName);
    debug(`deletedSearch() filteredSearches is ${JSON.stringify(filteredSearches)}`);
    resetInputsFilters();
    dispatch(setSavedSearchesInStore(
      {
        devicesType: 'inputs',
        savedSearches: filteredSearches
      }));
  };

  const savedSearchesOnChange = (detail: SelectProps.ChangeDetail) => {
    const value = detail.selectedOption.value;
    const label = detail.selectedOption.label;
    const chosenSavedSearch = { label: label!, value: value! };
    debug(`InputsFilter() savedSearchesOnChange() chosenSavedSearch is ${chosenSavedSearch}`);
    const actions = ['Reset', 'Save Search', 'Delete Search'];

    if (value === 'Reset') resetInputsFilters();
    if (value === 'Save Search') setShowSaveSearch(true);
    if (value === 'Delete Search') setShowDeleteSearch(true);
    if (!actions.includes(value!)) {
      setSelectedInputsSavedSearch(chosenSavedSearch);
      const savedSearch = userPrefs.inputs.savedSearches.find(
        (ss: { name: string, filterText: string, maskStatus: string }) => {
          return ss.name === label;
        }
      );
      debug(`InputsFilter() savedSearchesOnChange() savedSearch is ${JSON.stringify(savedSearch)}`);
      const savedQuery = getPropertyFilterQuery(savedSearch.filterText);
      props.resetSelectedItemsCallback();
      dispatch(setInputsSearch(
        {
          maskingGroup: undefined,
          maskStatus: savedSearch.maskStatus,
          modes: savedSearch.modes,
          outputStatus: savedSearch.outputStatus,
          savedSearch: chosenSavedSearch,
          query: savedQuery,
        }
      ));
      setFilterInputsStatus({ label: savedSearch.maskStatus, value: savedSearch.maskStatus });
      setQuery(savedQuery)
      props.setFilterCallback(savedQuery);
      setSelectedMaskingGroup(defaultMaskingGroup);
    }
  };

  useEffect(() => {
    debug(`InputsFilter() useEffect() [selectUserPrefs]`)
    const updatedUserPrefs = selectUserPrefs;
    debug(`InputsFilter() useEffect() updatedUserPrefs is ${JSON.stringify(updatedUserPrefs)}`);

    let options = [
      {
        label: 'Actions',
        options: [
          { label: 'Reset', value: 'Reset', iconName: 'undo' },
          { label: 'Save Search', value: 'Save Search', iconName: 'file' },
          { label: 'Delete Search', value: 'Delete Search', iconName: 'close' }
        ]
      },
      {
        label: 'Saved Searches',
        options: updatedUserPrefs.inputs.savedSearches.map(
          (ss: { name: string, filterText: string, maskStatus: string, modes: [] }) => {
            return { label: ss.name, value: ss.name }
          }
        ).sort()
      }
    ];
    debug(`InputsFilter() useEffect() options is ${JSON.stringify(options)}`);
    setSavedSearchesOptions(options);
    setUserPrefs(updatedUserPrefs);
  }, [selectUserPrefs])

  useEffect(() => {
    debug(`InputsFilter() useEffect() [query] query is ${query}}`);
    dispatch(setInputsSearch(
      {
        ...inputsSearch,
        query: query
      }
    ));
    props.setFilterCallback(query);
  }, [query]);

  useEffect(() => {
    debug(`InputsFilter() useEffect() [filterInputsStatus] filterInputsStatus is ${JSON.stringify(filterInputsStatus)}`);
    dispatch(setInputsSearch(
      {
        ...inputsSearch,
        maskStatus: filterInputsStatus
      }
    ));
  }, [filterInputsStatus]);

  useEffect(() => {
    setSelectedMaskingGroup(inputsSearch?.maskingGroup || defaultMaskingGroup);
  }, [inputsSearch?.maskingGroup]);

  if (isBundleLoading) return <Spinner />;

  return (
    <>
      <SpaceBetween size='xs' direction='horizontal'>
        <PropertyFilter
          countText={bundle.formatMessage('filter-matches', { count: props.filteredItemsCount === undefined ? 0 : props.filteredItemsCount})}
          expandToViewport
          filteringOptions={
            DeviceTypes.Inputs.map((type: string) => { return { propertyKey: "Device_Type", value: type } })
          }
          filteringProperties={SilencioDeviceFilteringProperties}
          i18nStrings={i18nFilterStrings(bundle)}
          onChange={({ detail }) => setQuery(detail)}
          query={query}
        />
        <Select
          onChange={({ detail }) => setFilterInputsStatusOnChangeHandler(detail)}
          options={[
            { label: 'Any Status', value: 'Any Status' },
            { label: 'Masked', value: 'Masked' },
            { label: 'Unmasked', value: 'Unmasked' },
            { label: '-------------', value: 'Separator', disabled: true },
            { label: 'Unknown', value: 'Unknown' },
          ]}
          selectedAriaLabel={bundle.getMessage('selected')}
          selectedOption={filterInputsStatus}
          expandToViewport
        />
        <Select
          onChange={({ detail }) => { savedSearchesOnChange(detail) }}
          options={savedSearchesOptions}
          selectedAriaLabel={bundle.getMessage('selected-saved-search')}
          selectedOption={selectedInputsSavedSearch}
          expandToViewport
        />
        <MaskingGroups
          deviceType={'inputs'}
          resetSearch={resetInputsFilters}
          selectedMaskingGroup={selectedMaskingGroup}
          setSearchInStore={setInputsSearch}
          setSelectedMaskingGroup={setSelectedMaskingGroup}
          resetSelectedItemsCallback={props.resetSelectedItemsCallback}
          refreshDevicesCallback={props.refreshDevicesCallback}
        />
      </SpaceBetween>
      {showSaveSearch &&
        <SaveSearch
          showSaveSearch={showSaveSearch}
          setShowSaveSearchCallback={setShowSaveSearch}
          userPrefs={userPrefs}
          searchName={selectedInputsSavedSearch.label}
          filteringText={JSON.stringify(query)}
          filterInputsStatus={filterInputsStatus.value}
          filterReadersModes={[]}
          savedSearchCallback={savedSearch}
          devicesType='inputs'
        />
      }
      {showDeleteSearch &&
        <DeleteSearch
          showDeleteSearch={showDeleteSearch}
          setShowDeleteSearchCallback={setShowDeleteSearch}
          userPrefs={userPrefs}
          searchName={selectedInputsSavedSearch.label}
          deletedSearchCallback={deletedSearch}
          devicesType='inputs'
        />
      }
    </>);
}