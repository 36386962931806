import * as APIt from '../../API';
import {
    addToken,
    clearTokens,
    deleteSchedule as deleteScheduleReducer,
    selectLoadingSchedules,
    selectSchedules,
    selectTokenIndex,
    selectTokens,
    setLoadingSchedules,
    setSchedules,
    setTokenIndex,
    updateSchedule as updateScheduleReducer,
} from '../../stores/slices/schedulesSlice';
import {
    Alert,
    Button,
    CollectionPreferences,
    Flashbar,
    Header,
    Icon,
    Link,
    Pagination,
    PropertyFilter,
    SpaceBetween,
    Spinner,
    Table,
    TableProps,
    Toggle,
    ToggleProps,
} from '@amzn/awsui-components-react';
import { ColumnDefinitions, ScheduleFilteringOptions, ScheduleFilteringProperties } from './schedule-table-config';
import { debug, i18nFilterStrings } from '../../utils/commonUtils';
import { deleteSchedule, listSchedules, updateSchedule } from '../../utils/SchedulesUtils';
import { extractScheduleRow, getProgrammingSummaryLink, isScheduleExpressionInPast } from './utils';
import { PaginationLabels, TableEmptyState, TableNoMatchState } from '../table-config';
import React, { useEffect } from 'react';
import { Schedule, ScheduleRow } from '../../utils/SilencioTypes';
import { ScheduleState, SiteScheduleLimit } from "src/constants/Constants";
import {
    selectInputDevices,
    selectLoadingDevices,
    selectReadersAlarmsDevices,
    selectReadersModesDevices
} from 'src/stores/slices/devicesSlice';
import {
    selectUsername,
    selectUserPrefs,
    selectUserSelectedSite,
    setUserPrefs
} from '../../stores/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import CreateSchedule from './CreateSchedule';
import { ListSchedulesInput } from 'src/API';
import { SilencioDevice } from "src/utils/SilencioTypes";
import { updateUserPrefs } from 'src/utils/UserPrefsUtils';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';
import TranslateText from '../TranslateText';

interface ISchedulesProps {
    refreshDevicesCallback: Function;
}

export default function Schedules(props: ISchedulesProps) {
    debug(`Schedules(): props is ${JSON.stringify(props)}`);

    const [bundle, isBundleLoading] = useBundle('components.schedules.Schedules');

    const dispatch = useDispatch();

    const inputDevices = useSelector(selectInputDevices) as SilencioDevice[];
    const loadingDevices = useSelector(selectLoadingDevices);
    const loadingSchedules = useSelector(selectLoadingSchedules);
    const readersAlarmsDevices = useSelector(selectReadersAlarmsDevices) as SilencioDevice[];
    const readersModesDevices = useSelector(selectReadersModesDevices) as SilencioDevice[];
    const schedules = useSelector(selectSchedules) as ScheduleRow[];
    const tokenIndex = useSelector(selectTokenIndex);
    const tokens = useSelector(selectTokens);
    const userPrefs = useSelector(selectUserPrefs);
    const username = useSelector(selectUsername) as string;
    const userSelectedSite = useSelector(selectUserSelectedSite) as APIt.UserPrefSite | undefined;

    const initialPageSize = (): number => {
        const defaultNumRecs = 50;
        let numRecs;
        try {
            numRecs = userPrefs.schedules.numRecordsPerPage;
            debug(`pageSizeFromStore() numRecs is ${numRecs ?? defaultNumRecs}`);
            return numRecs ?? defaultNumRecs;
        } catch (error) {
            return defaultNumRecs;
        }
    }

    const initialVisibleColumns = (): string[] => {
        const defaultColumns = ['Action', 'EntityName', 'PairedReader', 'FormattedScheduleExpression', 'State', 'Delete'];
        let columns;
        try {
            columns = userPrefs.schedules.visibleColumns;
            debug(`pageSizeFromStore() visible columns are ${columns ?? defaultColumns}`);
            return columns ?? defaultColumns;
        } catch (error) {
            return defaultColumns;
        }
    }

    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [flashBarMessages, setFlashBarMessages] = React.useState<any>([{
        type: "warning",
        content: (
            <>
                Caution: Only <b>user created</b> schedules are listed on this page.
                Programmed Schedules are not listed and can be found in the sites{" "}
                <Link color="inverted" external href={getProgrammingSummaryLink()}>Programming Summary</Link>
                .
            </>
        ),
        dismissible: true,
        dismissLabel: "Dismiss message",
        onDismiss: () => setFlashBarMessages([]),
        id: "programmingMessage"
    }]);
    const [pageSize, setPageSize] = React.useState<number>(initialPageSize());
    const [showCreateSchedule, setShowCreateSchedule] = React.useState(false);
    const [visibleColumns, setVisibleColumns] = React.useState<string[]>(initialVisibleColumns())

    const deleteScheduleHandler = async (item: ScheduleRow) => {
        const scheduleName = item.Name;
        setErrorMessage(null);
        dispatch(setLoadingSchedules(true));
        try {
            debug(`Schedules() deleteScheduleHandler() Deleting ${scheduleName}`);
            const deleteInput: APIt.DeleteScheduleInput = {
                scheduleName: scheduleName
            }

            const result = await deleteSchedule(deleteInput, username);
            debug(`Schedules() deleteScheduleHandler() result: ${result}`);

            dispatch(deleteScheduleReducer({ scheduleName: scheduleName }));
        } catch (err) {
            debug(`Schedules() deleteScheduleHandler() Error: ${err}`);
            setErrorMessage(`Error deleting schedule: ${scheduleName}`);
        }
        dispatch(setLoadingSchedules(false));
    }

    const fetchSchedules = async (previousPage: boolean, nextPage: boolean) => {
        setErrorMessage(null);
        if (previousPage && nextPage) {
            setErrorMessage('Error while loading schedules. Can not page forward and back at the same time.');
            return;
        }

        let index = tokenIndex;
        if (previousPage && tokenIndex > 0) {
            index = tokenIndex - 1;
        }
        if (nextPage && tokenIndex < tokens.length - 1) {
            index = tokenIndex + 1;
        }

        dispatch(setSchedules({ schedules: [] }));
        dispatch(setLoadingSchedules(true));
        try {
            const siteCode = userSelectedSite?.sitename;
            const siteRegion = userSelectedSite?.siteRegion;
            debug(`Schedules() fetchSchedules() siteCode is ${siteCode} siteRegion is ${siteRegion}`);
            if (!siteCode || !siteRegion) {
                throw new Error('Schedules() fetchSchedules() siteCode or siteRegion is undefined');
            }

            const listSchedulesInput: ListSchedulesInput = {
                NamePrefix: `${siteCode}-`,
                NextToken: tokens[index],
                MaxResults: 100,
                State: undefined,
            }
            debug(`Schedules() fetchSchedules() input is ${JSON.stringify(listSchedulesInput)}`);

            const response: APIt.ListSchedulesOutput = await listSchedules(listSchedulesInput, username);
            debug(`Schedules() fetchSchedules() response is ${JSON.stringify(response)}`);

            dispatch(setTokenIndex({ index }));
            // Only store new token if reaching a new page and it is not the last page.
            if (index == tokens.length - 1 && response.nextToken) {
                dispatch(addToken({ token: response.nextToken }));
            }
            const foundSchedules: ScheduleRow[] = [];
            if (response.schedules) {
                for (let schedule of response.schedules) {
                    if (!schedule) {
                        debug('Schedules() fetchSchedules() null schedule in response');
                    } else {
                        debug(`Schedules() fetchSchedules() parsing schedule ${schedule}`);
                        const parsed = JSON.parse(schedule) as Schedule;
                        const scheduleRow = await extractScheduleRow(inputDevices, readersAlarmsDevices, readersModesDevices, parsed, siteRegion);
                        if (scheduleRow.EntityName?.startsWith('Device not found')) {
                            setErrorMessage(`${scheduleRow.EntityName}. Please refresh schedules and then check if the device is valid.`);
                        }
                        foundSchedules.push(scheduleRow);
                    }
                };
            }
            debug(`Schedules() fetchSchedules() schedules is ${JSON.stringify(foundSchedules)}`)
            dispatch(setSchedules({ schedules: foundSchedules }));
        } catch (error) {
            console.error(`Schedules() fetchSchedules() error is ${JSON.stringify(error)}`);
            setErrorMessage('Error while loading schedules');
        }
        dispatch(setLoadingSchedules(false));
    };

    const getColumnDefinitions = (
        bundle: { getMessage: (id: string) => string; formatMessage: (id: string, ...args: any) => string; }
    ): TableProps.ColumnDefinition<ScheduleRow>[] => {
        return [
            ...ColumnDefinitions,
            {
                id: 'State',
                header: <TranslateText translateKey={'state'} />,
                cell: (item: ScheduleRow) =>
                    <Toggle
                        ariaLabel={bundle.getMessage('enable-or-disable-label')}
                        checked={item.State === ScheduleState.Enabled}
                        disabled={isScheduleExpressionInPast(item.ScheduleExpression, item.ScheduleExpressionTimezone)}
                        onChange={({ detail }) => updateScheduleHandler(item, detail)}
                    >
                        {bundle.formatMessage('enabled-or-disabled', { enabled: (item.State === ScheduleState.Enabled) })}
                    </Toggle>
            },
            {
                id: 'Delete',
                header: <TranslateText translateKey={'delete'} />,
                cell: (item: ScheduleRow) =>
                    <Button
                        onClick={() => deleteScheduleHandler(item)}
                        ariaLabel='Delete schedule'
                    >
                        {bundle.getMessage('delete')}
                    </Button>
            }
        ];
    }

    const updatePreferencesHandler = async (details: any) => {
        setErrorMessage(null);
        debug(`Schedules() updatePreferencesHandler() details: ${JSON.stringify(details)}`);
        dispatch(setLoadingSchedules(true));
        try {
            setPageSize(details.pageSize);
            setVisibleColumns(details.visibleContent);
            const newUserPrefs = await updateUserPrefs(
                {
                    ...userPrefs,
                    ['schedules']: {
                        __typename: 'SchedulesPrefs',
                        numRecordsPerPage: details.pageSize,
                        visibleColumns: details.visibleContent,
                    }
                });
            if (newUserPrefs) dispatch(setUserPrefs(newUserPrefs));
        } catch (err) {
            console.error(`Schedules() updatePreferencesHandler() Error: ${err}`);
            setErrorMessage(`Error updating user preferences`);
        }
        dispatch(setLoadingSchedules(false));
    }

    const updateScheduleHandler = async (item: ScheduleRow, detail: ToggleProps.ChangeDetail) => {
        setErrorMessage(null);
        debug(`Schedules() updateScheduleHandler() value is ${detail.checked}`);
        const scheduleName = item.Name;
        dispatch(setLoadingSchedules(true));
        try {
            debug(`Schedules() updateScheduleHandler() updating ${scheduleName}`);
            const input: APIt.UpdateScheduleInput = {
                scheduleName: scheduleName,
                enabled: detail.checked
            };
            const result = await updateSchedule(input, username);
            debug(`Schedules() updateScheduleHandler() result: ${result}`);

            dispatch(updateScheduleReducer({ scheduleName: scheduleName, enabled: detail.checked }));
        } catch (err) {
            console.error(`Schedules() updateScheduleHandler() Error: ${err}`);
            setErrorMessage(`Error updating schedule: ${scheduleName}`);
        }
        dispatch(setLoadingSchedules(false));
    }

    const { items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps } = useCollection(
        schedules,
        {
            propertyFiltering: {
                filteringProperties: ScheduleFilteringProperties,
                empty: <TableEmptyState title='No Schedules' />,
                noMatch: (
                    <TableNoMatchState
                        onClearFilter={() => {
                            actions.setPropertyFiltering({ tokens: [], operation: 'and' });
                        }}
                    />
                ),
            },
            pagination: { pageSize: pageSize },
            sorting: { defaultState: { sortingColumn: ColumnDefinitions[0] } },
            selection: {},
        }
    );

    useEffect(() => {
        debug(`Schedules() useEffect() Site set. Clearing schedules and loading. Will fetch schedules after devices load.`);
        dispatch(setLoadingSchedules(true));
        dispatch(setSchedules({ schedules: [] }));
    }, [userSelectedSite]);

    useEffect(() => {
        debug(`Schedules() useEffect() loading devices: ${loadingDevices}.`);
        if (!loadingDevices) {
            (async () => {
                dispatch(clearTokens());
                dispatch(setTokenIndex({ index: 0 }));
                await fetchSchedules(false, false);
            })();
        }
    }, [loadingDevices]);

    useEffect(() => {
        debug(`Schedules() useEffect() schedules from store: ${schedules.length}`);
    }, [schedules]);

    if (isBundleLoading) return <Spinner />;

    return (
        <div>
            <SpaceBetween direction='vertical' size='m'>
                {errorMessage &&
                    <Alert
                        dismissible={true}
                        type='error'
                        visible={errorMessage != null}
                        onDismiss={() => setErrorMessage(null)}
                    >
                        {errorMessage}
                    </Alert>
                }
                <Table
                    {...collectionProps}
                    ariaLabels={{
                        tableLabel: bundle.getMessage('schedule-table')
                    }}
                    columnDefinitions={getColumnDefinitions(bundle)}
                    empty={<TableEmptyState title={bundle.getMessage('no-schedules')} />}
                    filter={
                        <PropertyFilter
                            {...propertyFilterProps}
                            filteringOptions={ScheduleFilteringOptions}
                            i18nStrings={{
                                ...i18nFilterStrings,
                                filteringAriaLabel: bundle.getMessage('filter-schedules'),
                                filteringPlaceholder: bundle.getMessage('filter-schedules')
                            }}
                            countText={bundle.formatMessage('filter-matches', { count: filteredItemsCount === undefined ? 0 : filteredItemsCount })}
                            expandToViewport={true}
                        />
                    }
                    header={
                        <>
                            <Header
                                counter={loadingSchedules ? `(${bundle.getMessage('loading-schedules')})` : `(${schedules.length})`}
                            >
                                {bundle.getMessage('schedules')}
                            </Header>
                            <SpaceBetween direction='vertical' size='xs'>
                                <Flashbar
                                    items={flashBarMessages}
                                />
                                <SpaceBetween direction='horizontal' size='xs'>
                                    <Button onClick={() => fetchSchedules(false, false)} ariaLabel={bundle.getMessage('refresh-schedules')}>
                                        <Icon name='refresh' alt={bundle.getMessage('refresh-schedules')} />
                                    </Button>
                                    <Button
                                        ariaLabel={bundle.getMessage('create-schedule')}
                                        disabled={loadingSchedules || schedules.length >= SiteScheduleLimit}
                                        onClick={() => setShowCreateSchedule(true)}
                                        variant='primary'
                                    >
                                        {bundle.getMessage('add')}
                                    </Button>
                                    {schedules.length >= SiteScheduleLimit &&
                                        <>
                                            <Icon name="status-info" />
                                            {bundle.getMessage('max-schedules-reached')}
                                        </>
                                    }
                                </SpaceBetween>
                            </SpaceBetween>
                        </>
                    }
                    items={items}
                    loading={loadingDevices || loadingSchedules}
                    loadingText={bundle.getMessage('loading-schedules')}
                    pagination={
                        <Pagination
                            {...paginationProps}
                            ariaLabels={PaginationLabels}
                        />
                    }
                    preferences={
                        <CollectionPreferences
                            onConfirm={({ detail }) => updatePreferencesHandler(detail)}
                            title={bundle.getMessage('preferences')}
                            confirmLabel={bundle.getMessage('confirm')}
                            cancelLabel={bundle.getMessage('cancel')}
                            preferences={{
                                pageSize: pageSize,
                                visibleContent: visibleColumns,
                            }}
                            pageSizePreference={{
                                title: bundle.getMessage('select-page-size'),
                                options: [
                                    { value: 10, label: bundle.formatMessage('number-of-schedules', { numberOfSchedules: 10 }) },
                                    { value: 20, label: bundle.formatMessage('number-of-schedules', { numberOfSchedules: 20 }) },
                                    { value: 30, label: bundle.formatMessage('number-of-schedules', { numberOfSchedules: 30 }) },
                                    { value: 40, label: bundle.formatMessage('number-of-schedules', { numberOfSchedules: 40 }) },
                                    { value: 50, label: bundle.formatMessage('number-of-schedules', { numberOfSchedules: 50 }) },
                                ],
                            }}
                            visibleContentPreference={{
                                title: bundle.getMessage('select-visible-columns'),
                                options: [{
                                    label: bundle.getMessage('schedule-columns'),
                                    options: getColumnDefinitions(bundle).map((columnDefinition: TableProps.ColumnDefinition<ScheduleRow>) => {
                                        return {
                                            id: columnDefinition.id!,
                                            label: columnDefinition.id!
                                        };
                                    })
                                }]
                            }}
                        />
                    }
                    resizableColumns={true}
                    stickyHeader={true}
                    trackBy='ScheduleName'
                    visibleColumns={visibleColumns}
                />
                {showCreateSchedule &&
                    <CreateSchedule
                        refreshDevicesCallback={props.refreshDevicesCallback}
                        showModalCallback={setShowCreateSchedule}
                        visible={showCreateSchedule}
                    />
                }
            </SpaceBetween>
        </div>
    );
}