import React, { useEffect } from 'react';
import { Modes, UserActionNames } from '../../constants/Constants';
import { Select, SelectProps, Spinner } from '@amzn/awsui-components-react';
import {
  selectInputsMaskingGroups,
  selectReadersAlarmsMaskingGroups,
  selectReadersModesMaskingGroups,
} from '../../stores/slices/devicesSlice';
import { selectUserSelectedSitePrivs, selectUsername } from 'src/stores/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import CreateMaskingGroup from './CreateMaskingGroup';
import DeleteMaskingGroup from './DeleteMaskingGroup';
import EditMaskingGroup from './EditMaskingGroup';
import { MaskingGroupInfo } from 'src/utils/SilencioTypes';
import { Privileges } from 'src/utils/UserSitesUtils';
import { createUserAction } from '../../utils/UserActionsUtils';
import { debug } from '../../utils/commonUtils';
import { defaultMaskingGroup } from './utils';
import { useBundle } from '@amzn/react-arb-tools';

export interface IMaskingGroupsProps {
  deviceType: string;
  refreshDevicesCallback: Function;
  resetSearch: Function;
  resetSelectedItemsCallback: Function;
  selectedMaskingGroup: SelectProps.Option;
  setSearchInStore: Function;
  setSelectedMaskingGroup: Function;
}

export default function MaskingGroups(props: IMaskingGroupsProps) {
  debug(`MaskingGroups() props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.maskingGroups.MaskingGroups');

  const dispatch = useDispatch();

  const getMaskingGroupsSelector = () => {
    switch (props.deviceType) {
      case 'inputs':
        return useSelector(selectInputsMaskingGroups) as MaskingGroupInfo[];
      case 'readersAlarms':
        return useSelector(selectReadersAlarmsMaskingGroups) as MaskingGroupInfo[];
      case 'readersModes':
        return useSelector(selectReadersModesMaskingGroups) as MaskingGroupInfo[];
    }
  };
  const maskingGroupsFromStore = getMaskingGroupsSelector();
  const username = useSelector(selectUsername);
  const userSitePrivs = useSelector(selectUserSelectedSitePrivs) as Privileges[] | undefined;

  const [showCreateMaskingGroup, setShowCreateMaskingGroup] = React.useState(false);
  const [showEditMaskingGroup, setShowEditMaskingGroup] = React.useState(false);
  const [showDeleteMaskingGroup, setShowDeleteMaskingGroup] = React.useState(false);
  const [maskingGroupOptions, setMaskingGroupOptions] = React.useState<SelectProps.Options>();
  const [selectedMaskingGroup, setSelectedMaskingGroup] = React.useState<SelectProps.Option>(props.selectedMaskingGroup);

  useEffect(() => {
    setSelectedMaskingGroup(props.selectedMaskingGroup);
  }, [props.selectedMaskingGroup])

  useEffect(() => {
    debug(`MaskingGroups() useEffect() [maskingGroupsFromStore]`)
    const disabled = (!userSitePrivs || !userSitePrivs.includes(Privileges.all));
    let options = [
      {
        label: 'Actions',
        options: [
          { label: 'Reset', value: 'Reset', iconName: 'undo' },
          { label: 'Create Masking Group', value: 'Create Masking Group', iconName: 'file', disabled: disabled },
          { label: 'Edit Masking Group', value: 'Edit Masking Group', iconName: 'edit', disabled: disabled },
          { label: 'Delete Masking Group', value: 'Delete Masking Group', iconName: 'close', disabled: disabled },
        ]
      },
      {
        label: 'Masking Groups',
        options: maskingGroupsFromStore?.map(maskingGroup => {
          return { label: maskingGroup.name, value: maskingGroup.id }
        })
      }
    ];
    debug(`MaskingGroups() useEffect() maskingGroups options is ${JSON.stringify(options)}`);
    setMaskingGroupOptions(options);
  }, [maskingGroupsFromStore])

  const resetMaskingGroups = () => {
    debug(`MaskingGroups() resetMaskingGroups()`);
    setSelectedMaskingGroup(defaultMaskingGroup);
    props.setSelectedMaskingGroup(defaultMaskingGroup);

    props.resetSearch();

    dispatch(props.setSearchInStore(
      {
        maskStatus: { label: 'Any Status', value: 'Any Status' },
        modes: props.deviceType == 'readersModes' ? Modes : [],
        savedSearch: undefined,
        textFilter: '',
        maskingGroup: undefined
      }
    ));
  };

  const maskingGroupsOnChange = (detail: SelectProps.ChangeDetail) => {
    const value = detail.selectedOption.value;
    const label = detail.selectedOption.label;
    const chosenMaskingGroup = { label: label!, value: value! };
    debug(`MaskingGroups() maskingGroupsOnChange() chosenMaskingGroup is ${chosenMaskingGroup.label}`);
    const actions = ['Masking Groups', 'Actions', 'Reset', 'Create Masking Group', 'Edit Masking Group', 'Delete Masking Group'];

    if (value === 'Reset') resetMaskingGroups();
    if (value === 'Create Masking Group') setShowCreateMaskingGroup(true);
    if (value === 'Edit Masking Group') setShowEditMaskingGroup(true);
    if (value === 'Delete Masking Group') setShowDeleteMaskingGroup(true);
    if (!actions.includes(value!)) {
      props.resetSearch();
      setSelectedMaskingGroup(chosenMaskingGroup);
      props.setSelectedMaskingGroup(chosenMaskingGroup);

      dispatch(props.setSearchInStore(
        {
          maskStatus: { label: 'Any Status', value: 'Any Status' },
          modes: props.deviceType == 'readersModes' ? Modes : [],
          savedSearch: undefined,
          textFilter: '',
          maskingGroup: chosenMaskingGroup
        }
      ));

      createUserAction({
        actionName: UserActionNames.SelectMaskingGroup,
        username: username,
        parameters: JSON.stringify({ maskingGroup: chosenMaskingGroup.value })
      });
    }
  };

  if (isBundleLoading) return <Spinner/>

  return (
    <>
      <Select
        onChange={({ detail }) => { maskingGroupsOnChange(detail) }}
        options={maskingGroupOptions}
        selectedAriaLabel={bundle.getMessage('selected-masking-group')}
        selectedOption={selectedMaskingGroup}
        filteringType="auto"
        expandToViewport
      />
      {showCreateMaskingGroup &&
        <CreateMaskingGroup
          deviceType={props.deviceType}
          maskingGroups={maskingGroupsFromStore}
          showModalCallback={setShowCreateMaskingGroup}
          visible={showCreateMaskingGroup}
        />
      }
      {(showEditMaskingGroup && maskingGroupsFromStore) &&
        <EditMaskingGroup
          deviceType={props.deviceType}
          maskingGroups={maskingGroupsFromStore}
          refreshDevicesCallback={props.refreshDevicesCallback}
          resetSelectedItemsCallback={props.resetSelectedItemsCallback}
          selectedMaskingGroup={selectedMaskingGroup}
          showModalCallback={setShowEditMaskingGroup}
          visible={showEditMaskingGroup}
        />
      }
      {(showDeleteMaskingGroup && maskingGroupsFromStore) &&
        <DeleteMaskingGroup
          deviceType={props.deviceType}
          maskingGroups={maskingGroupsFromStore}
          refreshDevicesCallback={props.refreshDevicesCallback}
          selectedMaskingGroup={selectedMaskingGroup}
          showModalCallback={setShowDeleteMaskingGroup}
          visible={showDeleteMaskingGroup}
        />
      }
    </>
  );
}