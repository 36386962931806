import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import * as APIt from '../API';
import { getUserPrefs as getUserPrefsQueryOp } from '../graphql/queries';
import { createUserPrefs as createUserPrefsMutationOp } from '../graphql/mutations';
import { debug } from './commonUtils';
import { createUserAction } from './UserActionsUtils';
import { UserActionNames } from 'src/constants/Constants';

export const fetchUserPrefs = async (username: string): Promise<APIt.UserPrefs | null | undefined> => {
  debug(`fetchUserPrefs(): username is ${JSON.stringify(username)}`);

  try {
    const userPrefsReponse = await API.graphql(graphqlOperation(getUserPrefsQueryOp,
      {
        username: username
      })) as GraphQLResult<APIt.GetUserPrefsQuery>;
    debug(`fetchUserPrefs(): userPrefsResponse is ${JSON.stringify(userPrefsReponse)}`);
    const userPrefs = userPrefsReponse.data?.getUserPrefs as APIt.UserPrefs;
    createUserAction(
      {
        actionName: UserActionNames.FetchUserPreferences,
        username: username,
        parameters: JSON.stringify(
          {
            userPrefs: JSON.stringify(userPrefs)
          })
      });
    return userPrefs;
  } catch (error) {
    console.error(`fetchUserPrefs(): error is ${error} JSON.stringify ${JSON.stringify(error)}`);
    createUserAction(
      {
        actionName: UserActionNames.FetchUserPreferencesError,
        username: username,
        parameters: JSON.stringify(
          {
            error: error
          })
      });
    throw error;
  }
}

export const createUserPrefs = async (userPrefs: APIt.UserPrefs): Promise<APIt.UserPrefs | undefined | null> => {
  debug(`createUserPrefs(): userPrefs is ${JSON.stringify(userPrefs)}`);

  try {
    const userPrefSites = userPrefs.sites?.map(s => { return { sitename: s?.sitename, siteRegion: s?.siteRegion } });
    debug(`createUserPrefs(): userPrefSites is ${JSON.stringify(userPrefSites)}`);
    let lastSite = undefined;
    if (userPrefs.lastSite) lastSite = { sitename: userPrefs.lastSite.sitename, siteRegion: userPrefs.lastSite.siteRegion };
    const createUserPrefsMutationReponse = await API.graphql(graphqlOperation(createUserPrefsMutationOp,
      {
        input: {
          username: userPrefs.username,
          inputs: {
            numRecordsPerPage: userPrefs.inputs?.numRecordsPerPage,
            emailAddresses: userPrefs.inputs?.emailAddresses,
            savedSearches: userPrefs.inputs?.savedSearches,
          },
          outputs: {
            numRecordsPerPage: userPrefs.outputs?.numRecordsPerPage,
            emailAddresses: userPrefs.outputs?.emailAddresses,
            savedSearches: userPrefs.outputs?.savedSearches,
          },
          lastSite: lastSite,
          readersAlarms: {
            numRecordsPerPage: userPrefs.readersAlarms?.numRecordsPerPage,
            emailAddresses: userPrefs.readersAlarms?.emailAddresses,
            savedSearches: userPrefs.readersAlarms?.savedSearches,
          },
          readersModes: {
            numRecordsPerPage: userPrefs.readersModes?.numRecordsPerPage,
            emailAddresses: userPrefs.readersModes?.emailAddresses,
            savedSearches: userPrefs.readersModes?.savedSearches,
          },
          schedules: {
            numRecordsPerPage: userPrefs.schedules?.numRecordsPerPage,
            emailAddresses: userPrefs.schedules?.emailAddresses,
            visibleColumns: userPrefs.schedules?.visibleColumns,
          },
          siteTemplates: {
            numRecordsPerPage: userPrefs.siteTemplates?.numRecordsPerPage,
            visibleColumns: userPrefs.siteTemplates?.visibleColumns,
          },
          site: userPrefs.site,
          sites: userPrefSites
        }
      })) as GraphQLResult<APIt.CreateUserPrefsMutation>;
    debug(`createUserPrefs(): createUserPrefsMutationReponse is ${JSON.stringify(createUserPrefsMutationReponse)}`);
    createUserAction(
      {
        actionName: UserActionNames.CreateUserPreferences,
        username: userPrefs.username || 'null',
        parameters: JSON.stringify(
          {
            userPrefs: JSON.stringify(userPrefs)
          })
      });
    return createUserPrefsMutationReponse?.data?.createUserPrefs as APIt.UserPrefs;
  } catch (error) {
    createUserAction(
      {
        actionName: UserActionNames.CreateUserPreferencesError,
        username: userPrefs.username || 'null',
        parameters: JSON.stringify(
          {
            error: error
          })
      });
    console.error(`createUserPrefs(): error is ${JSON.stringify(error)}`);
    throw error;
  }
}

export const updateUserPrefs = async (userPrefs: APIt.UserPrefs): Promise<APIt.UserPrefs | undefined | null> => {
  debug(`updateUserPrefs(): userPrefs is ${JSON.stringify(userPrefs)}`);
  createUserAction(
    {
      actionName: UserActionNames.UpdateUserPreferences,
      username: userPrefs.username || 'null',
      parameters: JSON.stringify(userPrefs)
    });
  return createUserPrefs(userPrefs);
}

/**
 * This function merges user preferences for email lists from the different Silencio tabs 
 * to be saved in a new userPrefs.site.emailAddresses field. If userPrefs.site.emailAddresses
 * is already present it will be returned.
 * 
 * @param userPrefs
 * @returns A string of email addresses joined by commas.
 */
export const getMergedEmailAddresses = (userPrefs: APIt.UserPrefs): string => {
  let siteEmailAddresses = '';
  try {
    if (userPrefs.site) {
      siteEmailAddresses = JSON.parse(userPrefs.site)['emailAddresses'];
    }
    if (!siteEmailAddresses) {
      debug('getMergedEmailAddresses() merging old email address prefs.');
      const foundAddresses: string[] = [
        ...userPrefs.inputs?.emailAddresses?.split(',') ?? [],
        ...userPrefs.outputs?.emailAddresses?.split(',') ?? [],
        ...userPrefs.readersAlarms?.emailAddresses?.split(',') ?? [],
        ...userPrefs.readersModes?.emailAddresses?.split(',') ?? []
      ];
      siteEmailAddresses = Array.from(new Set(foundAddresses)).filter(value => value.length > 0).join(',');
    }
  } catch (error) {
    console.error(error);
  }
  debug(`getMergedEmailAddresses() merged email addresses is ${siteEmailAddresses}`);
  return siteEmailAddresses;
}