import { Button, Input, Modal, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import React from 'react';
import { debug } from 'src/utils/commonUtils';
import { updateUserPrefs } from '../utils/UserPrefsUtils';
import { useBundle } from '@amzn/react-arb-tools';


const checkSearchName = (value: string): string => {
  const invalidSearchNames = ['Reset', 'Save Search', 'Saved Searches'];
  if (invalidSearchNames.includes(value)) return '';
  return value;
};

export default function DeleteSearch(props: any) {
  debug(`DeleteSearch(): props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.Common');

  const [searchName, _] = React.useState(checkSearchName(props.searchName));

  const deleteOnClickHandler = () => {
    debug(`DeleteSearch(): deleteOnChangeHandler()`);
    if (checkSearchName(searchName) === '') {
      return;
    }
    props.setShowDeleteSearchCallback(false);
    let nodeName = '';
    let typeName = '';
    switch (props.devicesType) {
      case 'inputs':
        nodeName = 'inputs';
        typeName = 'InputsPrefs';
        break;
      case 'outputs':
        nodeName = 'outputs';
        typeName = 'OutputsPrefs';
        break;
      case 'readersAlarms':
        nodeName = 'readersAlarms';
        typeName = 'ReadersAlarmsPrefs';
        break;
      case 'readersModes':
        nodeName = 'readersModes';
        typeName = 'ReadersModesPrefs';
        break;
    }
    const nodePrefs = props.userPrefs[nodeName] || {};
    const savedSearches = nodePrefs.savedSearches || [];
    debug(`DeleteSearch(): deleteOnChangeHandler() searchName is ${searchName}`);
    debug(`DeleteSearch(): deleteOnChangeHandler() savedSearches is ${JSON.stringify(savedSearches)}`);

    updateUserPrefs(
      {
        ...props.userPrefs,
        [nodeName]: {
          ...nodePrefs,
          __typename: typeName,
          savedSearches: savedSearches.filter((ss: any) => ss.name !== searchName)
        }
      });
    props.deletedSearchCallback(searchName);
  };

  const cancelOnClickHandler = () => {
    props.setShowDeleteSearchCallback(false);
  };

  if (isBundleLoading) return <Spinner />;

  return (
    <Modal
      header={bundle.getMessage('delete-saved-search')}
      visible={props.showDeleteSearch}
      onDismiss={() => props.setShowDeleteSearchCallback(false)}
    >
      <SpaceBetween direction='vertical' size={'l'}>
        <Input
          type='text'
          value={searchName}
          ariaDescribedby={undefined}
          disabled
        />
        <SpaceBetween direction='horizontal' size={'m'}>
          <Button
            variant='primary'
            onClick={deleteOnClickHandler}
            disabled={!searchName}
            ariaLabel={bundle.getMessage('delete-saved-search')}
          >
            {bundle.getMessage('delete')}
          </Button>
          <Button
            variant='normal'
            onClick={cancelOnClickHandler}
            ariaLabel='Cancel Delete Saved Search'
          >
            {bundle.getMessage('cancel')}
          </Button>
        </SpaceBetween>
      </SpaceBetween>
    </Modal>
  );
}