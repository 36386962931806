import { Box } from '@amzn/awsui-components-react';
import React from 'react';

export default function EmptyState(props: any) {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {props.title}
            </Box>
            <Box variant="p" padding={{ bottom: 's' }} color="inherit">
                {props.subtitle}
            </Box>
            {props.action}
        </Box>
    );
}

