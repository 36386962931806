import { debug, getPropertyFilterQuery, i18nFilterStrings } from '../utils/commonUtils';
import { EmptyPropertyFilterQuery, Modes, SilencioDeviceFilteringProperties } from '../constants/Constants';
import { PropertyFilter, Multiselect, MultiselectProps, Select, SelectProps, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import React, { useEffect } from 'react';
import { Search, selectReadersModesSearch, setReadersModesSearch } from 'src/stores/slices/devicesSlice';
import { selectUserPrefs as selectUserPrefsInStore, setSavedSearches as setSavedSearchesInStore } from '../stores/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { defaultMaskingGroup } from './maskingGroups/utils';
import DeleteSearch from './DeleteSearch';
import MaskingGroups from './maskingGroups';
import SaveSearch from './SaveSearch';
import { useBundle } from '@amzn/react-arb-tools';

interface ReadersModeFilterInterface {
  filteredItemsCount: any;
  readersType: string;
  refreshDevicesCallback: Function;
  resetSelectedItemsCallback: Function;
  setFilterCallback: Function;
}

export default function ReadersModeFilter(props: ReadersModeFilterInterface) {
  debug(`ReadersModeFilter() props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.Common');

  const dispatch = useDispatch();

  const selectUserPrefs = useSelector(selectUserPrefsInStore);
  const [userPrefs, setUserPrefs] = React.useState(selectUserPrefs);

  const readersModesSearch = useSelector(selectReadersModesSearch) as Search;
  debug(`ReadersModeFilter() readersModesSearch is ${JSON.stringify(readersModesSearch)}`);

  const [query, setQuery] = React.useState<any>(readersModesSearch?.query || EmptyPropertyFilterQuery);

  const [selectedFilterReadersModes, setSelectedFilterReadersModes] = React.useState<any>(
    readersModesSearch?.modes || Modes);

  const defaultSavedSearchesSelection =
  {
    label: 'Saved Searches',
    value: 'Saved Searches'
  };

  const [selectedReadersModesSavedSearch, setSelectedReadersModesSavedSearch] = React.useState<any>(
    readersModesSearch?.savedSearch || defaultSavedSearchesSelection);

  const [selectedMaskingGroup, setSelectedMaskingGroup] = React.useState<SelectProps.Option>(
    readersModesSearch?.maskingGroup || defaultMaskingGroup);

  const [showSaveSearch, setShowSaveSearch] = React.useState(false);
  const [showDeleteSearch, setShowDeleteSearch] = React.useState(false);

  const [savedSearchesOptions, setSavedSearchesOptions] = React.useState<SelectProps.Options>();

  const resetReadersModesFilters = () => {
    debug(`ReadersModeFilter() resetReadersModesFilters()`);
    setQuery(EmptyPropertyFilterQuery);
    setSelectedFilterReadersModes(Modes);
    setSelectedReadersModesSavedSearch({ label: 'Saved Searches', value: 'Saved Searches' });
    props.setFilterCallback(EmptyPropertyFilterQuery);
    props.resetSelectedItemsCallback();
    setSelectedMaskingGroup(defaultMaskingGroup);
    dispatch(setReadersModesSearch(
      {
        maskingGroup: undefined,
        maskStatus: undefined,
        modes: Modes,
        outputStatus: { label: 'Any Status', value: 'Any Status' },
        savedSearch: undefined,
        query: EmptyPropertyFilterQuery
      }
    ));
  };

  const setFilterReadersModesOnChangeHandler = (detail: MultiselectProps.MultiselectChangeDetail) => {
    setSelectedFilterReadersModes(detail.selectedOptions.slice())
    props.resetSelectedItemsCallback();
  };

  const savedSearch = (searchName: string) => {
    debug(`ReadersModeFilter() savedSearch() searchName is ${searchName}`);
    let savedSearches = userPrefs.readersModes.savedSearches || [];
    debug(`ReadersModeFilter() savedSearch() savedSearches is ${JSON.stringify(savedSearches)}`);
    const newSavedOption = { label: searchName, value: searchName };
    debug(`ReadersModeFilter() savedSearch() newSavedOption is ${JSON.stringify(newSavedOption)}`);
    setSelectedReadersModesSavedSearch(newSavedOption);
    dispatch(setSavedSearchesInStore(
      {
        devicesType: props.readersType,
        savedSearches: [
          ...savedSearches,
          {
            name: searchName,
            filterText: JSON.stringify(query),
            modes: selectedFilterReadersModes.map((m: { label: string, value: string }) => m.value)
          }
        ]
      }));
  };

  const deletedSearch = (searchName: string) => {
    debug(`ReadersModeFilter() deletedSearch() searchName is ${searchName}`);
    let savedSearches = userPrefs.readersModes.savedSearches || [];
    debug(`ReadersModeFilter() deletedSearch() savedSearches is ${JSON.stringify(savedSearches)}`);
    let filteredSearches = savedSearches.filter((ss: { name: string; filterText: string; maskStatus: string; }) => ss.name !== searchName);
    debug(`ReadersModeFilter() deletedSearch() filteredSearches is ${JSON.stringify(filteredSearches)}`);
    resetReadersModesFilters();
    dispatch(setSavedSearchesInStore(filteredSearches));
    dispatch(setSavedSearchesInStore(
      {
        devicesType: props.readersType,
        savedSearches: filteredSearches
      }));
  };

  const savedSearchesOnChange = (detail: SelectProps.ChangeDetail) => {
    const value = detail.selectedOption.value;
    const label = detail.selectedOption.label;
    const chosenSavedSearch = { label: label!, value: value! };
    debug(`ReadersModeFilter() savedSearchesOnChange() chosenSavedSearch is ${chosenSavedSearch}`);
    const actions = ['Reset', 'Save Search', 'Delete Search'];

    if (value === 'Reset') resetReadersModesFilters();
    if (value === 'Save Search') setShowSaveSearch(true);
    if (value === 'Delete Search') setShowDeleteSearch(true);
    if (!actions.includes(value!)) {
      setSelectedReadersModesSavedSearch(chosenSavedSearch);
      let savedSearches = userPrefs.readersModes.savedSearches || [];
      debug(`ReadersModeFilter() Saved Searches onChange() savedSearches is ${JSON.stringify(savedSearches)}`);
      const savedSearch = savedSearches.find(
        (ss: { name: string, filterText: string, maskStatus: string }) => {
          return ss.name === label;
        }
      );
      debug(`ReadersModeFilter() savedSearchesOnChange() savedSearch is ${JSON.stringify(savedSearch)}`);
      const savedQuery = getPropertyFilterQuery(savedSearch.filterText);
      props.resetSelectedItemsCallback();
      dispatch(setReadersModesSearch(
        {
          maskingGroup: undefined,
          maskStatus: savedSearch.maskStatus,
          modes: savedSearch.modes,
          outputStatus: savedSearch.outputStatus,
          savedSearch: chosenSavedSearch,
          query: savedQuery
        }
      ));
      setSelectedFilterReadersModes(savedSearch.modes.map((m: string) => { return { label: m, value: m } }));
      setQuery(savedQuery)
      props.setFilterCallback(savedQuery);
      setSelectedMaskingGroup(defaultMaskingGroup);
    }
  };

  useEffect(() => {
    debug(`ReadersModeFilter(): useEffect()[selectUserPrefs]`)
    const updatedUserPrefs = selectUserPrefs;
    debug(`ReadersModeFilter(): useEffect()[selectUserPrefs] updatedUserPrefs is ${JSON.stringify(updatedUserPrefs)}`);
    const savedSearches = updatedUserPrefs.readersModes.savedSearches;

    let options = [
      {
        label: 'Actions',
        options: [
          { label: 'Reset', value: 'Reset', iconName: 'undo' },
          { label: 'Save Search', value: 'Save Search', iconName: 'file' },
          { label: 'Delete Search', value: 'Delete Search', iconName: 'close' }
        ]
      },
      {
        label: 'Saved Searches',
        options: savedSearches.map(
          (ss: { name: string, filterText: string, maskStatus: string }) => {
            return { label: ss.name, value: ss.name }
          }
        ).sort(),
      }
    ];
    debug(`ReadersModeFilter(): useEffect()[selectUserPrefs] options is ${JSON.stringify(options)}`);
    setSavedSearchesOptions(options);
    setUserPrefs(updatedUserPrefs);
  }, [selectUserPrefs])

  useEffect(() => {
    debug(`ReadersModeFilter(): useEffect() [query] query is ${query}`);
    dispatch(setReadersModesSearch(
      {
        ...readersModesSearch,
        query: query
      }
    ));
    props.setFilterCallback(query);
  }, [query]);

  useEffect(() => {
    debug(`ReadersModeFilter(): useEffect() [selectedFilterReaderModes] selectedFilterReadersModes is ${selectedFilterReadersModes}`);
    dispatch(setReadersModesSearch(
      {
        ...readersModesSearch,
        modes: selectedFilterReadersModes
      }
    ));
  }, [selectedFilterReadersModes]);

  useEffect(() => {
    setSelectedMaskingGroup(readersModesSearch?.maskingGroup || defaultMaskingGroup);
  }, [readersModesSearch?.maskingGroup]);

  if (isBundleLoading) return <Spinner />;

  return (
    <SpaceBetween size="xs" direction="horizontal">
      <PropertyFilter
        countText={bundle.formatMessage('filter-matches', { count: props.filteredItemsCount === undefined ? 0 : props.filteredItemsCount })}
        expandToViewport
        filteringProperties={SilencioDeviceFilteringProperties.slice(0, 1)}
        i18nStrings={i18nFilterStrings(bundle)}
        onChange={({ detail }) => setQuery(detail)}
        query={query}
      />
      <Multiselect
        hideTokens
        expandToViewport
        selectedOptions={selectedFilterReadersModes}
        onChange={({ detail }) => setFilterReadersModesOnChangeHandler(detail)}
        deselectAriaLabel={e => `Remove ${e.label}`}
        options={Modes}
        placeholder={(selectedFilterReadersModes.length === Modes.length) ? 'Any Mode' : selectedFilterReadersModes.length === 0 ? 'None' : 'Subset'}
        selectedAriaLabel={bundle.getMessage('selected')}
      />
      <Select
        onChange={({ detail }) => { savedSearchesOnChange(detail) }}
        options={savedSearchesOptions}
        selectedAriaLabel={bundle.getMessage('selected-saved-search')}
        selectedOption={selectedReadersModesSavedSearch}
        expandToViewport
      />
      <MaskingGroups
        deviceType={'readersModes'}
        resetSearch={resetReadersModesFilters}
        selectedMaskingGroup={selectedMaskingGroup}
        setSearchInStore={setReadersModesSearch}
        setSelectedMaskingGroup={setSelectedMaskingGroup}
        resetSelectedItemsCallback={props.resetSelectedItemsCallback}
        refreshDevicesCallback={props.refreshDevicesCallback}
      />
      {showSaveSearch &&
        <SaveSearch
          showSaveSearch={showSaveSearch}
          setShowSaveSearchCallback={setShowSaveSearch}
          userPrefs={userPrefs}
          searchName={selectedReadersModesSavedSearch.label}
          filteringText={JSON.stringify(query)}
          filterReadersModes={selectedFilterReadersModes}
          savedSearchCallback={savedSearch}
          devicesType={props.readersType}
        />
      }
      {showDeleteSearch &&
        <DeleteSearch
          showDeleteSearch={showDeleteSearch}
          setShowDeleteSearchCallback={setShowDeleteSearch}
          userPrefs={userPrefs}
          searchName={selectedReadersModesSavedSearch.label}
          deletedSearchCallback={deletedSearch}
          devicesType={props.readersType}
        />
      }
    </SpaceBetween>
  );
}