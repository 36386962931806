import * as APIt from 'src/API';
import {
  Alert,
  Box,
  Button,
  Icon,
  Link,
  Modal,
  SpaceBetween,
  Spinner,
  Table,
  TextContent,
} from '@amzn/awsui-components-react';
import { DeviceMutationResponseInterface, PerformActionsRow, SilencioDevice } from 'src/utils/SilencioTypes';
import { DeviceMutationStatuses, DeviceTypeTabs, TemplateActions, TemplateTypes, URLS, UserActionNames } from 'src/constants/Constants';
import React, { useEffect, useState } from 'react';
import { debug, getKeywordTranslationKey } from '../../utils/commonUtils';
import {
  selectInputDevices,
  selectLoadingDevices,
  selectReadersAlarmsDevices,
  selectReadersModesDevices,
} from 'src/stores/slices/devicesSlice';
import {
  selectLoadingTemplates,
  setLoadingTemplates,
} from 'src/stores/slices/templatesSlice';
import { selectUserSelectedSite, selectUsername } from 'src/stores/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import TranslateText from '../TranslateText';
import { createUserAction } from 'src/utils/UserActionsUtils';
import { getSiteTemplateDevices } from 'src/utils/TemplatesUtils';
import { performActionOnDevices } from './utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';

interface IPerformActions {
  refreshDevicesCallback: Function;
  showModalCallback: Function;
  templateType: TemplateTypes | null;
  visible: boolean;
}

export const PerformActions = (props: IPerformActions) => {
  debug(`PerformActions() props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.templates.PerformActions');

  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<PerformActionsRow[]>([]);
  const [submitFinished, setSubmitFinished] = useState<boolean>(false);

  const inputDevices = useSelector(selectInputDevices) as SilencioDevice[];
  const loadingDevices = useSelector(selectLoadingDevices) as boolean;
  const loadingTemplates = useSelector(selectLoadingTemplates) as boolean;
  const readersAlarmsDevices = useSelector(selectReadersAlarmsDevices) as SilencioDevice[];
  const readersModesDevices = useSelector(selectReadersModesDevices) as SilencioDevice[];
  const username = useSelector(selectUsername) as string;
  const userSelectedSite = useSelector(selectUserSelectedSite) as APIt.UserPrefSite | undefined;

  const getDeviceTypeTab = (templateDevice: APIt.TemplateDevice): DeviceTypeTabs => {
    const action = templateDevice.Desired_State as TemplateActions;
    switch (action) {
      case TemplateActions.Mask:
      case TemplateActions.Unmask:
        return DeviceTypeTabs.inputs;
      case TemplateActions.MaskDoorForcedOpen:
      case TemplateActions.UnmaskDoorForcedOpen:
      case TemplateActions.MaskDoorHeldOpen:
      case TemplateActions.UnmaskDoorHeldOpen:
        return DeviceTypeTabs.readersAlarms;
      default:
        return DeviceTypeTabs.readersModes;
    }
  }

  const getSilencioDevice = (templateDevice: APIt.TemplateDevice, deviceTypeTab: DeviceTypeTabs): SilencioDevice => {
    let device: SilencioDevice | undefined;
    switch (deviceTypeTab) {
      case DeviceTypeTabs.inputs:
        device = inputDevices.find(d => `${d.Parent_DeviceID}_${d.Child_DeviceID}_${d.Subchild_DeviceID}` === templateDevice.amzn_key);
        break;
      case DeviceTypeTabs.readersAlarms:
        device = readersAlarmsDevices.find(d => `${d.Parent_DeviceID}_${d.Child_DeviceID}_${d.Subchild_DeviceID}` === templateDevice.amzn_key);
        break;
      default:
        device = readersModesDevices.find(d => `${d.Parent_DeviceID}_${d.Child_DeviceID}_${d.Subchild_DeviceID}` === templateDevice.amzn_key);
    }
    if (!device) {
      throw new Error(`Device not found for: ${templateDevice.amzn_key}`)
    }
    return device;
  }

  const loadTemplates = async () => {
    setErrorMessage(null);
    dispatch(setLoadingTemplates(true));

    const siteName = userSelectedSite?.sitename;
    const siteRegion = userSelectedSite?.siteRegion;
    const templateType = props.templateType;

    if (!siteName || !siteRegion) {
      setErrorMessage('Unable to determine site code or region.');
    } else if (!templateType) {
      setErrorMessage('Unable to determine template type.');
    } else {
      try {
        const actionRows: PerformActionsRow[] = [];
        const errors: string[] = [];
        debug(`PerformActions() loadTemplates() siteCode is ${siteName} siteRegion is ${siteRegion} template type is ${templateType}`);

        const templateDevices: APIt.TemplateDevice[] = await getSiteTemplateDevices(siteName, siteRegion, templateType, username);
        if (templateDevices.length === 0) {
          errors.push(`No actions found to take for template type: ${templateType}.`);
        } else {
          debug(`PerformActions() loadTemplates() template devices: ${JSON.stringify(templateDevices)}`);
          templateDevices.forEach((templateDevice: APIt.TemplateDevice) => {
            try {
              const deviceTypeTab = getDeviceTypeTab(templateDevice);
              const device = getSilencioDevice(templateDevice, deviceTypeTab);
              const actionRowIndex = actionRows.findIndex(actionRow => actionRow.action === templateDevice.Desired_State);
              if (actionRowIndex !== -1) {
                // Add device if not already in action row
                if (!actionRows[actionRowIndex].devices.find(d => d.Parent_DeviceID === device.Parent_DeviceID
                  && d.Child_DeviceID === device.Child_DeviceID
                  && d.Subchild_DeviceID === device.Subchild_DeviceID)) {
                  actionRows[actionRowIndex].devices.push(device);
                }
              } else {
                actionRows.push({
                  action: templateDevice.Desired_State as TemplateActions,
                  devices: [device],
                  deviceSource: templateDevice.devicesource,
                  deviceTypeTab: deviceTypeTab,
                  progress: DeviceMutationStatuses.notStarted
                });
              }
            } catch (err) {
              errors.push(`Error including device with amzn_key: ${templateDevice.amzn_key} for action ${templateDevice.Desired_State}.`);
            }
          });
          setRows(actionRows);
        }

        if (errors.length > 0) {
          setErrorMessage(`Error(s): ${errors.join(' ')}`);
        }
      } catch (err) {
        console.error(`PerformActions() loadTemplates() error: ${err}`);
        setErrorMessage('Error fetching site template actions.');
      }
    }
    dispatch(setLoadingTemplates(false));
  }

  const submitHandler = async () => {
    setLoading(true);
    setErrorMessage(null);

    const siteName = userSelectedSite?.sitename;
    const siteRegion = userSelectedSite?.siteRegion;
    const templateType = props.templateType;

    if (!siteName || !siteRegion) {
      setErrorMessage('Unable to determine site code or region');
    } else if (!templateType) {
      setErrorMessage('Unable to determine template type');
    } else {
      try {
        const actionRows: PerformActionsRow[] = rows;
        const errors: string[] = [];
        const results: DeviceMutationResponseInterface[] = [];
        debug(`PerformActions() submitHandler() siteCode is ${siteName} siteRegion is ${siteRegion} template type is ${templateType}`);

        createUserAction({
          actionName: templateType === TemplateTypes.open ? UserActionNames.OpenSite : UserActionNames.CloseSite,
          username: username,
          parameters: JSON.stringify({ actionRows, siteName, siteRegion })
        });

        for (const row of actionRows) {
          row.progress = DeviceMutationStatuses.inProgress;
          setRows([...actionRows]);
          debug(`PerformActions() submitHandler() row is ${JSON.stringify(row)}`);
          const result: DeviceMutationResponseInterface = await performActionOnDevices(
            row.deviceSource,
            row.devices,
            row.deviceTypeTab,
            row.action,
            username,
            siteName,
            siteRegion
          );
          results.push(result);

          if ((result.status !== 200 && result.status !== 202) || result.devices.find((d: APIt.SetDeviceResponse) => (d.status !== 'Success'))) {
            const failedDevices: string[] = result.devices.filter((d: APIt.SetDeviceResponse) => (d.status !== 'Success')).map(d => d.deviceName);
            if (failedDevices.length === 0) {
              errors.push(`Encountered failure for ${row.action} action.`);
            } else if (failedDevices.length > 5) {
              errors.push(`${row.action} action failed for ${failedDevices.length} devices.`);
            } else {
              errors.push(`${row.action} action failed for: ${failedDevices.join(', ')}.`);
            }
            row.progress = DeviceMutationStatuses.failure;
          } else if (result.status === 200) {
            row.progress = DeviceMutationStatuses.updated;
          } else {
            row.progress = DeviceMutationStatuses.submitted;
          }
          setRows([...actionRows]);
        }

        props.refreshDevicesCallback();

        if (errors.length === 0) {
          createUserAction({
            actionName: templateType === TemplateTypes.open ? UserActionNames.OpenSiteResult : UserActionNames.CloseSiteResult,
            username: username,
            parameters: JSON.stringify({ actionRows, results, siteName, siteRegion })
          });
        } else {
          createUserAction({
            actionName: templateType === TemplateTypes.open ? UserActionNames.OpenSiteError : UserActionNames.CloseSiteError,
            username: username,
            parameters: JSON.stringify({ actionRows, results, siteName, siteRegion })
          });
          setErrorMessage(`Error(s): ${errors.join(' ')}`);
        }
      } catch (err) {
        const errMessage = `PerformActions() submitHandler() error: ${err}`;
        console.error(errMessage);
        setErrorMessage('Error submitting site template actions.');
        createUserAction({
          actionName: templateType === TemplateTypes.open ? UserActionNames.OpenSiteError : UserActionNames.CloseSiteError,
          username: username,
          parameters: JSON.stringify({ error: errMessage, siteName, siteRegion })
        });
      }
    }
    setLoading(false);
    setSubmitFinished(true);
  };

  const { items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps } = useCollection(
    rows,
    {
      pagination: {},
      sorting: {},
      selection: {},
    }
  );

  useEffect(() => {
    if (userSelectedSite && props.templateType && !submitFinished && !loadingDevices) {
      (async () => {
        await loadTemplates();
      })();
    }
  }, [loadingDevices]);

  if (isBundleLoading) return <Spinner />;

  return (
    <Modal
      footer={submitFinished ?
        <>
          <SpaceBetween direction='horizontal' size='xs'>
            <Icon name='status-info' />
            <span>{bundle.getMessage('information')}</span>
          </SpaceBetween>
          <TextContent>{bundle.getMessage('schedules-notice')}</TextContent>
          <Box float='right'>
            <Button
              variant='primary'
              onClick={() => props.showModalCallback(false)}
            >
              {bundle.getMessage('close')}
            </Button>
          </Box>
        </>
        :
        <Box float='right'>
          <SpaceBetween size='s' direction='horizontal'>
            <Button
              onClick={() => props.showModalCallback(false)}
            >
              {bundle.getMessage('cancel')}
            </Button>
            <Button
              variant='primary'
              onClick={submitHandler}
              disabled={(loading || loadingDevices || loadingTemplates || rows.length === 0)}
            >
              {bundle.getMessage('submit')}
            </Button>
          </SpaceBetween>
        </Box >
      }
      header={bundle.formatMessage('open-close-site', { input: props.templateType })}
      onDismiss={() => props.showModalCallback(false)}
      size='large'
      visible={props.visible}
    >
      <SpaceBetween size='m' direction='vertical'>
        {errorMessage != null &&
          <Alert
            dismissible={true}
            onDismiss={() => setErrorMessage(null)}
            type='error'
          >
            {errorMessage}
          </Alert>
        }
        <Table
          {...collectionProps}
          ariaLabels={{
            tableLabel: bundle.getMessage('template-progress')
          }}
          columnDefinitions={[
            {
              id: 'action',
              header: <TranslateText translateKey={'action'} />,
              cell: (item: PerformActionsRow) => <TranslateText translateKey={getKeywordTranslationKey(item.action)} />,
              sortingField: 'action'
            },
            {
              id: 'devices',
              header: <TranslateText translateKey={'name'} />,
              cell: (item: PerformActionsRow) => {
                if (item.devices.length === 1) {
                  return item.devices[0].DeviceName
                } else {
                  return <>{bundle.formatMessage('number-of-devices', { deviceCount: item.devices.length })}</>
                }
              },
              sortingField: 'devices'
            },
            {
              id: "status",
              header: <TranslateText translateKey={'status'} />,
              cell: (item: PerformActionsRow) => {
                switch (item.progress) {
                  case DeviceMutationStatuses.updated:
                    return <span style={{ color: 'green' }}><TranslateText translateKey={item.progress} /></span>
                  case DeviceMutationStatuses.failure:
                    return <span style={{ color: 'red' }}><TranslateText translateKey={item.progress} /></span>
                  case DeviceMutationStatuses.submitted:
                    return <span style={{ color: 'green' }}><TranslateText translateKey={item.progress} /></span>
                  default:
                    return <TranslateText translateKey={item.progress} />
                }
              },
              sortingField: "progress"
            }
          ]}
          empty={
            <Box textAlign='center' color='inherit'>
              <Box variant='strong' textAlign='center' color='inherit'>
                {bundle.getMessage('configure-quick-actions')}{" "}
                <Link href={URLS.SiteOpenClose} external target={'_blank'}>
                  <b>{bundle.getMessage('see-more-on-templates')}</b>
                </Link>
              </Box>
            </Box>
          }
          items={items}
          loading={loadingTemplates && !submitFinished}
          loadingText={bundle.getMessage('loading-template')}
          resizableColumns={true}
          stickyHeader={true}
          trackBy={'action'}
        />
      </SpaceBetween>
    </Modal>
  );
}
