import {
  Box,
  Button,
  FormField,
  Input,
  Modal,
  Pagination,
  ProgressBar,
  Select,
  SpaceBetween,
  Spinner,
  Table
} from '@amzn/awsui-components-react';
import { DelayOptions, DeviceMutationStatuses } from 'src/constants/Constants';
import React, { useEffect, useState } from 'react';
import { debug, filterModeOptions, getKeywordTranslationKey, validEmailAddresses } from '../utils/commonUtils';
import { selectPhySecMember, selectSideTeam, selectSigAdmin, selectUsername, selectUserSelectedSite } from '../stores/slices/userSlice';
import EmptyState from './EmptyState';
import { SilencioDevice } from 'src/utils/SilencioTypes';
import TranslateText from './TranslateText';
import { setReaderMode } from 'src/utils/DevicesUtils';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useSelector } from 'react-redux';

interface ChangeModeInterface {
  changeModeVisible: boolean;
  emailAddresses: string;
  setChangeModeVisibleCallback(mode: boolean): void;
  singleSelection?: { device: SilencioDevice, selection: { label: string, value: string } };
  selectedDevices?: SilencioDevice[];
  totalDevicesCount: number;
  updateDeviceCallback(updatedDevice: SilencioDevice): void;
}

// TODO: Use ChangeModeInterface 
export default function ChangeMode(props: any) {
  debug(`ChangeMode() props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.ChangeMode');

  const [emailAddresses, setEmailAddresses] = useState(props.emailAddresses);
  const [confirmed, setConfirmed] = useState(false);
  const [newMode, setNewMode] = useState<any>(props.singleSelection?.selection);
  const [disableSelectMode, setDisableSelectMode] = useState<boolean>(false);
  const [delay, setDelay] = useState<any>({ label: 'None', value: '0' });
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [progress, setProgress] = useState(0);
  const [progressDescription, setProgressDescription] = useState('');
  const [selectedDevices, setSelectedDevices] = useState(
    props.singleSelection ? [{ ...props.singleSelection.device, setReaderModeStatus: DeviceMutationStatuses.notStarted }] :
      props.selectedDevices.map((sd: SilencioDevice) => {
        return {
          ...sd,
          setReaderModeStatus: DeviceMutationStatuses.notStarted
        }
      }));
  const [showProgress, setShowProgress] = useState(false);

  const username = useSelector(selectUsername);
  const userSelectedSite = useSelector(selectUserSelectedSite);
  const phySecMember = useSelector(selectPhySecMember);
  const sideTeam = useSelector(selectSideTeam);
  const sigAdmin = useSelector(selectSigAdmin);

  const setReaderModeCallbackSuccess = (result: any) => {
    console.log('ChangeMode() setReaderModeCallbackSuccess() result is ', result);
    console.log('ChangeMode() setReaderModeCallbackSuccess() selectedDevices is ', selectedDevices);
    const selectedDevicesCopy = [...selectedDevices];
    const updatedSelectedDeviceIndex = selectedDevicesCopy.findIndex((sd: any) => sd.DeviceName === result.devices[0].deviceName);
    console.log('ChangeStatus() setReaderModeCallbackSuccess() updatedSelectedDeviceIndex is ', updatedSelectedDeviceIndex);
    const updatedSelectedDevice = selectedDevicesCopy[updatedSelectedDeviceIndex];
    if (result.status === 200 && result.devices[0].status === 'Success') {
      updatedSelectedDevice.setReaderModeStatus = DeviceMutationStatuses.updated;
      updatedSelectedDevice.Mode = newMode.value;
      const updatedSelectedDeviceForCallback = { ...updatedSelectedDevice };
      delete updatedSelectedDeviceForCallback.setReaderModeStatus;
      console.log('ChangeMode() setReaderModeCallbackSuccess() calling props.updateDeviceCallback() with ', updatedSelectedDeviceForCallback);
      props.updateDeviceCallback(updatedSelectedDeviceForCallback);
    } else {
      updatedSelectedDevice.setReaderModeStatus = DeviceMutationStatuses.failure;
    }
    console.log('ChangeMode() setReaderModeCallbackSuccess() updatedSelectedDevice is ', updatedSelectedDevice);
    selectedDevicesCopy.splice(updatedSelectedDeviceIndex, 1, updatedSelectedDevice);
    console.log('ChangeStatus() setDeviceMaskCallbackSuccess() selectedDevicesCopy is ', selectedDevicesCopy);
    setSelectedDevices(selectedDevicesCopy);
  };

  const setReaderModeCallbackFailure = (result: any) => {
    console.log('ChangeMode() setReaderModeCallbackFailure() result is ', result);
    const selectedDevicesCopy = [...selectedDevices];
    const failedUpdateSelectedDeviceIndex = selectedDevicesCopy.findIndex((sd: any) => sd.DeviceName === result.devices[0].deviceName);
    const failedUpdateSelectedDevice = selectedDevicesCopy[failedUpdateSelectedDeviceIndex];
    failedUpdateSelectedDevice.setReaderModeStatus = DeviceMutationStatuses.failure;
    selectedDevicesCopy.splice(failedUpdateSelectedDeviceIndex, 1, failedUpdateSelectedDeviceIndex);
    console.log('ChangeStatus() setDeviceMaskCallbackFailure() selectedDevicesCopy is ', selectedDevicesCopy);
    setSelectedDevices(selectedDevicesCopy);
  };

  const setReaderModeCallbackSuccessForDelay = (result: any) => {
    console.log('ChangeStatus() setReaderModeCallbackSuccessForDelay() result is ', result);
    console.log('ChangeStatus() setReaderModeCallbackSuccessForDelay() selectedDevices is ', selectedDevices);
    const selectedDevicesCopy = [...selectedDevices];
    for (let result_device of result.devices) {
      console.log('ChangeStatus() setReaderModeCallbackSuccessForDelay() result_device is ', result_device);
      const updatedSelectedDeviceIndex = selectedDevicesCopy.findIndex((sd: any) => sd.DeviceName === result_device.deviceName);
      console.log('ChangeStatus() setReaderModeCallbackSuccessForDelay() updatedSelectedDeviceIndex is ', updatedSelectedDeviceIndex);
      const updatedSelectedDevice = selectedDevicesCopy[updatedSelectedDeviceIndex];
      if (result.status === 202) updatedSelectedDevice.setReaderModeStatus = DeviceMutationStatuses.submitted;
      if (result.status !== 202) updatedSelectedDevice.setReaderModeStatus = DeviceMutationStatuses.failure;
      console.log('ChangeStatus() setReaderModeCallbackSuccessForDelay() updatedSelectedDevice is ', updatedSelectedDevice);
      selectedDevicesCopy.splice(updatedSelectedDeviceIndex, 1, updatedSelectedDevice);
      console.log('ChangeStatus() setReaderModeCallbackSuccessForDelay() selectedDevicesCopy is ', selectedDevicesCopy);
    }
    setSelectedDevices(selectedDevicesCopy);
    console.log('ChangeStatus() setReaderModeCallbackSuccessForDelay() done with call to setSelectedDevices using ', selectedDevicesCopy);
    setProgressDescription(`Complete: Submitted Request ${result.requestUUID}`);
    setProgress(100);
    setInProgress(false);
  };

  const setReaderModeCallbackFailureForDelay = (result: any) => {
    console.log('ChangeStatus() setReaderModeCallbackFailureForDelay() result is ', result);
    const selectedDevicesCopy = [...selectedDevices];
    for (let result_device of result.devices) {
      const failedUpdateSelectedDeviceIndex = selectedDevicesCopy.findIndex((sd: any) => sd.DeviceName === result_device.deviceName);
      const failedUpdateSelectedDevice = selectedDevicesCopy[failedUpdateSelectedDeviceIndex];
      failedUpdateSelectedDevice.setReaderModeStatus = DeviceMutationStatuses.failure;
      selectedDevicesCopy.splice(failedUpdateSelectedDeviceIndex, 1, failedUpdateSelectedDevice);
    }
    console.log('ChangeStatus() setReaderModeCallbackFailureForDelay() selectedDevicesCopy is ', selectedDevicesCopy);
    setSelectedDevices(selectedDevicesCopy);
    setProgressDescription(`Failure: Submitted Request ${result.requestUUID}`);
    setProgress(100);
    setInProgress(false);
  };

  const processDevices = () => {
    if (inProgress) return;
    console.log('ChangeMode() processDevices()');
    let updatedSelectedDevices: SilencioDevice[] = [];
    const selectedDevicesCopy = [...selectedDevices];
    console.log('ChangeMode() processDevices() selectedDevicesCopy.length is ', selectedDevicesCopy.length);
    console.log('ChangeMode() processDevices() selectedDevicesCopy is ', selectedDevicesCopy);
    console.log('ChangeMode() processDevices() delay.value is ', delay.value);
    if (delay.value > 0) {
      for (let i = 0; i < selectedDevicesCopy.length; i++) {
        const updatedSelectedDevice = selectedDevicesCopy[i];
        updatedSelectedDevice.setReaderModeStatus = DeviceMutationStatuses.inProgress;
        updatedSelectedDevices = [...selectedDevicesCopy.filter((sd: SilencioDevice) => sd.DeviceName !== updatedSelectedDevice.DeviceName), updatedSelectedDevice];
      }
      const devices = selectedDevicesCopy.map((sd: any) => { return { deviceName: sd.DeviceName, deviceHref: sd.device_href } });
      console.log('ChangeStatus() processDevices() devices is ', devices);
      setReaderMode(
        selectedDevices[0].devicesource,
        devices,
        emailAddresses,
        newMode.value,
        delay.value * 60,
        username,
        userSelectedSite.sitename,
        userSelectedSite.siteRegion)
        .then(setReaderModeCallbackSuccessForDelay, setReaderModeCallbackFailureForDelay);
    } else {
      for (let i = 0; i < selectedDevices.length; i++) {
        if (!selectedDevices[i].DeviceName || !selectedDevices[i].devicesource) continue;
        const deviceHref = selectedDevices[i].device_href === 'Not_Available' ? undefined : selectedDevices[i].device_href;
        const devices = [{ deviceName: selectedDevices[i].DeviceName, deviceHref: deviceHref }];
        setReaderMode(selectedDevices[i].devicesource!, devices, '', newMode.value, 0, username, userSelectedSite.sitename, userSelectedSite.siteRegion)
          .then(setReaderModeCallbackSuccess, setReaderModeCallbackFailure);
        const updatedSelectedDevice = selectedDevices[i];
        updatedSelectedDevice.setReaderModeStatus = DeviceMutationStatuses.inProgress;
        updatedSelectedDevices = [...selectedDevices.filter((sd: SilencioDevice) => sd.DeviceName !== updatedSelectedDevice.DeviceName), updatedSelectedDevice];
      }
      console.log('ChangeMode() processDevices() updatedSelectedDevices is ', updatedSelectedDevices);
      setSelectedDevices(updatedSelectedDevices);
    }
  };

  const confirmBtnHandler = () => {
    console.log('ChangeMode() confirmBtnHandler()');
    setDisableSelectMode(true);
    setProgress(0);
    setInProgress(true);
    setConfirmed(true);
    setShowProgress(true);
    processDevices();
  };

  useEffect(() => {
    if (progress >= 100 || delay.value > 0) return;
    debug(`ChangeMode() useEffect() [showProgress, selectedDevices] progress is ${progress} showProgress is ${showProgress}`);
    debug(`ChangeMode() useEffect() [showProgress, selectedDevices] selectedDevices is ${JSON.stringify(selectedDevices)}`);
    if (showProgress) {
      debug(`ChangeMode() useEffect() [showProgress, selectedDevices] selectedDevices.length is ${selectedDevices.length}`);
      let processedCount = 0;
      for (let i = 0; i < selectedDevices.length; i++) {
        if (selectedDevices[i].setReaderModeStatus === DeviceMutationStatuses.updated ||
          selectedDevices[i].setReaderModeStatus === DeviceMutationStatuses.failure ||
          selectedDevices[i].setReaderModeStatus === DeviceMutationStatuses.submitted)
          processedCount++;
      }
      debug(`ChangeMode() useEffect() [showProgress, selectedDevices] processedCount is ${processedCount}`);
      setProgress(Math.ceil(processedCount / selectedDevices.length * 100));
      const remaining = selectedDevices.length - processedCount;
      if (remaining === 0)
        setProgressDescription('Complete');
      else
        setProgressDescription(`${remaining} Devices Remaining...`);
    }
  }, [showProgress, selectedDevices]);

  useEffect(() => {
    if (progress < 100 || delay.value > 0) return;
    debug(`ChangeMode() useEffect() [progress] progress is ${progress}`);
    const selectedDevicesCopy = [...selectedDevices];
    selectedDevicesCopy.sort((a, b) => {
      if (b.setReaderModeStatus !== DeviceMutationStatuses.failure && a.setReaderModeStatus === DeviceMutationStatuses.failure) return 0;
      return 1;
    });
    debug(`ChangeMode() useEffect() [progress] selectedDevicesCopy is ${JSON.stringify(selectedDevicesCopy)}`);
    setSelectedDevices(selectedDevicesCopy);
  }, [progress]);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    selectedDevices,
    {
      filtering: {
        empty: (
          <EmptyState
            title="No devices"
            subtitle="No devices to display."
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: 5 },
      sorting: {},
      selection: {},
    }
  );

  const filteredModes = filterModeOptions(phySecMember, sideTeam, sigAdmin);

  const paginationLabels = {
    nextPageLabel: 'Next page',
    pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
    previousPageLabel: 'Previous page'
  };

  useEffect(() => {
    // automatically confirm for single device selection
    debug(`ChangeMode() useEffect() [] props.singleSelection is ${JSON.stringify(props.singleSelection)} newMode is ${JSON.stringify(newMode)} items.length is ${items.length}`);
    if (props.singleSelection && newMode && items.length > 0) confirmBtnHandler();
  }, [])

  if (isBundleLoading) return <Spinner />;

  return (
    <Modal
      footer={
        <Box float={'right'}>
          <Button
            disabled={!newMode?.value || showProgress && progress < 100 || (delay.valuee > 0 && !validEmailAddresses(emailAddresses))}
            onClick={() => {
              if (progress === 0) confirmBtnHandler();
              if (progress >= 100) props.setChangeModeVisibleCallback(false);
            }}
            variant="primary"
          >
            {progress < 100 ? bundle.getMessage('confirm') : bundle.getMessage('ok')}
          </Button>
        </Box>
      }
      header={bundle.formatMessage('change-mode', { selectedCount: props.singleSelection ? 1 : props.selectedDevices.length, total: props.totalDevicesCount })}
      onDismiss={() => props.setChangeModeVisibleCallback(false)}
      size="large"
      visible={props.changeModeVisible}
    >
      <Table
        {...collectionProps}
        trackBy='DeviceName'
        header={
          <>
            {
              showProgress &&
              <ProgressBar
                status="in-progress"
                value={progress}
                additionalInfo=""
                description={progressDescription}
                label={bundle.formatMessage('update-mode', { newMode: newMode.label })}
              />
            }
            <SpaceBetween size="xs" direction="horizontal">
              <FormField label={bundle.getMessage('new-mode')}>
                <Select
                  disabled={disableSelectMode}
                  invalid={!newMode}
                  onChange={({ detail }) => {
                    setNewMode(detail.selectedOption);
                    setShowProgress(false);
                  }}
                  options={filteredModes}
                  placeholder={bundle.getMessage('select-a-mode')}
                  selectedAriaLabel={bundle.getMessage('selected')}
                  selectedOption={props.singleSelection ? props.singleSelection.selection : newMode}
                />
              </FormField>
              <FormField label={bundle.getMessage('delay')}>
                <Select
                  disabled={confirmed}
                  expandToViewport
                  onChange={({ detail }) => {
                    setDelay(detail.selectedOption)
                  }}
                  options={DelayOptions}
                  selectedAriaLabel={bundle.getMessage('selected')}
                  selectedOption={delay}
                />
              </FormField>
              {delay.value != 0 &&
                <FormField label={bundle.getMessage('email-addresses')}>
                  <Input
                    disabled={delay.value == 0 || confirmed}
                    invalid={!emailAddresses}
                    onChange={({ detail }) => setEmailAddresses(detail.value)}
                    placeholder={bundle.getMessage('email-addresses')}
                    value={emailAddresses}
                  />
                </FormField>
              }
            </SpaceBetween>
          </>
        }
        ariaLabels={{
          selectionGroupLabel: bundle.getMessage('devices-selection'),
          allItemsSelectionLabel: ({ selectedItems }) =>
            `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"
            } selected`,
          itemSelectionLabel: ({ selectedItems }, item: any) => {
            const isItemSelected = selectedItems.filter(
              (i: any) => i.name === item.name
            ).length;
            return `${item.name} is ${isItemSelected ? "" : "not"
              } selected`;
          }
        }}
        columnDefinitions={[
          {
            id: "deviceName",
            header: bundle.getMessage('device-name'),
            cell: (e: any) => e.DeviceName,
            sortingField: "DeviceName"
          },
          {
            id: "mode",
            header: bundle.getMessage('mode'),
            cell: (e: any) => <TranslateText translateKey={getKeywordTranslationKey(e.Mode || 'Unknown')} />,
            sortingField: "Mode"
          },
          {
            id: "setReaderModeStatus",
            header: bundle.getMessage('status'),
            cell: (e: any) => {
              switch (e.setReaderModeStatus) {
                case DeviceMutationStatuses.updated:
                  return <span style={{ color: 'green' }}><TranslateText translateKey={e.setReaderModeStatus} /></span>
                case DeviceMutationStatuses.failure:
                  return <span style={{ color: 'red' }}><TranslateText translateKey={e.setReaderModeStatus} /></span>
                case DeviceMutationStatuses.submitted:
                  return <span style={{ color: 'green' }}><TranslateText translateKey={e.setReaderModeStatus} /></span>
                default:
                  return <TranslateText translateKey={e.setReaderModeStatus} />
              }
            },
            sortingField: "setReaderModeStatus"
          },
        ]}
        items={items}
        visibleColumns={[
          'deviceName',
          'mode',
          'setReaderModeStatus'
        ]}
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={paginationLabels}
          />
        }
      />
    </Modal>
  )
}