import { MaskingGroupInfo, SilencioDevice } from "src/utils/SilencioTypes";
import { createSlice } from '@reduxjs/toolkit';
import { debug } from '../../utils/commonUtils';

export type Search = {
  maskStatus: { label: string, value: string } | undefined,
  modes: { label: string, value: string }[] | undefined,
  outputStatus: { label: string, value: string } | undefined,
  savedSearch: { label: string, value: string } | undefined,
  query: any | undefined,
  maskingGroup: { label: string, value: string } | undefined,
};

export interface DevicesSliceInterface {
  inputs: SilencioDevice[];
  inputsMaskingGroups: MaskingGroupInfo[];
  inputsSearch: Search | undefined;
  loadingDevices: boolean,
  outputs: SilencioDevice[];
  outputsSearch: Search | undefined;
  readersAlarms: SilencioDevice[];
  readersAlarmsMaskingGroups: MaskingGroupInfo[];
  readersModes: SilencioDevice[];
  readersModesMaskingGroups: MaskingGroupInfo[];
  readersAlarmsSearch: Search | undefined;
  readersModesSearch: Search | undefined;
  selectedInputs: SilencioDevice[];
  selectedOutputs: SilencioDevice[];
  selectedReadersAlarms: SilencioDevice[];
  selectedReadersModes: SilencioDevice[];
  textFilter: string | null;
};

export const initialDevicesState: DevicesSliceInterface = {
  inputs: [],
  inputsMaskingGroups: [],
  inputsSearch: undefined,
  loadingDevices: false,
  outputs: [],
  outputsSearch: undefined,
  readersAlarms: [],
  readersAlarmsMaskingGroups: [],
  readersModes: [],
  readersModesMaskingGroups: [],
  readersAlarmsSearch: undefined,
  readersModesSearch: undefined,
  selectedInputs: [],
  selectedOutputs: [],
  selectedReadersAlarms: [],
  selectedReadersModes: [],
  textFilter: null,
};

export const devicesSlice = createSlice({
  name: 'devicesState',
  initialState: initialDevicesState,
  reducers: {
    setInputsDevices: (state, action: { type: string, payload: { inputs: SilencioDevice[] } }) => {
      state.inputs = action.payload.inputs;
    },
    setInputsMaskingGroups: (state, action: { type: string, payload: { maskingGroups: MaskingGroupInfo[] } }) => {
      state.inputsMaskingGroups = action.payload.maskingGroups;
    },
    setInputsSearch: (state, action: { type: string, payload: Search | undefined }) => {
      state.inputsSearch = action.payload;
    },
    setInputsSearchMaskingGroup: (state, action: { type: string, payload: { label: string, value: string } | undefined }) => {
      if (state.inputsSearch) {
        state.inputsSearch.maskingGroup = action.payload;
      }
    },
    setLoadingDevices: (state, action: { type: string, payload: boolean }) => {
      state.loadingDevices = action.payload;
    },
    setOutputsDevices: (state, action: { type: string, payload: { outputs: SilencioDevice[] } }) => {
      state.outputs = action.payload.outputs;
    },
    setOutputsSearch: (state, action: { type: string, payload: Search | undefined }) => {
      state.outputsSearch = action.payload;
    },
    setReadersAlarmsDevices: (state, action: { type: string, payload: { readersAlarms: SilencioDevice[] } }) => {
      state.readersAlarms = action.payload.readersAlarms;
    },
    setReadersAlarmsMaskingGroups: (state, action: { type: string, payload: { maskingGroups: MaskingGroupInfo[] } }) => {
      state.readersAlarmsMaskingGroups = action.payload.maskingGroups;
    },
    setReadersModesDevices: (state, action: { type: string, payload: { readersModes: SilencioDevice[] } }) => {
      state.readersModes = action.payload.readersModes;
    },
    setReadersModesMaskingGroups: (state, action: { type: string, payload: { maskingGroups: MaskingGroupInfo[] } }) => {
      state.readersModesMaskingGroups = action.payload.maskingGroups;
    },
    setReadersAlarmsSearch: (state, action: { type: string, payload: Search | undefined }) => {
      state.readersAlarmsSearch = action.payload;
    },
    setReadersAlarmsSearchMaskingGroup: (state, action: { type: string, payload: { label: string, value: string } | undefined }) => {
      if (state.readersAlarmsSearch) {
        state.readersAlarmsSearch.maskingGroup = action.payload;
      }
    },
    setReadersModesSearch: (state, action: { type: string, payload: Search | undefined }) => {
      state.readersModesSearch = action.payload;
    },
    setReadersModesSearchMaskingGroup: (state, action: { type: string, payload: { label: string, value: string } | undefined }) => {
      if (state.readersModesSearch) {
        state.readersModesSearch.maskingGroup = action.payload;
      }
    },
    setSelectedInputs: (state, action: { type: string, payload: SilencioDevice[] }) => {
      debug(`setSelectedInputs() action is ${JSON.stringify(action)}`);
      state.selectedInputs = action.payload;
    },
    setSelectedOutputs: (state, action: { type: string, payload: SilencioDevice[] }) => {
      state.selectedOutputs = action.payload;
    },
    setSelectedReadersAlarms: (state, action: { type: string, payload: SilencioDevice[] }) => {
      state.selectedReadersAlarms = action.payload;
    },
    setSelectedReadersModes: (state, action: { type: string, payload: SilencioDevice[] }) => {
      state.selectedReadersModes = action.payload;
    },
    updateInputMasked: (state, action: { type: string, payload: SilencioDevice }) => {
      debug(`updateInputMasked() action is ${JSON.stringify(action)}`);
      const updatedDevice = action.payload as SilencioDevice;
      state.inputs.forEach((e: SilencioDevice, i) => {
        if (e.DeviceName === updatedDevice.DeviceName) state.inputs[i].Masked = updatedDevice.Masked;
      });
      debug(`updateInputStatus() state.inputs is ${JSON.stringify(state.inputs)}`);
    },
    updateOutputStatus: (state, action: { type: string, payload: SilencioDevice }) => {
      debug(`updateOutputStatus() action is ${JSON.stringify(action)}`);
      const updatedDevice = action.payload as SilencioDevice;
      state.outputs.forEach((e: SilencioDevice, i) => {
        if (e.DeviceName === updatedDevice.DeviceName) state.outputs[i].OutputStatus = updatedDevice.OutputStatus;
      });
      debug(`updateOutputStatus() state.outputs is ${JSON.stringify(state.outputs)}`);
    },
    updateReaderMasked: (state, action: { type: string, payload: SilencioDevice }) => {
      debug(`updateReaderMasked() action is ${JSON.stringify(action)}`);
      const updatedDevice = action.payload as SilencioDevice;
      state.readersAlarms.forEach((e: SilencioDevice, i) => {
        if (e.DeviceName === updatedDevice.DeviceName) {
          state.readersAlarms[i].DoorForcedMasked = updatedDevice.DoorForcedMasked;
          state.readersAlarms[i].DoorHeldMasked = updatedDevice.DoorHeldMasked;
        }
      });
      debug(`updateReaderMasked() state.readersAlarms is ${JSON.stringify(state.readersAlarms)}`);
    },
    updateReaderMode: (state, action: { type: string, payload: SilencioDevice }) => {
      debug(`updateReaderMode() action is ${JSON.stringify(action)}`);
      const updatedDevice = action.payload;
      state.readersModes.forEach((e, i) => {
        if (e.DeviceName === updatedDevice.DeviceName) state.readersModes[i].Mode = updatedDevice.Mode;
      });
      debug(`updateReaderMode() state.readersModes is ${JSON.stringify(state.readersModes)}`);
    },
    updateSelectedInputMask: (state, action: { type: string, payload: SilencioDevice }) => {
      debug('updateSelectedInputMask()');
      debug(`updateSelectedInputMask() action is ${JSON.stringify(action)}`);
      const updatedDevice = action.payload;
      state.selectedInputs.forEach((e, i) => {
        if (e.DeviceName === updatedDevice.DeviceName) state.selectedInputs[i].Masked = updatedDevice.Masked;
      });
      debug(`updateSelectedInputMask() state.selectedInputs is ${JSON.stringify(state.selectedInputs)}`);
    },
    updateSelectedOutputStatus: (state, action: { type: string, payload: SilencioDevice }) => {
      debug(`updateSelectedOutputStatus() action is ${JSON.stringify(action)}`);
      const updatedDevice = action.payload;
      state.selectedOutputs.forEach((e, i) => {
        if (e.DeviceName === updatedDevice.DeviceName) state.selectedOutputs[i].OutputStatus = updatedDevice.OutputStatus;
      });
      debug(`updateSelectedOutputStatus() state.selectedOutputs is ${JSON.stringify(state.selectedOutputs)}`);
    },
    updateSelectedReaderMasked: (state, action: { type: string, payload: SilencioDevice }) => {
      debug(`updateSelectedReaderAlarmDoorForcedMasked() action is ${JSON.stringify(action)}`);
      const updatedDevice = action.payload;
      state.selectedReadersAlarms.forEach((e, i) => {
        if (e.DeviceName === updatedDevice.DeviceName) {
          state.selectedReadersAlarms[i].DoorForcedMasked = updatedDevice.DoorForcedMasked;
          state.selectedReadersAlarms[i].DoorHeldMasked = updatedDevice.DoorHeldMasked;
        }
      });
      debug(`updateSelectedReaderAlarmDoorForcedMasked() state.selectedReadersAlarms is ${JSON.stringify(state.selectedReadersAlarms)}`);
    },
    updateSelectedReaderMode: (state, action: { type: string, payload: SilencioDevice }) => {
      debug(`updateSelectedReaderMode() action is ${JSON.stringify(action)}`);
      const updatedDevice = action.payload;
      state.selectedReadersModes.forEach((e, i) => {
        if (e.DeviceName === updatedDevice.DeviceName) state.selectedReadersModes[i].Mode = updatedDevice.Mode;
      });
      debug(`updateSelectedReaderMode() state.selectedReadersModes is ${JSON.stringify(state.selectedReadersModes)}`);
    },
    updateInputDeviceMaskingGroups: (state, action: { type: string, payload: SilencioDevice }) => {
      debug(`updateInputDeviceMaskingGroups() action is ${JSON.stringify(action)}`);
      const updatedDevice = action.payload;
      state.inputs.forEach((e: SilencioDevice, i) => {
        if (e.DeviceName === updatedDevice.DeviceName) state.inputs[i].MaskingGroups = updatedDevice.MaskingGroups;
      });
      state.selectedInputs.forEach((e: SilencioDevice, i) => {
        if (e.DeviceName === updatedDevice.DeviceName) state.selectedInputs[i].MaskingGroups = updatedDevice.MaskingGroups;
      });
      debug(`updateInputDeviceMaskingGroups() state.inputs is ${JSON.stringify(state.inputs)}`);
    },
    updateReadersAlarmsDeviceMaskingGroups: (state, action: { type: string, payload: SilencioDevice }) => {
      debug(`updateReadersAlarmsDeviceMaskingGroups() action is ${JSON.stringify(action)}`);
      const updatedDevice = action.payload;
      state.readersAlarms.forEach((e: SilencioDevice, i) => {
        if (e.DeviceName === updatedDevice.DeviceName) state.readersAlarms[i].MaskingGroups = updatedDevice.MaskingGroups;
      });
      state.selectedReadersAlarms.forEach((e: SilencioDevice, i) => {
        if (e.DeviceName === updatedDevice.DeviceName) state.selectedReadersAlarms[i].MaskingGroups = updatedDevice.MaskingGroups;
      });
      debug(`updateReadersAlarmsDeviceMaskingGroups() state.readersAlarms is ${JSON.stringify(state.readersAlarms)}`);
    },
    updateReadersModesDeviceMaskingGroups: (state, action: { type: string, payload: SilencioDevice }) => {
      debug(`updateReadersModesDeviceMaskingGroups() action is ${JSON.stringify(action)}`);
      const updatedDevice = action.payload;
      state.readersModes.forEach((e: SilencioDevice, i) => {
        if (e.DeviceName === updatedDevice.DeviceName) state.readersModes[i].MaskingGroups = updatedDevice.MaskingGroups;
      });
      state.selectedReadersModes.forEach((e: SilencioDevice, i) => {
        if (e.DeviceName === updatedDevice.DeviceName) state.selectedReadersModes[i].MaskingGroups = updatedDevice.MaskingGroups;
      });
      debug(`updateReadersModesDeviceMaskingGroups() state.readersModes is ${JSON.stringify(state.readersModes)}`);
    }
  },
});

export const {
  setInputsDevices,
  setInputsMaskingGroups,
  setInputsSearch,
  setInputsSearchMaskingGroup,
  setLoadingDevices,
  setOutputsDevices,
  setOutputsSearch,
  setReadersAlarmsDevices,
  setReadersAlarmsMaskingGroups,
  setReadersAlarmsSearch,
  setReadersAlarmsSearchMaskingGroup,
  setReadersModesMaskingGroups,
  setReadersModesDevices,
  setReadersModesSearch,
  setReadersModesSearchMaskingGroup,
  setSelectedInputs,
  setSelectedOutputs,
  setSelectedReadersAlarms,
  setSelectedReadersModes,
  updateInputMasked,
  updateOutputStatus,
  updateReaderMasked,
  updateReaderMode,
  updateSelectedInputMask,
  updateSelectedOutputStatus,
  updateSelectedReaderMasked,
  updateSelectedReaderMode,
  updateInputDeviceMaskingGroups,
  updateReadersAlarmsDeviceMaskingGroups,
  updateReadersModesDeviceMaskingGroups,
} = devicesSlice.actions;

export const selectDevices = (state: any) => state.devices;
export const selectInputDevices = (state: any) => state.devices.inputs;
export const selectInputsMaskingGroups = (state: any) => state.devices.inputsMaskingGroups;
export const selectInputsSearch = (state: any) => state.devices.inputsSearch;
export const selectReadersAlarmsDevices = (state: any) => state.devices.readersAlarms;
export const selectReadersAlarmsMaskingGroups = (state: any) => state.devices.readersAlarmsMaskingGroups;
export const selectReadersAlarmsSearch = (state: any) => state.devices.readersAlarmsSearch;
export const selectReadersModesDevices = (state: any) => state.devices.readersModes;
export const selectReadersModesMaskingGroups = (state: any) => state.devices.readersModesMaskingGroups;
export const selectReadersModesSearch = (state: any) => state.devices.readersModesSearch;
export const selectSelectedInputs = (state: any) => state.devices.selectedInputs;
export const selectSelectedOutputs = (state: any) => state.devices.selectedOutputs;
export const selectSelectedReadersAlarms = (state: any) => state.devices.selectedReadersAlarms;
export const selectSelectedReadersModes = (state: any) => state.devices.selectedReadersModes;
export const selectLoadingDevices = (state: any) => state.devices.loadingDevices;
export const selectOutputDevices = (state: any) => state.devices.outputs;
export const selectOutputsSearch = (state: any) => state.devices.outputsSearch;

export default devicesSlice.reducer;