import { MaskingGroupTypes } from 'src/constants/Constants';

/**
 * Checks if a masking group name overlaps with a keyword.
 * 
 * @param value to test
 * @returns true if the name overlaps a keyword
 */
export const isMaskingGroupKeyword = (value: string | null | undefined): boolean => {
  const invalidNames = [
    'MASKING GROUPS',
    'ACTIONS',
    'RESET',
    'CREATE MASKING GROUP',
    'EDIT MASKING GROUP',
    'DELETE MASKING GROUP',
  ];
  
  return (value !== undefined && value !== null
    && value.length > 0
    && invalidNames.includes(value.toUpperCase()));
};

/**
 * Checks that a masking group name is valid by ensuring that it does not overlap with 
 * a masking group action, does not contain "MDF", and only uses valid characters.
 * 
 * @param value to test
 * @returns true if the name is valid
 */
export const isMaskingGroupNameValid = (value: string | null | undefined): boolean => {
  const regex: RegExp = new RegExp(/[^\w\s\(\)\.\/\-]|(MDF)/);
  return (value !== undefined && value !== null
    && value.length > 0
    && !isMaskingGroupKeyword(value)
    && !regex.test(value.toUpperCase()));
};

export const getMaskingGroupType = (deviceType: string): string => {
  switch (deviceType) {
    case 'inputs':
      return MaskingGroupTypes.Inputs;
    case 'readersAlarms':
      return MaskingGroupTypes.ReadersAlarms;
    case 'readersModes':
      return MaskingGroupTypes.ReadersModes;
    default:
      throw new Error(`Invalid deviceType: ${deviceType}`);
  }
}

export const formatMaskingGroupName = (input: string, siteCode: string): string => {
  // Allow to set name back to '' after an edit.
  if (input == '') {
    return '';
  }

  // Ensure name has site code and is capitalized.
  let name = input.toUpperCase();
  if (!name.startsWith(`${siteCode.toUpperCase()}-`)) {
    name = `${siteCode.toUpperCase()}-${name}`
  }
  return name;
}

export const defaultMaskingGroup = { label: 'Masking Groups', value: 'Masking Groups' };