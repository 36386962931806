import { EmailDomains, Modes } from '../constants/Constants';
import { SelectProps } from '@amzn/awsui-components-react';
export function debug(message: string) {
  (window.location.hostname !== 'silencio.gso.amazon.dev') && console.log(message);
}

export const filterModeOptions = (phySecMember: boolean | undefined, sideTeam: boolean | undefined, sigAdmin: boolean | undefined): SelectProps.Options => {
  const options = Modes.filter((m: SelectProps.Option) =>
    (phySecMember || sideTeam || sigAdmin || m.value !== 'Facility Code') &&
    m.value !== 'Unknown');
  return options;
};


export const getKeywordTranslationKey = (action: string | undefined) => {
  switch (action) {
    case 'Activated':
      return 'activated';
    case 'Deactivated':
      return 'deactivated';
    case 'Mask':
      return 'mask';
    case 'Unmask':
      return 'unmask';
    case 'Mask Door Forced Open':
      return 'mask-door-forced-open';
    case 'Unmask Door Forced Open':
      return 'unmask-door-forced-open';
    case 'Mask Door Held Open':
      return 'mask-door-held-open';
    case 'Unmask Door Held Open':
      return 'unmask-door-held-open';
    case 'Card and Pin':
      return 'card-and-pin';
    case 'Card Only':
      return 'card-only';
    case 'Facility Code':
    case 'Facility Code Only':
      return 'facility-code';
    case 'Locked':
      return 'locked';
    case 'Pin or Card':
      return 'pin-or-card';
    case 'Unlocked':
      return 'unlocked';
    default:
      return 'unknown';
  }
}

// Onboarded Silencio Beta and Gamma to PACSDataAPI Beta (https://sim.amazon.com/issues/SIDE-38912)
export const getPACSDataAPIStage = (stage: string) => {
  return (stage.toUpperCase() === 'TEST' || stage.toUpperCase() === 'GAMMA') ? 'BETA' : stage.toUpperCase();
}

export const getPropertyFilterQuery = (input: any) => {
  debug(`getPropertyFilterQuery() Attempting to format: ${input}`);
  const result: any = { tokens: [], operation: "and" };
  if (input == undefined || input == null || input == '') return result;
  try {
    const inputQuery = JSON.parse(input);
    if ('tokens' in inputQuery && 'operation' in inputQuery) {
      result.tokens = inputQuery.tokens;
      result.operation = inputQuery.operation;
    } else {
      debug(`getPropertyFilterQuery() Invalid query format. Using empty query.`);
    }
  } catch (err) {
    if (err instanceof SyntaxError && (typeof input === 'string')) {
      debug(`getPropertyFilterQuery() Input not JSON, using input as string query.`);
      result.tokens = [{ "operator": ":", "value": input }]
    } else {
      debug(`getPropertyFilterQuery() Unhandled Error: ${err}. Using empty query.`);
    }
  }
  return result;
}

export function getTimeString(timestamp: number) {
  const date = new Date(timestamp);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}

export const i18nFilterStrings = (bundle: { getMessage: (id: string) => string; formatMessage: (id: string, ...args: any) => string; }): any => {
  return {
    filteringAriaLabel: bundle.getMessage('filter-devices'),
    filteringPlaceholder: bundle.getMessage('filter-devices'),
    dismissAriaLabel: bundle.getMessage('dismiss'),
    groupValuesText: bundle.getMessage('values'),
    groupPropertiesText: bundle.getMessage('properties'),
    operatorsText: bundle.getMessage('operators'),
    operationAndText: bundle.getMessage('and'),
    operationOrText: bundle.getMessage('or'),
    operatorLessText: bundle.getMessage('less-than'),
    operatorLessOrEqualText: bundle.getMessage('less-than-or-equal'),
    operatorGreaterText: bundle.getMessage('greater-than'),
    operatorGreaterOrEqualText: bundle.getMessage('greater-than-or-equal'),
    operatorContainsText: bundle.getMessage('contains'),
    operatorDoesNotContainText: bundle.getMessage('does-not-contain'),
    operatorEqualsText: bundle.getMessage('equals'),
    operatorDoesNotEqualText: bundle.getMessage('does-not-equal'),
    editTokenHeader: bundle.getMessage('edit-filter'),
    propertyText: bundle.getMessage('property'),
    operatorText: bundle.getMessage('operator'),
    valueText: bundle.getMessage('value'),
    cancelActionText: bundle.getMessage('cancel'),
    applyActionText: bundle.getMessage('apply'),
    allPropertiesLabel: bundle.getMessage('all-properties'),
    tokenLimitShowMore: bundle.getMessage('show-more'),
    tokenLimitShowFewer: bundle.getMessage('show-fewer'),
    clearFiltersText: bundle.getMessage('clear-filters'),
    removeTokenButtonAriaLabel: () => bundle.getMessage('remove-token'),
    enteredTextLabel: (text: any) => bundle.formatMessage('use-text', { textString: text })
  }
};

export function validEmailAddresses(emailAddresses: string): boolean {
  debug(`ChangeStatus() validEmailAddresses() emailAddresses is ${emailAddresses}`);
  const regex: RegExp = /^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([,.][\s]*(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/;
  const regex_result = regex.test(emailAddresses);
  debug(`ChangeStatus() validEmailAddresses() regex_result is ${regex_result}`);
  if (!regex_result) return false;
  const emailsList = emailAddresses.split(',');
  for (let email of emailsList) {
    debug(`ChangeStatus() validEmailAddresses() email is ${email}`);
    const domain = email.split('@')[1];
    debug(`ChangeStatus() validEmailAddresses() domain is ${domain}`);
    if (!EmailDomains.includes(domain)) return false;
  }
  return regex_result;
}

export const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};