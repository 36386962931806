import { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter';
import React from 'react';
import { ScheduleRow } from '../../utils/SilencioTypes';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import TranslateText from '../TranslateText';
import { getKeywordTranslationKey } from 'src/utils/commonUtils';

export const ColumnDefinitions: TableProps.ColumnDefinition<ScheduleRow>[] = [
    {
        id: 'Action',
        header: <TranslateText translateKey={'action'} />,
        cell: (item: ScheduleRow) => <TranslateText translateKey={getKeywordTranslationKey(item.Action)} />,
        sortingField: 'Action'
    },
    {
        id: 'EntityName',
        header: <TranslateText translateKey={'name'} />,
        cell: (item: ScheduleRow) => item.EntityName,
        sortingField: 'EntityName'
    },
    {
        id: 'PairedReader',
        header: <TranslateText translateKey={'paired-reader'} />,
        cell: (item: ScheduleRow) => item.PairedReader,
        sortingField: 'PairedReader'
    },
    {
        id: 'FormattedScheduleExpression',
        header: <TranslateText translateKey={'schedule-expression'} />,
        cell: (item: ScheduleRow) => item.FormattedScheduleExpression,
        sortingField: 'FormattedScheduleExpression'
    },
    {
        id: 'ScheduleName',
        header: <TranslateText translateKey={'schedule-name'} />,
        cell: (item: ScheduleRow) => item.Name,
        sortingField: 'Name'
    },
    {
        id: 'CreationDate',
        header: <TranslateText translateKey={'creation-date'} />,
        cell: (item: ScheduleRow) => item.CreationDate,
        sortingField: 'CreationDate'
    },
    {
        id: 'LastInvocationResult',
        header: <TranslateText translateKey={'last-invocation-result'} />,
        cell: (item: ScheduleRow) => item.LastInvocationResult,
        sortingField: 'LastInvocationResult'
    },
    {
        id: 'LastInvocationRequestId',
        header: <TranslateText translateKey={'last-invocation-request-id'} />,
        cell: (item: ScheduleRow) => item.LastInvocationRequestId,
        sortingField: 'LastInvocationRequestId'
    },
    {
        id: 'LastInvocationHttpStatus',
        header: <TranslateText translateKey={'last-invocation-http-status'} />,
        cell: (item: ScheduleRow) => item.LastInvocationHttpStatus,
        sortingField: 'LastInvocationHttpStatus'
    },
    {
        id: 'LastInvocationTimestamp',
        header: <TranslateText translateKey={'last-invocation-timestamp'} />,
        cell: (item: ScheduleRow) => {
            if (item.LastInvocationTimestamp) {
                const timestamp = new Date(item.LastInvocationTimestamp);
                return timestamp.toISOString();
            } else {
                return null;
            }
        } ,
        sortingField: 'LastInvocationTimestamp'
    }
];

export const ScheduleFilteringProperties: PropertyFilterProps.FilteringProperty[] = [
    {
        key: 'Action',
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: 'Schedule Action',
        groupValuesLabel: 'Schedule Actions'
    },
    {
        key: 'EntityName',
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: 'Name',
        groupValuesLabel: 'Names'
    },
    {
        key: 'FormattedScheduleExpression',
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: 'Schedule Expression',
        groupValuesLabel: 'Schedule Expressions'
    },
    {
        key: 'Name',
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: 'Schedule Name',
        groupValuesLabel: 'Schedule Names'
    },
    {
        key: 'CreationDate',
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: 'Creation Date',
        groupValuesLabel: 'Creation Dates'
    }
];

export const ScheduleFilteringOptions = [
    { propertyKey: 'Action', value: 'Mask' },
    { propertyKey: 'Action', value: 'Unmask' },
    { propertyKey: 'Action', value: 'Mask Door Forced Open' },
    { propertyKey: 'Action', value: 'Unmask Door Forced Open' },
    { propertyKey: 'Action', value: 'Mask Door Held Open' },
    { propertyKey: 'Action', value: 'Unmask Door Held Open' },
    { propertyKey: 'Action', value: 'Card and Pin' },
    { propertyKey: 'Action', value: 'Card Only' },
    { propertyKey: 'Action', value: 'Facility Code' },
    { propertyKey: 'Action', value: 'Locked' },
    { propertyKey: 'Action', value: 'Pin or Card' },
    { propertyKey: 'Action', value: 'Unlocked' }
];

