import { DeviceSources, DeviceTypeTabs, OnguardDelay, OnguardReaderMaskActionTypes } from 'src/constants/Constants';
import { TemplateActions, TemplateActionBatchSize } from '../../constants/Constants';
import { debug, wait } from '../../utils/commonUtils';
import {
    setReaderMode,
    setInputMask,
    setOnguardReaderMasking,
    setReaderDoorForcedMask,
    setReaderDoorHeldMask,
} from 'src/utils/DevicesUtils';
import { DeviceMutationResponseInterface } from '../../utils/SilencioTypes';
import { SilencioDevice } from 'src/utils/SilencioTypes';

export const performActionOnDevices = async (
    deviceSource: string,
    devices: SilencioDevice[],
    deviceType: DeviceTypeTabs,
    action: TemplateActions,
    requestedBy: string,
    siteName: string,
    siteRegion: string): Promise<DeviceMutationResponseInterface> => {
    debug(`performActionOnDevices() performing ${action} action on ${devices.length} devices.`);

    const batchPromises: Promise<DeviceMutationResponseInterface>[] = [];

    for (let index = 0; index < devices.length; index += TemplateActionBatchSize) {
        const deviceBatch: SilencioDevice[] = devices.slice(index, index + TemplateActionBatchSize);

        // Throttle concurrent calls
        if (index%(10*TemplateActionBatchSize) === 0) {
            await wait(1000);
        }

        const deviceSummaries: { deviceName: string, deviceHref: string | undefined }[] = [];
        deviceBatch.forEach(device => deviceSummaries.push({
            deviceName: device.DeviceName!,
            deviceHref: device.device_href ?? undefined,
        }));
        debug(`performActionOnDevices() performing action on deviceBatch: ${JSON.stringify(deviceSummaries)}`);
        switch (deviceType) {
            case DeviceTypeTabs.inputs:
                batchPromises.push(setInputMask(
                    deviceSource,
                    deviceSummaries,
                    '',
                    (action === TemplateActions.Mask),
                    0,
                    requestedBy,
                    siteName,
                    siteRegion,
                ));
                break;
            case DeviceTypeTabs.readersAlarms:
                const onguardSite: boolean = deviceSource === DeviceSources.ONGUARD;
                const doorForcedAction: boolean = (action === TemplateActions.MaskDoorForcedOpen || action === TemplateActions.UnmaskDoorForcedOpen);
                const masked: boolean = (action === TemplateActions.MaskDoorForcedOpen || action === TemplateActions.MaskDoorHeldOpen);
                if (onguardSite) {
                    batchPromises.push(setOnguardReaderMasking(
                        siteRegion,
                        deviceBatch,
                        doorForcedAction ? OnguardReaderMaskActionTypes.doorForcedOpen : OnguardReaderMaskActionTypes.doorHeldOpen,
                        masked,
                        requestedBy,
                        OnguardDelay,
                    ));
                } else {
                    if (doorForcedAction) {
                        batchPromises.push(setReaderDoorForcedMask(
                            deviceSource,
                            deviceSummaries,
                            '',
                            masked,
                            0,
                            requestedBy,
                            siteName,
                            siteRegion,
                        ));
                    } else {
                        batchPromises.push(setReaderDoorHeldMask(
                            deviceSource,
                            deviceSummaries,
                            '',
                            masked,
                            0,
                            requestedBy,
                            siteName,
                            siteRegion,
                        ));
                    }
                }
                break;
            default:
                batchPromises.push(setReaderMode(
                    deviceSource,
                    deviceSummaries,
                    '',
                    action,
                    0,
                    requestedBy,
                    siteName,
                    siteRegion,
                ));
        }
    }

    let result: DeviceMutationResponseInterface = {
        devices: [],
        error: null,
        requestUUID: null,
        status: 200
    };

    let rejectedCount: number = 0;
    const batchResults: PromiseSettledResult<DeviceMutationResponseInterface>[] = await Promise.allSettled(batchPromises);
    // Merge batch results into one result with an unsuccessful status if any are unsuccessful
    batchResults.forEach(response => {
        if (response.status == "fulfilled") {
            const batchResult: DeviceMutationResponseInterface = response.value;
            debug(`performActionOnDevices() deviceBatch result: ${JSON.stringify(batchResult)}`);
            result.devices.push(...batchResult.devices);
            if (batchResult.status && result.status && batchResult.status > result.status) {
                result.status = batchResult.status;
            }
        } else {
            rejectedCount++;
        }
    });

    if (rejectedCount > 0) {
        result.error = `${rejectedCount} batch call(s) rejected.`;
        result.status = 500;
    }

    debug(`performActionOnDevices() result: ${JSON.stringify(result)}`);
    return result;
}