import React from 'react';
import { Alert, Button, Input, Modal, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import { updateUserPrefs } from '../utils/UserPrefsUtils';
import { debug } from 'src/utils/commonUtils';
import { useBundle } from '@amzn/react-arb-tools';

const checkSearchName = (value: string): string => {
  const invalidSearchNames = ['Reset', 'Save Search', 'Saved Searches'];
  if (invalidSearchNames.includes(value)) return '';
  return value;
};

export default function SaveSearch(props: any) {
  debug(`SaveSearch(): props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.Common');

  const [searchName, setSearchName] = React.useState(checkSearchName(props.searchName));
  const [showInvalidName, setShowInvalidName] = React.useState(false);

  const saveOnClickHandler = () => {
    if (checkSearchName(searchName) === '') {
      setShowInvalidName(true);
      return;
    }
    props.setShowSaveSearchCallback(false);
    let nodeName = '';
    let typeName = '';
    const newSearch: any = {
      name: searchName,
      filterText: props.filteringText,
    };
    switch (props.devicesType) {
      case 'inputs':
        nodeName = 'inputs';
        typeName = 'InputsPrefs';
        newSearch.maskStatus = props.filterInputsStatus;
        break;
      case 'outputs':
        nodeName = 'outputs';
        typeName = 'OutputsPrefs';
        newSearch.outputStatus = props.filterOutputsStatus;
        break;
      case 'readersAlarms':
        nodeName = 'readersAlarms';
        typeName = 'ReadersAlarmsPrefs';
        newSearch.maskStatus = props.filterInputsStatus;
        break;
      case 'readersModes':
        nodeName = 'readersModes';
        typeName = 'ReadersModesPrefs';
        newSearch.modes = props.filterReadersModes.map((m: { label: string, value: string }) => m.value).filter((v: string) => v !== null);
        break;
    }
    const nodePrefs = props.userPrefs[nodeName] || {};
    const savedSearches = nodePrefs.savedSearches || [];

    updateUserPrefs(
      {
        ...props.userPrefs,
        [nodeName]: {
          ...nodePrefs,
          __typename: typeName,
          savedSearches: [
            ...savedSearches.filter((ss: any) => ss.name !== searchName),
            newSearch
          ]
        }
      });
    props.savedSearchCallback(searchName);
  };

  if (isBundleLoading) return <Spinner />;

  return (
    <Modal
      header={bundle.getMessage('save-search')}
      visible={props.showSaveSearch}
      onDismiss={() => props.setShowSaveSearchCallback(false)}
    >
      <SpaceBetween direction='vertical' size={'l'}>
        <Input
          type='text'
          value={searchName}
          onChange={event => setSearchName(event.detail.value)}
          placeholder={bundle.getMessage('enter-search-name')}
          ariaDescribedby={undefined}
          autoFocus={true}
        />
        <Button
          variant='primary'
          onClick={saveOnClickHandler}
          disabled={!searchName}
          ariaLabel={bundle.getMessage('select-search')}
        >
          {bundle.getMessage('save')}
        </Button>
        <Alert
          dismissible={true}
          type='error'
          visible={showInvalidName}
          onDismiss={() => setShowInvalidName(false)}
        >
          {bundle.getMessage('invalid-name')}
        </Alert>
      </SpaceBetween>
    </Modal>
  );
}