import React from 'react';
import { Spinner } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';

interface ITranslateText {
  formatInput?: string;
  translateKey: string;
}

export default function TranslateText(props: ITranslateText) {
  const [bundle, isBundleLoading] = useBundle('components.Common');

  if (isBundleLoading) return <Spinner/>

  if (props.formatInput !== undefined) {
    return <>{bundle.formatMessage(props.translateKey, { input: props.formatInput })}</>;
  } else {
    return <>{bundle.getMessage(props.translateKey)}</>;
  }
}