import { debug, getPropertyFilterQuery, i18nFilterStrings } from '../utils/commonUtils';
import { DeviceTypes, EmptyPropertyFilterQuery, SilencioDeviceFilteringProperties } from 'src/constants/Constants';
import { PropertyFilter, Select, SelectProps, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import React, { useEffect } from 'react';
import { selectUserPrefs as selectUserPrefsInStore, setSavedSearches as setSavedSearchesInStore } from '../stores/slices/userSlice';
import { setOutputsSearch, selectOutputsSearch, Search } from 'src/stores/slices/devicesSlice';
import { useDispatch, useSelector } from 'react-redux';
import DeleteSearch from './DeleteSearch';
import SaveSearch from './SaveSearch';
import { useBundle } from '@amzn/react-arb-tools';

interface OutputsFilterInterface {
  filteredItemsCount: any;
  resetSelectedItemsCallback: Function;
  setFilterCallback: Function;
}

export default function OutputsFilter(props: OutputsFilterInterface) {
  debug(`OutputsFilter() props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.Common');

  const dispatch = useDispatch();

  const selectUserPrefs = useSelector(selectUserPrefsInStore);
  const [userPrefs, setUserPrefs] = React.useState(selectUserPrefs);

  const outputsSearch = useSelector(selectOutputsSearch) as Search;
  debug(`OutputsFilter() outputsSearch is ${JSON.stringify(outputsSearch)}`);

  const [query, setQuery] = React.useState<any>(outputsSearch?.query || EmptyPropertyFilterQuery);

  const [filterOutputsStatus, setFilterOutputsStatus] = React.useState<any>(
    outputsSearch?.outputStatus ||
    {
      label: 'Any Status',
      value: 'Any Status'
    });

  const [selectedOutputsSavedSearch, setSelectedOutputsSavedSearch] = React.useState<{ label: string, value: string }>(
    outputsSearch?.savedSearch ||
    {
      label: 'Saved Searches',
      value: 'Saved Searches'
    });

  const [showSaveSearch, setShowSaveSearch] = React.useState(false);
  const [showDeleteSearch, setShowDeleteSearch] = React.useState(false);

  const [savedSearchesOptions, setSavedSearchesOptions] = React.useState<SelectProps.Options>();

  const resetOutputsFilters = () => {
    debug(`OutputsFilter() resetOutputsFilters()`);
    setQuery(EmptyPropertyFilterQuery);
    setFilterOutputsStatus({ label: 'Any Status', value: 'Any Status' });
    setSelectedOutputsSavedSearch({ label: 'Saved Searches', value: 'Saved Searches' });
    props.setFilterCallback(EmptyPropertyFilterQuery);
    props.resetSelectedItemsCallback();
    dispatch(setOutputsSearch(
      {
        maskingGroup: undefined,
        maskStatus: undefined,
        modes: undefined,
        outputStatus: { label: 'Any Status', value: 'Any Status' },
        savedSearch: undefined,
        query: EmptyPropertyFilterQuery
      }
    ));
  };

  const setFilterOutputsStatusOnChangeHandler = (detail: SelectProps.ChangeDetail) => {
    debug(`OutputsFilter() setFilterOutputsStatusOnChangeHandler()`);
    setFilterOutputsStatus(detail.selectedOption);
    props.resetSelectedItemsCallback();
  };

  const savedSearch = (searchName: string) => {
    debug(`OutputsFilter() savedSearch() searchName is ${searchName}`);
    let savedSearches = userPrefs.outputs?.savedSearches || [];
    debug(`OutputsFilter() savedSearch() savedSearches is ${JSON.stringify(savedSearches)}`);
    const newSavedOption = { label: searchName, value: searchName };
    debug(`OutputsFilter() savedSearch() newSavedOption is ${JSON.stringify(newSavedOption)}`);
    setSelectedOutputsSavedSearch(newSavedOption);
    dispatch(setSavedSearchesInStore(
      {
        devicesType: 'outputs',
        savedSearches: [
          ...savedSearches,
          {
            name: searchName,
            filterText: JSON.stringify(query),
            outputStatus: filterOutputsStatus.value
          }
        ]
      }));
  };

  const deletedSearch = (searchName: string) => {
    debug(`deletedSearch() searchName is ${searchName}`);
    let savedSearches = userPrefs.outputs?.savedSearches || [];
    debug(`deletedSearch() savedSearches is ${JSON.stringify(savedSearches)}`);
    let filteredSearches = savedSearches.filter((ss: { name: string; filterText: string; maskStatus: string; }) => ss.name !== searchName);
    debug(`deletedSearch() filteredSearches is ${JSON.stringify(filteredSearches)}`);
    resetOutputsFilters();
    dispatch(setSavedSearchesInStore(
      {
        devicesType: 'outputs',
        savedSearches: filteredSearches
      }));
  };

  const savedSearchesOnChange = (detail: SelectProps.ChangeDetail) => {
    const value = detail.selectedOption.value;
    const label = detail.selectedOption.label;
    const chosenSavedSearch = { label: label!, value: value! };
    debug(`OutputsFilter() savedSearchesOnChange() chosenSavedSearch is ${chosenSavedSearch}`);
    const actions = ['Reset', 'Save Search', 'Delete Search'];

    if (value === 'Reset') resetOutputsFilters();
    if (value === 'Save Search') setShowSaveSearch(true);
    if (value === 'Delete Search') setShowDeleteSearch(true);
    if (!actions.includes(value!)) {
      setSelectedOutputsSavedSearch(chosenSavedSearch);
      const savedSearch = userPrefs.outputs.savedSearches.find(
        (ss: { name: string, filterText: string, outputStatus: string }) => {
          return ss.name === label;
        }
      );
      debug(`OutputsFilter() savedSearchesOnChange() savedSearch is ${JSON.stringify(savedSearch)}`);
      const savedQuery = getPropertyFilterQuery(savedSearch.filterText);
      debug(`OutputsFilter() savedSearchesOnChange() savedQuery is ${savedQuery}`);
      props.resetSelectedItemsCallback();
      dispatch(setOutputsSearch(
        {
          maskingGroup: undefined,
          maskStatus: undefined,
          modes: undefined,
          outputStatus: savedSearch.outputStatus,
          savedSearch: chosenSavedSearch,
          query: savedQuery
        }
      ));
      setFilterOutputsStatus({ label: savedSearch.outputStatus, value: savedSearch.outputStatus });
      setQuery(savedQuery)
      props.setFilterCallback(savedQuery);
    }
  };

  useEffect(() => {
    debug(`OutputsFilter() useEffect() [selectUserPrefs]`)
    const updatedUserPrefs = selectUserPrefs;
    debug(`OutputsFilter() useEffect() updatedUserPrefs is ${JSON.stringify(updatedUserPrefs)}`);

    let options = [
      {
        label: 'Actions',
        options: [
          { label: 'Reset', value: 'Reset', iconName: 'undo' },
          { label: 'Save Search', value: 'Save Search', iconName: 'file' },
          { label: 'Delete Search', value: 'Delete Search', iconName: 'close' }
        ]
      },
      {
        label: 'Saved Searches',
        options: updatedUserPrefs.outputs ? updatedUserPrefs.outputs.savedSearches.map(
          (ss: { name: string, filterText: string, maskStatus: string, modes: [], outputStatus: string }) => {
            return { label: ss.name, value: ss.name }
          }
        ).sort() : []
      }
    ];
    debug(`OutputsFilter() useEffect() options is ${JSON.stringify(options)}`);
    setSavedSearchesOptions(options);
    setUserPrefs(updatedUserPrefs);
  }, [selectUserPrefs])

  useEffect(() => {
    debug(`OutputsFilter() useEffect() [query] query is ${query}}`);
    dispatch(setOutputsSearch(
      {
        ...outputsSearch,
        query: query
      }
    ));
    props.setFilterCallback(query);
  }, [query]);

  useEffect(() => {
    debug(`OutputsFilter() useEffect() [filterOutputsStatus] filterOutputsStatus is ${JSON.stringify(filterOutputsStatus)}`);
    dispatch(setOutputsSearch(
      {
        ...outputsSearch,
        outputStatus: filterOutputsStatus
      }
    ));
  }, [filterOutputsStatus]);

  if (isBundleLoading) return <Spinner />;

  return (
    <>
      <SpaceBetween size='xs' direction='horizontal'>
        <PropertyFilter
          countText={bundle.formatMessage('filter-matches', { count: props.filteredItemsCount === undefined ? 0 : props.filteredItemsCount})}
          expandToViewport
          filteringOptions={
            DeviceTypes.Outputs.map((type: string) => { return { propertyKey: "Device_Type", value: type } })
          }
          filteringProperties={SilencioDeviceFilteringProperties}
          i18nStrings={i18nFilterStrings(bundle)}
          onChange={({ detail }) => setQuery(detail)}
          query={query}
        />
        <Select
          onChange={({ detail }) => setFilterOutputsStatusOnChangeHandler(detail)}
          options={[
            { label: 'Any Status', value: 'Any Status' },
            { label: 'Activated', value: 'Activated' },
            { label: 'Deactivated', value: 'Deactivated' },
            { label: '-------------', value: 'Separator', disabled: true },
            { label: 'Unknown', value: 'Unknown' },
          ]}
          selectedAriaLabel={bundle.getMessage('selected')}
          selectedOption={filterOutputsStatus}
          expandToViewport
        />
        <Select
          onChange={({ detail }) => { savedSearchesOnChange(detail) }}
          options={savedSearchesOptions}
          selectedAriaLabel={bundle.getMessage('selected-saved-search')}
          selectedOption={selectedOutputsSavedSearch}
          expandToViewport
        />
      </SpaceBetween>
      {showSaveSearch &&
        <SaveSearch
          showSaveSearch={showSaveSearch}
          setShowSaveSearchCallback={setShowSaveSearch}
          userPrefs={userPrefs}
          searchName={selectedOutputsSavedSearch.label}
          filteringText={JSON.stringify(query)}
          filterOutputsStatus={filterOutputsStatus.value}
          savedSearchCallback={savedSearch}
          devicesType='outputs'
        />
      }
      {showDeleteSearch &&
        <DeleteSearch
          showDeleteSearch={showDeleteSearch}
          setShowDeleteSearchCallback={setShowDeleteSearch}
          userPrefs={userPrefs}
          searchName={selectedOutputsSavedSearch.label}
          deletedSearchCallback={deletedSearch}
          devicesType='outputs'
        />
      }
    </>);
}