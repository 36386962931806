import * as APIt from '../../API';
import {
    Alert,
    Box,
    Button,
    DatePicker,
    Icon,
    Input,
    Modal,
    Select,
    SelectProps,
    SpaceBetween,
    Spinner,
    TextContent,
    TimeInput,
    Toggle,
} from '@amzn/awsui-components-react';
import {
    DeviceSources,
    MaskingGroupTypes,
    Modes,
    OnguardReaderMaskActionTypes,
    Paths,
    ScheduleDurationLimit,
    ScheduleState,
} from 'src/constants/Constants';
import { MaskingGroupInfo, ScheduleRow, SilencioDevice } from 'src/utils/SilencioTypes';
import { debug, getPACSDataAPIStage } from 'src/utils/commonUtils';
import {
    formatScheduleExpression,
    getActionOptions,
    isScheduleExpressionInPast,
    performForScheduleInitialAction,
} from './utils';
import {
    selectInputDevices,
    selectInputsMaskingGroups,
    selectReadersAlarmsDevices,
    selectReadersAlarmsMaskingGroups,
    selectReadersModesDevices,
    selectReadersModesMaskingGroups,
} from 'src/stores/slices/devicesSlice';
import { selectSchedules, setSchedules } from 'src/stores/slices/schedulesSlice';
import { selectUsername, selectUserSelectedSite } from 'src/stores/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import React from 'react';
import { setSchedule } from 'src/utils/SchedulesUtils';
import { TimeZoneConverter } from 'adaptive-expressions';
import { useBundle } from '@amzn/react-arb-tools';

const MASKING_GROUP_OPTION_PREFIX = "MASKING_GROUP_";

export interface ICreateScheduleProps {
    refreshDevicesCallback: Function;
    showModalCallback: Function;
    visible: boolean;
}

export default function CreateSchedule(props: ICreateScheduleProps) {
    debug(`CreateSchedule() props is ${JSON.stringify(props)}`);

    const [bundle, isBundleLoading] = useBundle('components.schedules.Schedules');
    const [commonBundle, isCommonBundleLoading] = useBundle('components.Common');

    const dispatch = useDispatch();

    const [autoDelete, setAutoDelete] = React.useState<boolean>(false);
    const [dateValue, setDateValue] = React.useState<string>('');
    const [dayOfWeek, setDayOfWeek] = React.useState<SelectProps.Option | null>(null);
    const [deviceType, setDeviceType] = React.useState<string>('inputs');
    const [duration, setDuration] = React.useState<number>(1);
    const [durationUnit, setDurationUnit] = React.useState<SelectProps.Option | null>(null);
    const [enabled, setEnabled] = React.useState<boolean>(true);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [modeAfterDuration, setModeAfterDuration] = React.useState<SelectProps.Option | null>(null);
    const [selectedAction, setSelectedAction] = React.useState<SelectProps.Option>({ label: 'Mask', value: 'Mask' });
    const [selectedDevice, setSelectedDevice] = React.useState<SilencioDevice | null>(null);
    const [selectedMaskingGroup, setSelectedMaskingGroup] = React.useState<string | null>(null);
    const [selectedScheduleType, setSelectedScheduleType] = React.useState<SelectProps.Option | null>(null);
    const [timeValue, setTimeValue] = React.useState<string>('');
    const [timezone, setTimezone] = React.useState<string | null>(null);

    const inputDevices = useSelector(selectInputDevices) as SilencioDevice[];
    const inputsMaskingGroups = useSelector(selectInputsMaskingGroups) as MaskingGroupInfo[];
    const readersAlarmsDevices = useSelector(selectReadersAlarmsDevices) as SilencioDevice[];
    const readersAlarmsMaskingGroups = useSelector(selectReadersAlarmsMaskingGroups) as MaskingGroupInfo[];
    const readersModesDevices = useSelector(selectReadersModesDevices) as SilencioDevice[];
    const readersModesMaskingGroups = useSelector(selectReadersModesMaskingGroups) as MaskingGroupInfo[];

    const schedules = useSelector(selectSchedules) as ScheduleRow[];
    const username = useSelector(selectUsername);
    const userSelectedSite = useSelector(selectUserSelectedSite);

    const checkDateEnabled = (date: Date): boolean => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const sixMonthsFromNow = new Date();
        sixMonthsFromNow.setMonth(today.getMonth() + 6);
        return date >= today && date <= sixMonthsFromNow;
    }

    const checkSaveDisabled = (): boolean => {
        if ((selectedDevice === null && selectedMaskingGroup === null) || selectedScheduleType === null || loading) {
            return true;
        }
        switch (selectedScheduleType.value) {
            case 'At':
                return (timeValue === '' || dateValue === '');
            case 'Every':
                return (timeValue === '' || dayOfWeek === null);
            case 'For':
                return (duration < 1 || durationUnit == null || (deviceType == 'readersModes' && selectedMaskingGroup !== null && !modeAfterDuration?.value));
            default:
                return true;
        }
    }

    const durationOnChange = (input: string): void => {
        const parsed = parseInt(input, 10);
        if (isNaN(parsed) || parsed < 1) {
            setErrorMessage('Please specify a numerical value greater than 0.');
        } else {
            let unitValue = parseInt(durationUnit?.value!, 10)
            if (parsed * unitValue > ScheduleDurationLimit) {
                setErrorMessage('Please specify a duration less than 30 days.');
            } else {
                setErrorMessage(null);
                setDuration(parsed);
            }
        }
    }

    const durationUnitOnChange = (detail: SelectProps.ChangeDetail): void => {
        let unitValue = parseInt(detail.selectedOption.value!, 10)
        if (isNaN(unitValue)) throw new Error('CreateSchedule() durationUnitOnChange() invalid duration unit.');
        if (duration * unitValue > ScheduleDurationLimit) {
            setErrorMessage('Please specify a duration less than 30 days.');
        } else {
            setErrorMessage(null);
            setDurationUnit({ value: detail.selectedOption.value, label: detail.selectedOption.label })
        }
    }

    const formatFooterMessage = (): string => {
        let onguardReadersAlarms = false;
        let message = '';
        let name;

        if (selectedDevice) {
            name = selectedDevice.DeviceName;
            if (deviceType === 'readersAlarms' && selectedDevice.devicesource === DeviceSources.ONGUARD) {
                onguardReadersAlarms = true;
            }
        } else if (selectedMaskingGroup) {
            name = selectedMaskingGroup;
            if (deviceType === 'readersAlarms') {
                const devices = getDevices();
                if (devices.length > 0 && devices[0].devicesource === DeviceSources.ONGUARD) onguardReadersAlarms = true;
            }
        }

        if (selectedScheduleType?.value === 'For') {
            let actionMessage: string;
            switch (deviceType) {
                case 'inputs':
                    actionMessage = selectedAction.label!;
                    break;
                case 'readersAlarms':
                    actionMessage = `${selectedAction.label!} on`;
                    break;
                default:
                    actionMessage = `set ${selectedAction.label!} mode to`;
                    break;
            }
            message += `This will ${actionMessage} ${name} ${onguardReadersAlarms ? '2 minutes after' : 'upon'} saving and will create a schedule to set it back at the end of the chosen time.\n`;
        }
        message += `The schedule may take up to ${onguardReadersAlarms ? '3 minutes' : '1 minute'} after the chosen time to apply.`;
        return message;
    }

    const getDevices = (): SilencioDevice[] => {
        switch (deviceType) {
            case 'inputs':
                return inputDevices;
            case 'readersAlarms':
                return readersAlarmsDevices;
            default:
                return readersModesDevices;
        }
    }

    const getEntityOptions = (): SelectProps.Option[] => {
        const devices = getDevices();
        const maskingGroups = getMaskingGroups();

        debug(`CreateSchedule() getDeviceOptions() deviceType is ${deviceType}`);
        let options = [
            {
                label: 'Masking Groups',
                options: maskingGroups.map(maskingGroup => ({ label: maskingGroup.name, value: `${MASKING_GROUP_OPTION_PREFIX}${maskingGroup.name}` }))
            },
            {
                label: 'Devices',
                options: devices.map(device => {
                    if (device.PairedReader) {
                        return { label: device.DeviceName!, tags: ['Paired Reader', device.PairedReader], value: device.DeviceName! }
                    }
                    return { label: device.DeviceName!, value: device.DeviceName! }
                })
            }
        ];
        debug(`CreateSchedule() getDeviceOptions() options are: ${JSON.stringify(options)}`);
        return options;
    }

    const getMaskingGroups = (): MaskingGroupInfo[] => {
        switch (deviceType) {
            case 'inputs':
                return inputsMaskingGroups;
            case 'readersAlarms':
                return readersAlarmsMaskingGroups;
            default:
                return readersModesMaskingGroups;
        }
    };

    // https://docs.aws.amazon.com/scheduler/latest/UserGuide/schedule-types.html
    const getScheduleExpression = (): string => {
        if (checkSaveDisabled()) {
            throw new Error('CreateSchedule() getScheduleExpression() Invalid state to calculate expression');
        }
        let scheduleExpression: string | null = null;
        switch (selectedScheduleType?.value) {
            case 'At':
                debug(`CreateSchedule() getScheduleExpression() "At" inputs are date value: ${dateValue}, time value: ${timeValue}`);
                //at(yyyy-mm-ddThh:mm:ss)
                scheduleExpression = `at(${dateValue}T${timeValue}:00)`;
                break;
            case 'Every':
                debug(`CreateSchedule() getScheduleExpression() "Every" inputs are time value: ${timeValue}, dayOfWeek: ${dayOfWeek?.label}`);
                const [hours, minutes] = timeValue.split(':');
                //cron(minutes hours day_of_month month day_of_week year)
                scheduleExpression = `cron(${minutes} ${hours} ? * ${dayOfWeek?.value} *)`;
                break;
            case 'For':
                debug(`CreateSchedule() getScheduleExpression() "For" inputs are duration: ${duration}, duration unit: ${durationUnit?.label}`);
                if (!timezone) {
                    throw new Error('CreateSchedule() getScheduleExpression() timezone is required');
                }
                const now = DateTime.now().setZone(timezone);
                let unitValue = parseInt(durationUnit?.value!, 10)
                if (isNaN(unitValue)) throw new Error('CreateSchedule() getScheduleExpression() invalid duration unit.');
                const result = now.plus({ milliseconds: (duration * unitValue) });
                scheduleExpression = `at(${result.toFormat("yyyy-MM-dd'T'HH:mm:ss")})`;
                break;
            default:
                throw new Error('CreateSchedule() getScheduleExpression() invalid schedule type.');
        }
        if (!scheduleExpression) {
            throw new Error('CreateSchedule() getScheduleExpression() scheduleExpression was not calculated');
        } else {
            debug(`CreateSchedule() getScheduleExpression() "${selectedScheduleType?.value}" expression is: ${scheduleExpression}`);
            return scheduleExpression as string;
        }
    }

    const getScheduleMasked = (isForSchedule: boolean): boolean | undefined => {
        switch (selectedAction.value) {
            case 'Mask':
            case 'Mask Door Forced Open':
            case 'Mask Door Held Open':
                return isForSchedule ? false : true;
            case 'Unmask':
            case 'Unmask Door Forced Open':
            case 'Unmask Door Held Open':
                return isForSchedule ? true : false;
            default:
                return undefined;
        }
    }

    const getScheduleMode = (): string | undefined => {
        switch (selectedAction.value) {
            case 'Card and Pin':
            case 'Card Only':
            case 'Locked':
            case 'Pin or Card':
            case 'Unlocked':
            case 'Unknown':
                return selectedAction.value;
            default:
                return undefined;
        }
    }

    const getSchedulePath = (isOnguardReaderMasking: boolean, region: string): string => {
        let stage = 'beta';
        switch (selectedAction.value) {
            case 'Mask':
            case 'Unmask':
                return Paths.InputMask;
            case 'Mask Door Forced Open':
            case 'Unmask Door Forced Open':
                if (isOnguardReaderMasking) {
                    return `${Paths.OnguardReaderMasking}/region/${region}/stage/${getPACSDataAPIStage(stage)}/?ACTION_TYPEID=${OnguardReaderMaskActionTypes.doorForcedOpen}`;
                } else {
                    return Paths.ReaderDoorForced;
                }
            case 'Mask Door Held Open':
            case 'Unmask Door Held Open':
                if (isOnguardReaderMasking) {
                    return `${Paths.OnguardReaderMasking}/region/${region}/stage/${getPACSDataAPIStage(stage)}/?ACTION_TYPEID=${OnguardReaderMaskActionTypes.doorHeldOpen}`;
                } else {
                    return Paths.ReaderDoorHeld;
                }
            default:
                return Paths.DeviceMode;
        }
    }

    const selectedActionOnChange = (detail: SelectProps.ChangeDetail): void => {
        debug(`CreateSchedule() selectedActionOnChange() detail is ${JSON.stringify(detail)}`);
        const value = detail.selectedOption.value!;
        switch (value) {
            case 'Mask':
            case 'Unmask':
                setDeviceType('inputs');
                break;
            case 'Mask Door Forced Open':
            case 'Unmask Door Forced Open':
            case 'Mask Door Held Open':
            case 'Unmask Door Held Open':
                setDeviceType('readersAlarms');
                break;
            default:
                setDeviceType('readersModes');
                break;
        }
        setSelectedDevice(null);
        setSelectedMaskingGroup(null);
        setTimezone(null);
        setSelectedAction({ label: detail.selectedOption.label!, value: value });
    }

    const selectedEntityOnChange = (detail: SelectProps.ChangeDetail): void => {
        debug(`CreateSchedule() selectedEntityOnChange() detail is ${JSON.stringify(detail)}`);
        const devices = getDevices();
        let device;
        const value = detail.selectedOption.value!;
        if (value.startsWith(MASKING_GROUP_OPTION_PREFIX)) {
            setSelectedDevice(null);
            setSelectedMaskingGroup(value.replace(MASKING_GROUP_OPTION_PREFIX, ''));
            // Need a device to determine timezone
            device = devices.length > 0 ? devices[0] : null;
        } else {
            device = devices.find(device => device.DeviceName === detail.selectedOption.value!);
            setSelectedDevice(device ?? null);
            setSelectedMaskingGroup(null);
        }
        if (device && device.Derived_Panel_Windows_Timezone) {
            const ianaTimezone = TimeZoneConverter.windowsToIana(device.Derived_Panel_Windows_Timezone)
            debug(`CreateSchedule() selectedEntityOnChange() ianaTimezone is ${ianaTimezone}`);
            setTimezone(ianaTimezone);
        } else {
            setTimezone(null);
        }
    }

    const saveOnClickHandler = async (): Promise<void> => {
        debug(`CreateSchedule() saveOnClickHandler() started.`)
        setLoading(true);
        let saveErrorMessage = 'Error: Failed to create schedule.';
        const isForSchedule = selectedScheduleType?.value == 'For';
        try {
            let deviceSource = '';
            if (selectedMaskingGroup) {
                const devices = getDevices();
                if (devices.length > 0) deviceSource = devices[0].devicesource!;
            } else if (selectedDevice) {
                deviceSource = selectedDevice?.devicesource!;
            } else {
                saveErrorMessage = 'Error: Did not find device or masking group while saving schedule.';
                throw new Error(saveErrorMessage);
            }

            if (!timezone) {
                saveErrorMessage = 'Error: Did not find panel timezone';
                throw new Error(saveErrorMessage);
            }

            const scheduleExpression = getScheduleExpression();

            if (isScheduleExpressionInPast(scheduleExpression, timezone!)) {
                saveErrorMessage = 'Error: Schedule must be set in the future.';
                throw new Error(saveErrorMessage);
            }

            const isOnguardReaderMasking: boolean = (deviceSource === DeviceSources.ONGUARD && deviceType === 'readersAlarms');

            const input: APIt.SetScheduleInput = {
                actionAfterCompletion: autoDelete ? APIt.ActionAfterCompletion.DELETE : APIt.ActionAfterCompletion.NONE,
                deviceSource: deviceSource,
                enabled: enabled,
                masked: getScheduleMasked(false),
                mode: getScheduleMode(),
                path: getSchedulePath(isOnguardReaderMasking, userSelectedSite.siteRegion),
                requestedBy: username,
                scheduleExpression: scheduleExpression,
                siteName: userSelectedSite.sitename,
                siteRegion: userSelectedSite.siteRegion,
                timezone: timezone!,
            }

            if (selectedMaskingGroup) {
                input.maskingGroupName = selectedMaskingGroup;
                switch (deviceType) {
                    case 'inputs':
                        input.maskingGroupType = MaskingGroupTypes.Inputs;
                        break;
                    case 'readersAlarms':
                        input.maskingGroupType = MaskingGroupTypes.ReadersAlarms;
                        break;
                    default:
                        input.maskingGroupType = MaskingGroupTypes.ReadersModes;
                        break;
                }
                debug(`CreateSchedule() saveOnClickHandler() masking group is ${input.maskingGroupName}.`);
                debug(`CreateSchedule() saveOnClickHandler() masking group type is ${input.maskingGroupType}.`);
            } else {
                input.amzn_key = `${selectedDevice!.Parent_DeviceID}_${selectedDevice!.Child_DeviceID}_${selectedDevice!.Subchild_DeviceID}`;
                debug(`CreateSchedule() saveOnClickHandler() amzn_key is ${input.amzn_key}.`);
            }

            if (isForSchedule) {
                try {
                    let devices: SilencioDevice[] = [];
                    let resetMode: string | undefined;
                    if (selectedMaskingGroup) {
                        const allDevices = getDevices();
                        devices = allDevices.filter(device => device.MaskingGroups?.find(mg => mg.name === selectedMaskingGroup));
                        if (deviceType == 'readersModes') {
                            resetMode = modeAfterDuration!.value!;
                        }
                    } else {
                        devices = [selectedDevice!]
                        resetMode = selectedDevice!.Mode ?? undefined
                    }
                    if (enabled) {
                        await performForScheduleInitialAction(devices, deviceType, input);
                    }

                    props.refreshDevicesCallback();
                    //Update the input to have the resulting schedule reset the device after the chosen duration
                    input.masked = getScheduleMasked(true);
                    input.mode = resetMode;
                } catch (err) {
                    saveErrorMessage = `Error: Failed to perform initial ${selectedAction.label!} action and no schedule was created.`;
                    throw err;
                }
            }

            const result: APIt.ScheduleResponse = await setSchedule(input, username);

            if (result.status !== 200 || !result.result) throw new Error('Error creating schedule.');

            const scheduleList: ScheduleRow[] = schedules.concat({
                Action: selectedAction.value!,
                EntityName: selectedDevice ? selectedDevice.DeviceName! : selectedMaskingGroup!,
                FormattedScheduleExpression: formatScheduleExpression(input.scheduleExpression, input.timezone),
                Name: result.result.scheduleName!,
                PairedReader: selectedDevice ? selectedDevice.PairedReader : undefined,
                ScheduleExpression: input.scheduleExpression,
                ScheduleExpressionTimezone: input.timezone,
                State: enabled ? ScheduleState.Enabled : ScheduleState.Disabled,
            });

            dispatch(setSchedules({ schedules: scheduleList }));

            props.showModalCallback(false);
        } catch (err) {
            console.error(`CreateSchedule() saveOnClickHandler() error: ${err}`);
            setErrorMessage(saveErrorMessage);
        }
        setLoading(false);
    }

    if (isBundleLoading || isCommonBundleLoading) return <Spinner />;

    return (
        <Modal
            header={bundle.getMessage('create-schedule')}
            visible={props.visible}
            onDismiss={() => props.showModalCallback(false)}
            footer={
                <>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Icon name='status-info' />
                        <span>{bundle.getMessage('information')}</span>
                    </SpaceBetween>
                    <TextContent>
                        {timezone && <p>{bundle.formatMessage('timezone', { name: timezone })}</p>}
                        <p>{formatFooterMessage()}</p>
                    </TextContent>
                    <Box float='right'>
                        <Button
                            variant='primary'
                            onClick={saveOnClickHandler}
                            disabled={checkSaveDisabled()}
                            ariaLabel={bundle.getMessage('save-new-schedule')}
                        >
                            {bundle.getMessage('save')}
                        </Button>
                    </Box>
                </>
            }
        >
            {loading ? <Spinner /> :
                <SpaceBetween direction='vertical' size='m'>
                    {errorMessage != null &&
                        <Alert
                            dismissible={true}
                            onDismiss={() => setErrorMessage(null)}
                            type='error'
                        >
                            {errorMessage}
                        </Alert>
                    }
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Select
                            expandToViewport
                            onChange={({ detail }) => { selectedActionOnChange(detail) }}
                            options={getActionOptions(commonBundle)}
                            selectedAriaLabel={bundle.getMessage('selected-action')}
                            selectedOption={selectedAction}
                        />
                        <Select
                            expandToViewport
                            filteringType='auto'
                            onChange={({ detail }) => { selectedEntityOnChange(detail) }}
                            options={getEntityOptions()}
                            placeholder={bundle.getMessage('device-name')}
                            selectedAriaLabel={bundle.getMessage('selected-entity')}
                            selectedOption={
                                selectedDevice ? { label: selectedDevice.DeviceName!, value: selectedDevice.DeviceName! }
                                    : selectedMaskingGroup ? { label: selectedMaskingGroup, value: selectedMaskingGroup }
                                        : null
                            }
                        />
                        <Select
                            expandToViewport
                            onChange={({ detail }) => { setSelectedScheduleType({ value: detail.selectedOption.value, label: detail.selectedOption.label }) }}
                            options={[
                                { value: 'At', label: bundle.getMessage('at') },
                                { value: 'Every', label: bundle.getMessage('every') },
                                { value: 'For', label: bundle.getMessage('for') },
                            ]}
                            selectedAriaLabel={bundle.getMessage('selected-type-of-schedule')}
                            selectedOption={selectedScheduleType}
                        />
                    </SpaceBetween>
                    {selectedScheduleType && selectedScheduleType.value == 'At' &&
                        <SpaceBetween direction='horizontal' size='xs'>
                            <TimeInput
                                onChange={({ detail }) => setTimeValue(detail.value)}
                                value={timeValue}
                                format="hh:mm"
                                placeholder={bundle.getMessage('time-placeholder')}
                            />
                            <TextContent><p>on</p></TextContent>
                            <DatePicker
                                onChange={({ detail }) => setDateValue(detail.value)}
                                value={dateValue}
                                openCalendarAriaLabel={(selectedDate) =>
                                    bundle.formatMessage('selected-date', { dateSelected: (selectedDate != null), selectedDate })
                                }
                                placeholder={bundle.getMessage('date-placeholder')}
                                todayAriaLabel={bundle.getMessage('today')}
                                nextMonthAriaLabel={bundle.getMessage('next-month')}
                                previousMonthAriaLabel={bundle.getMessage('previous-month')}
                                isDateEnabled={checkDateEnabled}
                            />
                        </SpaceBetween>
                    }
                    {selectedScheduleType && selectedScheduleType.value == 'Every' &&
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Select
                                expandToViewport
                                onChange={({ detail }) => { setDayOfWeek({ value: detail.selectedOption.value, label: detail.selectedOption.label }) }}
                                options={[
                                    { value: '*', label: bundle.getMessage('day') },
                                    { value: '1', label: bundle.getMessage('sunday') },
                                    { value: '2', label: bundle.getMessage('monday') },
                                    { value: '3', label: bundle.getMessage('tuesday') },
                                    { value: '4', label: bundle.getMessage('wednesday') },
                                    { value: '5', label: bundle.getMessage('thursday') },
                                    { value: '6', label: bundle.getMessage('friday') },
                                    { value: '7', label: bundle.getMessage('saturday') },
                                    { value: '2-6', label: bundle.getMessage('weekday') },
                                ]}
                                selectedAriaLabel={bundle.getMessage('selected-day-of-week')}
                                selectedOption={dayOfWeek}
                            />
                            <TextContent><p>at</p></TextContent>
                            <TimeInput
                                onChange={({ detail }) => setTimeValue(detail.value)}
                                value={timeValue}
                                format="hh:mm"
                                placeholder={bundle.getMessage('time-placeholder')}
                            />
                        </SpaceBetween>
                    }
                    {selectedScheduleType && selectedScheduleType.value == 'For' &&
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Input
                                type="number"
                                value={duration.toString()}
                                onChange={event => durationOnChange(event.detail.value)}
                            />
                            <Select
                                expandToViewport
                                onChange={event => durationUnitOnChange(event.detail)}
                                options={[
                                    { value: `${1000 * 60}`, label: bundle.getMessage('minutes') },
                                    { value: `${1000 * 60 * 60}`, label: bundle.getMessage('hours') },
                                    { value: `${1000 * 60 * 60 * 24}`, label: bundle.getMessage('days') },
                                ]}
                                selectedAriaLabel={bundle.getMessage('selected-duration-unit')}
                                selectedOption={durationUnit}
                            />
                        </SpaceBetween>
                    }
                    {deviceType == 'readersModes' && selectedMaskingGroup && selectedScheduleType && selectedScheduleType.value == 'For' &&
                        <SpaceBetween direction='horizontal' size='xs'>
                            <TextContent>{bundle.getMessage('when-finished-set-mode-to')}</TextContent>
                            <Select
                                expandToViewport
                                onChange={({ detail }) => { setModeAfterDuration({ value: detail.selectedOption.value, label: detail.selectedOption.label }) }}
                                options={Modes.filter((m: SelectProps.Option) => (m.value !== 'Facility Code' && m.value !== 'Unknown'))}
                                selectedAriaLabel={bundle.getMessage('select-mode-after-duration')}
                                selectedOption={modeAfterDuration}
                            />
                        </SpaceBetween>
                    }
                    <Toggle
                        ariaLabel={bundle.formatMessage('enable-or-disable-label')}
                        onChange={({ detail }) => setEnabled(detail.checked)}
                        checked={enabled}
                    >
                        {bundle.formatMessage('enabled-or-disabled', { enabled })}
                    </Toggle>
                    {selectedScheduleType?.value !== 'Every' &&
                        <Toggle
                            ariaLabel={bundle.formatMessage('auto-delete', { autoDelete: autoDelete })}
                            onChange={({ detail }) => setAutoDelete(detail.checked)}
                            checked={autoDelete}
                        >
                            {bundle.formatMessage('auto-delete', { autoDelete })}
                        </Toggle>
                    }
                </SpaceBetween>
            }
        </Modal>
    );
}
