import { createSlice } from '@reduxjs/toolkit';
import { Privileges } from 'src/utils/UserSitesUtils';
import * as APIt from '../../API';
import { debug } from '../../utils/commonUtils';

export interface UserSliceInterface {
  authToken: string;
  authenticatedTimestamp: number;
  employeeId: number | undefined;
  phySecMember: boolean | undefined;
  phySecRegions: string[] | undefined;
  sideTeam: boolean | undefined;
  sigAdmin: boolean | undefined;
  tzOffset: number;
  userSelectedSite: APIt.UserPrefSite | undefined;
  userSelectedSitePrivs: Privileges[] | undefined;
  userAuthenticated: boolean;
  username: string;
  userPrefs: APIt.UserPrefs | undefined;
  userSites: (APIt.UserPrefSite | null)[];
};

export const initalUserState: UserSliceInterface = {
  authToken: '',
  authenticatedTimestamp: 0,
  employeeId: undefined,
  phySecMember: undefined,
  phySecRegions: undefined,
  sideTeam: undefined,
  sigAdmin: undefined,
  tzOffset: new Date().getTimezoneOffset(),
  userSelectedSite: undefined,
  userSelectedSitePrivs: undefined,
  userAuthenticated: false,
  username: '',
  userPrefs: undefined,
  userSites: []
};

export const userSlice = createSlice({
  name: 'userState',
  initialState: initalUserState,
  reducers: {
    setAuthToken: (state, action) => {
      state.authToken = action.payload.toString();
    },
    setEmployeeId: (state, action) => {
      debug(`setEmployeeId(): action is ${JSON.stringify(action)}`);
      state.employeeId = parseInt(action.payload);
    },
    setPhySecMember: (state, action) => {
      debug(`setPhySecMember(): action is ${JSON.stringify(action)}`);
      state.phySecMember = (action.payload);
    },
    setPhySecRegions: (state, action: { type: string, payload: string[] | undefined }) => {
      debug(`setPhySecRegions(): action is ${JSON.stringify(action)}`);
      state.phySecRegions = (action.payload);
    },
    setSavedSearches: (state, action: { type: string, payload: any }) => {
      debug(`setSavedSearches(): action is ${JSON.stringify(action)}`);
      switch (action.payload.devicesType) {
        case 'inputs':
          if (state.userPrefs?.inputs?.savedSearches)
            state.userPrefs.inputs.savedSearches = action.payload.savedSearches;
          break;
        case 'outputs':
          if (state.userPrefs?.outputs?.savedSearches)
            state.userPrefs.outputs.savedSearches = action.payload.savedSearches;
          break;
        case 'readersAlarms':
          if (state.userPrefs?.readersAlarms?.savedSearches)
            state.userPrefs.readersAlarms.savedSearches = action.payload.savedSearches;
          break;
        case 'readersModes':
          if (state.userPrefs?.readersModes?.savedSearches)
            state.userPrefs.readersModes.savedSearches = action.payload.savedSearches;
          break;
      }
    },
    setSideTeam: (state, action) => {
      debug(`setSideTeam(): action is ${JSON.stringify(action)}`);
      state.sideTeam = (action.payload);
    },
    setSigAdmin: (state, action) => {
      debug(`setSigAdmin(): action is ${JSON.stringify(action)}`);
      state.sigAdmin = (action.payload);
    },
    setUsername: (state, action) => {
      state.username = action.payload.toString();
      state.userAuthenticated = true;
    },
    setUserPrefs: (state, action: { type: string, payload: APIt.UserPrefs }) => {
      debug(`setUserPrefs(): payload is ${JSON.stringify(action.payload)}`);
      if (!action.payload.inputs?.savedSearches && action.payload.inputs) action.payload.inputs.savedSearches = [];
      if (!action.payload.outputs?.savedSearches && action.payload.outputs) action.payload.outputs.savedSearches = [];
      if (!action.payload.readersAlarms?.savedSearches && action.payload.readersAlarms) action.payload.readersAlarms.savedSearches = [];
      if (!action.payload.readersModes?.savedSearches && action.payload.readersModes) action.payload.readersModes.savedSearches = [];
      state.userPrefs = action.payload;
    },
    setUserSelectedSite: (state, action: { type: string, payload: APIt.UserPrefSite }) => {
      debug(`setUserSelectedSite(): payload is ${JSON.stringify(action.payload)}`);
      state.userSelectedSite = action.payload;
    },
    setUserSelectedSitePrivs: (state, action: { type: string, payload: Privileges[] | undefined }) => {
      debug(`setUserSelectedSitePrivs(): payload is ${action.payload}`);
      state.userSelectedSitePrivs = action.payload || undefined;
    },
    setUserSites: (state, action: { type: string, payload: (APIt.UserPrefSite | null)[] }) => {
      state.userSites = action.payload;
    },
    userDidAuthenticate: (state) => {
      state.userAuthenticated = true;
    },
    userDidLogoff: (state) => {
      state.userAuthenticated = false;
      state.username = '';
    },
  },
});

export const {
  setAuthToken,
  setEmployeeId,
  setPhySecMember,
  setPhySecRegions,
  setSavedSearches,
  setSideTeam,
  setSigAdmin,
  setUsername,
  setUserPrefs,
  setUserSelectedSite,
  setUserSelectedSitePrivs,
  setUserSites,
  userDidAuthenticate,
  userDidLogoff,
} = userSlice.actions;

export const selectEmployeeId = (state: any) => state.user.employeeId;
export const selectPhySecMember = (state: any) => state.user.phySecMember;
export const selectPhySecRegions = (state: any) => state.user.phySecRegions;
export const selectSideTeam = (state: any) => state.user.sideTeam;
export const selectSigAdmin = (state: any) => state.user.sigAdmin;
export const selectUsername = (state: any) => state.user.username;
export const selectUserPrefs = (state: any) => state.user.userPrefs;
export const selectUserSites = (state: any) => state.user.userSites;
export const selectUserSelectedSite = (state: any) => state.user.userSelectedSite;
export const selectUserSelectedSitePrivs = (state: any) => state.user.userSelectedSitePrivs;

export default userSlice.reducer;