import {
  Alert,
  Button,
  Checkbox,
  Modal,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner
} from '@amzn/awsui-components-react';
import { getMaskingGroupType, isMaskingGroupKeyword } from './utils';
import React, { useEffect } from 'react';
import {
  setInputsSearchMaskingGroup,
  setReadersAlarmsSearchMaskingGroup,
  setReadersModesSearchMaskingGroup
} from '../../stores/slices/devicesSlice';
import { selectUserSelectedSite, selectUsername } from 'src/stores/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { MaskingGroupInfo } from 'src/utils/SilencioTypes';
import { debug } from 'src/utils/commonUtils';
import { deleteMaskingGroup } from '../../utils/DevicesUtils';
import { useBundle } from '@amzn/react-arb-tools';

export interface IDeleteMaskingGroupProps {
  deviceType: string;
  maskingGroups: MaskingGroupInfo[];
  refreshDevicesCallback: Function;
  selectedMaskingGroup: SelectProps.Option;
  showModalCallback: Function;
  visible: boolean;
}

export default function DeleteMaskingGroup(props: any) {
  debug(`DeleteMaskingGroup(): props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.maskingGroups.MaskingGroups');

  const [acknowledge, setAcknowledge] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [maskingGroupOptions, setMaskingGroupOptions] = React.useState<SelectProps.Options>();
  const [selectedMaskingGroup, setSelectedMaskingGroup] = React.useState<SelectProps.Option>(props.selectedMaskingGroup);

  const username = useSelector(selectUsername);
  const userSelectedSite = useSelector(selectUserSelectedSite);

  const dispatch = useDispatch();

  useEffect(() => {
    debug(`DeleteMaskingGroup() useEffect() [props.selectedMaskingGroup, props.maskingGroups]`);
    let options = props.maskingGroups.map((mg: MaskingGroupInfo) => ({ label: mg.name, value: mg.id }));
    debug(`DeleteMaskingGroup() useEffect() maskingGroups options is ${JSON.stringify(options)}`);
    setMaskingGroupOptions(options);
    setSelectedMaskingGroup(props.selectedMaskingGroup);
  }, [props.selectedMaskingGroup, props.maskingGroups])

  const deleteOnClickHandler = async () => {
    debug(`DeleteMaskingGroup(): deleteOnClickHandler()`);
    if (!selectedMaskingGroup.label || isMaskingGroupKeyword(selectedMaskingGroup.label)) {
      setErrorMessage('Error: must select a valid Masking Group to delete.');
      return;
    }
    const selectedName = selectedMaskingGroup.label;

    setLoading(true);
    try {
      debug(`DeleteMaskingGroup(): deleteOnClickHandler() Deleting masking group: ${selectedName}`);
      const maskingGroupType = getMaskingGroupType(props.deviceType);

      const result = await deleteMaskingGroup(userSelectedSite.siteRegion, username, selectedName, maskingGroupType);
      debug(`DeleteMaskingGroup(): deleteOnClickHandler() result: ${result}`);

      if (!result.Result || !result.Result.includes("MaskGroup Removal - Success") || !result.Result.includes("Device Removal from MaskGroup - Success")) {
        throw new Error(`Invalid Result ${JSON.stringify(result)}`);
      }

      props.refreshDevicesCallback();
      switch (props.deviceType) {
        case 'inputs':
          dispatch(setInputsSearchMaskingGroup(undefined));
          break;
        case 'readersAlarms':
          dispatch(setReadersAlarmsSearchMaskingGroup(undefined));
          break;
        case 'readersModes':
          dispatch(setReadersModesSearchMaskingGroup(undefined));
          break;
      }
      props.showModalCallback(false);
    } catch (err) {
      debug(`DeleteMaskingGroup(): deleteOnClickHandler() error: ${err}`);
      setErrorMessage(`Error deleting masking group: ${selectedName}`);
    }
    setLoading(false);
  };

  const cancelOnClickHandler = () => {
    props.showModalCallback(false);
  };

  if (isBundleLoading) return <Spinner />

  return (
    <Modal
      header={bundle.getMessage('delete-masking-group')}
      visible={props.visible}
      onDismiss={() => props.showModalCallback(false)}
    >
      {
        loading ? <Spinner /> :
          <SpaceBetween direction='vertical' size={'l'}>
            <Alert
              dismissible={true}
              type='error'
              visible={errorMessage != null}
              onDismiss={() => setErrorMessage(null)}
            >
              {errorMessage}
            </Alert>
            <Select
              onChange={({ detail }) => { setSelectedMaskingGroup(detail.selectedOption) }}
              options={maskingGroupOptions}
              selectedAriaLabel={bundle.getMessage('selected-masking-group')}
              selectedOption={selectedMaskingGroup}
              filteringType="auto"
              expandToViewport
            />
            {(selectedMaskingGroup.value && !isMaskingGroupKeyword(selectedMaskingGroup.value)) &&
              <Checkbox
                onChange={({ detail }) => setAcknowledge(detail.checked)}
                checked={acknowledge}>
                {bundle.formatMessage('delete-acknowledgement', { maskingGroupName: selectedMaskingGroup.label })}
              </Checkbox>
            }
            <SpaceBetween direction='horizontal' size={'m'}>
              <Button
                variant='primary'
                onClick={deleteOnClickHandler}
                disabled={!acknowledge}
                ariaLabel={bundle.getMessage('delete-masking-group')}
              >
                {bundle.getMessage('delete')}
              </Button>
              <Button
                variant='normal'
                onClick={cancelOnClickHandler}
                ariaLabel={bundle.getMessage('cancel-delete-masking-group')}
              >
                {bundle.getMessage('cancel')}
              </Button>
            </SpaceBetween>
          </SpaceBetween>
      }
    </Modal>
  );
}
