/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getDevice = /* GraphQL */ `query GetDevice($path: String!) {
  getDevice(path: $path) {
    Child_DeviceID
    Derived_Panel_Windows_Timezone
    device_href
    Device_Type
    DeviceName
    devicesource
    DoorForcedMasked
    DoorHeldMasked
    Parent_DeviceID
    SegmentID
    Subchild_DeviceID
    Masked
    Mode
    MaskGroupID
    MaskGroupName
    MaskGroupType
    pair_primary
    pair_secondary
    paired_reader_id
  }
}
` as GeneratedQuery<APITypes.GetDeviceQueryVariables, APITypes.GetDeviceQuery>;
export const getDeviceStatus = /* GraphQL */ `query GetDeviceStatus($queryStringParameters: GetDeviceStatusInput!) {
  getDeviceStatus(queryStringParameters: $queryStringParameters) {
    device_key
    parent_id
    child_id
    subchild_id
    site_code
    device_type
    masked
    masked_timestamp
    intrusion_report
    intrusion_report_timestamp
    alarm
    alarm_timestamp
    line_error
    line_error_timestamp
    open_line
    open_line_timestamp
    shorted_line
    shorted_line_timestamp
    grounded_loop
    grounded_loop_timestamp
    relay_contact
    relay_contact_timestamp
    comm_loss
    comm_loss_timestamp
    comm_loss_host
    comm_loss_host_timestamp
    door_forced
    door_forced_timestamp
    door_held
    door_held_timestamp
    reader_mode
    reader_mode_timestamp
    full_panel_download
    full_panel_download_timestamp
    cabinet_tamper
    cabinet_tamper_timestamp
    power_failure
    power_failure_timestamp
    door_held_masked
    door_held_masked_timestamp
    door_forced_masked
    door_forced_masked_timestamp
    comm_path
    comm_path_timestamp
    last_update_epoch_utc
  }
}
` as GeneratedQuery<
  APITypes.GetDeviceStatusQueryVariables,
  APITypes.GetDeviceStatusQuery
>;
export const getSiteTemplateDevices = /* GraphQL */ `query GetSiteTemplateDevices($path: String!) {
  getSiteTemplateDevices(path: $path) {
    Desired_State
    DeviceName
    Device_Type
    Entity
    EntityType
    MaskGroupName
    MaskGroupType
    TemplateType
    Template_Created_By
    Template_Created_Date_UTC
    amzn_key
    device_href
    devicesource
  }
}
` as GeneratedQuery<
  APITypes.GetSiteTemplateDevicesQueryVariables,
  APITypes.GetSiteTemplateDevicesQuery
>;
export const getUserPrefs = /* GraphQL */ `query GetUserPrefs($username: String!) {
  getUserPrefs(username: $username) {
    username
    inputs {
      numRecordsPerPage
      emailAddresses
      savedSearches {
        name
        filterText
        maskStatus
        modes
      }
    }
    outputs {
      numRecordsPerPage
      emailAddresses
      savedSearches {
        name
        filterText
        outputStatus
      }
    }
    lastSite {
      sitename
      siteRegion
    }
    readersAlarms {
      numRecordsPerPage
      emailAddresses
      savedSearches {
        name
        filterText
        maskStatus
        modes
      }
    }
    readersModes {
      numRecordsPerPage
      emailAddresses
      savedSearches {
        name
        filterText
        maskStatus
        modes
      }
    }
    schedules {
      numRecordsPerPage
      emailAddresses
      visibleColumns
    }
    siteTemplates {
      numRecordsPerPage
      visibleColumns
    }
    site
    sites {
      sitename
      siteRegion
    }
  }
}
` as GeneratedQuery<
  APITypes.GetUserPrefsQueryVariables,
  APITypes.GetUserPrefsQuery
>;
export const listChildDevices = /* GraphQL */ `query ListChildDevices($path: String!) {
  listChildDevices(path: $path) {
    Child_DeviceID
    Derived_Panel_Windows_Timezone
    device_href
    Device_Type
    DeviceName
    devicesource
    DoorForcedMasked
    DoorHeldMasked
    Parent_DeviceID
    SegmentID
    Subchild_DeviceID
    Masked
    Mode
    MaskGroupID
    MaskGroupName
    MaskGroupType
    pair_primary
    pair_secondary
    paired_reader_id
  }
}
` as GeneratedQuery<
  APITypes.ListChildDevicesQueryVariables,
  APITypes.ListChildDevicesQuery
>;
export const listDeviceStatusForSites = /* GraphQL */ `query ListDeviceStatusForSites(
  $queryStringParameters: ListDeviceStatusForSitesInput!
) {
  listDeviceStatusForSites(queryStringParameters: $queryStringParameters) {
    device_key
    parent_id
    child_id
    subchild_id
    site_code
    device_type
    masked
    masked_timestamp
    intrusion_report
    intrusion_report_timestamp
    alarm
    alarm_timestamp
    line_error
    line_error_timestamp
    open_line
    open_line_timestamp
    shorted_line
    shorted_line_timestamp
    grounded_loop
    grounded_loop_timestamp
    relay_contact
    relay_contact_timestamp
    comm_loss
    comm_loss_timestamp
    comm_loss_host
    comm_loss_host_timestamp
    door_forced
    door_forced_timestamp
    door_held
    door_held_timestamp
    reader_mode
    reader_mode_timestamp
    full_panel_download
    full_panel_download_timestamp
    cabinet_tamper
    cabinet_tamper_timestamp
    power_failure
    power_failure_timestamp
    door_held_masked
    door_held_masked_timestamp
    door_forced_masked
    door_forced_masked_timestamp
    comm_path
    comm_path_timestamp
    last_update_epoch_utc
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceStatusForSitesQueryVariables,
  APITypes.ListDeviceStatusForSitesQuery
>;
export const listDeviceStatusesForSite = /* GraphQL */ `query ListDeviceStatusesForSite($siteCode: String!, $startKey: String) {
  listDeviceStatusesForSite(siteCode: $siteCode, startKey: $startKey) {
    Items
    Count
    LastEvaluatedKey
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceStatusesForSiteQueryVariables,
  APITypes.ListDeviceStatusesForSiteQuery
>;
export const listFacmanUserSites = /* GraphQL */ `query ListFacmanUserSites(
  $sitename: String
  $groupname: String
  $regions: [String]
  $username: String
  $employeeId: Int
) {
  listFacmanUserSites(
    sitename: $sitename
    groupname: $groupname
    regions: $regions
    username: $username
    employeeId: $employeeId
  ) {
    sitesTotal
    sites {
      siteId
      siteName
      region
      siteType
      maskingApproverEmails
    }
    error
  }
}
` as GeneratedQuery<
  APITypes.ListFacmanUserSitesQueryVariables,
  APITypes.ListFacmanUserSitesQuery
>;
export const listSchedules = /* GraphQL */ `query ListSchedules(
  $scheduleAction: ScheduleAction
  $input: ListSchedulesInput
) {
  listSchedules(scheduleAction: $scheduleAction, input: $input) {
    error
    message
    result {
      nextToken
      schedules
    }
    status
  }
}
` as GeneratedQuery<
  APITypes.ListSchedulesQueryVariables,
  APITypes.ListSchedulesQuery
>;
export const listSIGInfraUserSites = /* GraphQL */ `query ListSIGInfraUserSites($path: String!) {
  listSIGInfraUserSites(path: $path) {
    SiteCode
    AccessLevelName
    BadgeKey
    BadgeID
    Login
    Region
  }
}
` as GeneratedQuery<
  APITypes.ListSIGInfraUserSitesQueryVariables,
  APITypes.ListSIGInfraUserSitesQuery
>;
