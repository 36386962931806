import React from 'react';
import { Button, Flashbar, Icon, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import { Privileges } from 'src/utils/UserSitesUtils';
import { debug } from 'src/utils/commonUtils';
import { useBundle } from '@amzn/react-arb-tools';

interface IButtonBarProps {
  deviceType: string;
  onguardDevices: boolean;
  selectedItemsLength: number | undefined;
  selectDeselectAllButton: boolean;
  selectDeselectAllCallBack: Function;
  selectDeselectAllDisabled: boolean;
  setChangeStatusVisibleCallback: Function;
  setChangeModeVisibleCallback: Function;
  refreshDevicesCallback: Function;
  userSitePrivs: Privileges[] | undefined;
}

function ButtonBar(props: IButtonBarProps) {
  debug(`ButtonBar() props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.ButtonBar');

  const refresh = () => {
    props.refreshDevicesCallback();
  };

  if (isBundleLoading) return <Spinner/>;

  return (
    <SpaceBetween direction='vertical' size='xs'>
      {(props.deviceType === 'readersAlarms' && props.onguardDevices) &&
        <Flashbar
          items={[{
            type: "info",
            content: (<>{bundle.getMessage('onguard-reader-masking-delay-message')}</>),
            id: "onguardReaderMaskingDelayMessage"
          }]}
        />
      }
      <SpaceBetween direction="horizontal" size="xs">
        {(props.deviceType === 'inputs' || props.deviceType === 'readersAlarms') &&
          <Button
            disabled=
            {
              ((!props.userSitePrivs?.includes(Privileges.maskInputs) &&
                !props.userSitePrivs?.includes(Privileges.all)) ||
                props.selectedItemsLength === 0)
            }
            onClick={() => props.setChangeStatusVisibleCallback(true)}
            variant='primary'
          >
            {bundle.formatMessage('mask-unmask', { count: props.selectedItemsLength})}
          </Button>}
        {(props.deviceType === 'readersModes') &&
          <Button
            disabled=
            {
              (!props.userSitePrivs?.includes(Privileges.all) ||
                props.selectedItemsLength === 0)
            }
            onClick={() => props.setChangeModeVisibleCallback(true)}
            variant='primary'
          >
            {bundle.formatMessage('change-mode', { count: props.selectedItemsLength})}
          </Button>}
        <Button onClick={() => props.selectDeselectAllCallBack()} disabled={props.selectDeselectAllDisabled}>
          {bundle.formatMessage('select-deselect-all', { input: props.selectDeselectAllButton})}
        </Button>
        <Button onClick={refresh} ariaLabel={bundle.getMessage('refresh-devices')}>
          <Icon name='refresh' alt={bundle.getMessage('refresh-devices')} />
        </Button>
      </SpaceBetween>
    </SpaceBetween>
  );
}

export default ButtonBar;