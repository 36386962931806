import { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter';
import React from 'react';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { TemplateRow } from '../../utils/SilencioTypes';
import TranslateText from '../TranslateText';
import { getKeywordTranslationKey } from 'src/utils/commonUtils';

export const ColumnDefinitions: TableProps.ColumnDefinition<TemplateRow>[] = [
  {
    id: 'TemplateType',
    header: <TranslateText translateKey={'template-type'} />,
    cell: (item: TemplateRow) => <TranslateText formatInput={item.templateType} translateKey={'open-close-site'} />,
    sortingField: 'templateType'
  },
  {
    id: 'Action',
    header: <TranslateText translateKey={'action'} />,
    cell: (item: TemplateRow) => <TranslateText translateKey={getKeywordTranslationKey(item.action)} />,
    sortingField: 'action'
  },
  {
    id: 'EntityName',
    header: <TranslateText translateKey={'name'} />,
    cell: (item: TemplateRow) => item.entityName,
    sortingField: 'entityName'
  },
  {
    id: 'PairedReader',
    header: <TranslateText translateKey={'paired-reader'} />,
    cell: (item: TemplateRow) => item.pairedReader,
    sortingField: 'pairedReader'
  },
  {
    id: 'CreatedDate',
    header: <TranslateText translateKey={'creation-date'} />,
    cell: (item: TemplateRow) => item.createdDate,
    sortingField: 'createdDate'
  }
];

export const FilteringProperties: PropertyFilterProps.FilteringProperty[] = [
  {
    key: 'templateType',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Template Type',
    groupValuesLabel: 'Template Types'
  }, {
    key: 'action',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Action',
    groupValuesLabel: 'Actions'
  },
  {
    key: 'entityName',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Name',
    groupValuesLabel: 'Names'
  },
  {
    key: 'pairedReader',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Paired Reader',
    groupValuesLabel: 'Paired Readers'
  },
  {
    key: 'createdDate',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Created Date',
    groupValuesLabel: 'Created Dates'
  }
];

export const TemplatesFilteringOptions = [
  { propertyKey: 'templateType', value: 'Open' },
  { propertyKey: 'templateType', value: 'Close' },
  { propertyKey: 'action', value: 'Mask' },
  { propertyKey: 'action', value: 'Unmask' },
  { propertyKey: 'action', value: 'Mask Door Forced Open' },
  { propertyKey: 'action', value: 'Unmask Door Forced Open' },
  { propertyKey: 'action', value: 'Mask Door Held Open' },
  { propertyKey: 'action', value: 'Unmask Door Held Open' },
  { propertyKey: 'action', value: 'Card and Pin' },
  { propertyKey: 'action', value: 'Card Only' },
  { propertyKey: 'action', value: 'Facility Code' },
  { propertyKey: 'action', value: 'Locked' },
  { propertyKey: 'action', value: 'Pin or Card' },
  { propertyKey: 'action', value: 'Unlocked' }
];
