import { configureStore } from '@reduxjs/toolkit';
import devicesReducer from './slices/devicesSlice';
import schedulesReducer from './slices/schedulesSlice';
import templatesReducer from './slices/templatesSlice';
import userReducer from './slices/userSlice';

export const loadUserPrefsState = () => {
  try {
    const serializedState = localStorage.getItem('userPrefsState');
    if (!serializedState) return undefined;
    else return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveUserPrefsState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state.userPrefsState);
    localStorage.setItem('userPrefsState', serializedState);
  } catch (err) {
    console.log(err);
  }
};

const store = configureStore({
  reducer: {
    devices: devicesReducer,
    schedules: schedulesReducer,
    templates: templatesReducer,
    user: userReducer,
  },
  devTools: true,
});

store.subscribe(() => {
  saveUserPrefsState(store.getState());
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
